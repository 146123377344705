import styles from "./list.module.scss";
import { useEffect, useState } from "react";
import React from "react";
import { Empty } from "@arco-design/web-react";
import { useWindowSize } from "@/utils/hook";
import { ReactComponent as IconLogo } from "@/icons/common/logo.svg";
import { ReactComponent as IconLoading } from "@/icons/common/three-dots.svg";

import { useMobileScreen } from "@/utils/hook";

function PromptList(props) {
  
  const isMobileScreen = useMobileScreen();
  const { width } = useWindowSize();
  useEffect(() => {
    setColNum(Math.floor((width - 200) / 300));
  }, [width]);
  const [colNum, setColNum] = useState(0);
  return (
    <div className={styles["amz-app-tabs"]} style={{height:isMobileScreen?'calc(100vh - 100px)':'calc(100vh - 66px)'}} >
      <div className={styles["amz-app-tab"]}>
        {props.loading ? (
          <IconLoading style={{ display: "block", margin: "100px auto 0" }} />
        ) : !props.list.length ? (
          <Empty style={{ marginTop: "100px" }} />
        ) : (
          <div className={styles["amz-app-tabItems"]}>
            {props.list.map((val) => {
              return (
                <div
                  style={{ width: `calc(100% / ${colNum})`, marginBottom: 10 }}
                >
                  <div
                    className={styles["amz-app-tabItem"]}
                    onClick={() => {
                      props.toDetail(val);
                    }}
                  >
                    <div className={styles["tabitem-title"]}>{val.title}</div>
                    <div className={styles["tabitem-content"]}>
                      {val.description}
                    </div>
                    <div className={styles["tabitem-from"]}>
                      <IconLogo
                        style={{ width: 12, height: 12, marginRight: 5 }}
                      />
                      AMZMOSS
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}
export default PromptList;
