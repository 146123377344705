import { Route, Routes, useNavigate, Navigate, use } from "react-router-dom";
import Chat from "@/pages/chat";
import VipPage from "@/pages/vip";
import HeadInfo from "../HeadInfo";
import AppCenter from "@/pages/appcenter";
import Prompt from "@/pages/prompt";
import ChatImg from "@/pages/chatimg";
import ImgList from "@/pages/imgList";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@arco-design/web-react";
import { clearToken } from "@/store/appSlice";
import { useMobileScreen } from "@/utils/hook";
import { useLocation } from "react-router";
import { IconMessage, IconApps } from "@arco-design/web-react/icon";
function Layout({ children }) {
  window.location.href = 'https://ai.amztab.com'

  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.app.aiToken);
  const [num, setNum] = useState("999");
  const isMobileScreen = useMobileScreen();
  const menuList = [
    {
      name: "会话",
      path: "/chat",
      icon: <IconMessage />,
    },
    {
      name: "应用",
      path: "/app",
      icon: <IconApps />,
    },
    // {
    //   name: "创作",
    //   path: "/prompt",
    // },
  ];
  // const loginState = ''
  // if (!token) {
  //     return <Navigate to='/login' />
  // }

  useEffect(() => {
    // if (!token) {
    // }
  }, []);

  return (
    <div>
      <HeadInfo></HeadInfo>
      {/* <Button onClick={loginOut}>退出登录</Button> */}

      <Routes>
        <Route index element={<Chat name="default" />} />
        <Route path="app" element={<AppCenter name="app" />} />
        <Route path="prompt" element={<Prompt name="prompt" />} />
        <Route path="vip" element={<VipPage name="vip" />} />
        <Route path="chat" element={<Chat />} />
        <Route path="aiimg" element={<ChatImg />} />
        <Route path="imglist" element={<ImgList />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      {isMobileScreen ? (
        <div
          style={{ height: "40px" }}
          className="bg-white flex justify-around items-center border-t border-gray-300"
        >
          {menuList.map((item, index) => {
            return (
              <div
                className={`px-2 flex text-xs flex-col items-center ${item.path == pathname ? "text-blue-600" : ""
                  }`}
                onClick={() => {
                  navigate(item.path);
                }}
                key={index}
              >
                {item.icon}
                {item.name}
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
}

export default Layout;
