import { Message } from "@arco-design/web-react";

export function isMobileScreen() {
  return window.innerWidth <= 600;
}
/**
 * 复制字符串的方法
 * @param {String} text 复制的字符串
 */
export async function copyToClipboard(text) {
  try {
    await navigator.clipboard.writeText(text);
    Message.success("复制成功");
  } catch (error) {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand("copy");
      Message.success("复制成功");
    } catch (error) {
      Message.error("复制失败");
    }
    document.body.removeChild(textArea);
  }
}
/**
 * 导出文本
 * @param {String} text 导出的文本
 * @param {String} filename 导出的文件名称
 */
export function downloadAs(text, filename) {
  const element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(text)
  );
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export function tranTime(time) {
  let min = Math.floor(time / 60);
  let sec = time % 60;
  if (time) {
    return `${min}分${sec}秒`;
  } else {
    return "已失效";
  }
}

export function getTemptypeList(type) {
  let arr = [1, 2, 4, 8, 16, 32];
  let list = [];
  arr.forEach((item) => {
    if ((type & item) == item) {
      list.push("" + item);
    }
  });
  return list;
}

/**
 * pxToNumber
 * @param value
 * @returns number
 */
export const pxToNumber = (value) => {
  if (!value) {
    return 0;
  }
  const match = value.match(/^\d*(\.\d*)?/);
  return match ? Number(match[0]) : 0;
};

/**
 * getStyleCssText
 * @param styleObj
 * @returns string
 */
export const getStyleCssText = (styleObj) => {
  let str = "";
  Object.entries(styleObj).forEach(([key, value]) => {
    const formatKey = key.replace(
      /[A-Z]/g,
      (v) => `-${v.charAt(0).toLowerCase()}`
    );
    const formatValue = typeof value === "number" ? `${value}px` : value;
    str += `${formatKey}: ${formatValue};`;
  });
  return str;
};

/**
 * getElementSize
 * @param element
 * @returns number
 */
export const getElementSize = (element) => {
  const {
    height,
    marginTop,
    marginBottom,
    paddingTop,
    paddingBottom,
    borderTopWidth,
    borderBottomWidth,
  } = getComputedStyle(element);
  return [
    pxToNumber(height),
    pxToNumber(marginTop),
    pxToNumber(marginBottom),
    pxToNumber(paddingTop),
    pxToNumber(paddingBottom),
    pxToNumber(borderTopWidth),
    pxToNumber(borderBottomWidth),
  ].reduce((prev, cur) => prev + cur);
};
