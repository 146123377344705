import { createSlice } from '@reduxjs/toolkit';
export const appSlice = createSlice({
  name: 'app',
  initialState: {
    theme:'light',

  },
  reducers: {
    setConfig: (state,action) => {
      state.theme  = action.payload.theme;
    },
  
    
  },
});

// 为每个 case reducer 函数生成 Action creators
export const { setConfig } = appSlice.actions;

export default appSlice.reducer;