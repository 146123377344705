import { useState, useRef } from "react";
import styles from "./index.module.scss";
import { Input} from "@arco-design/web-react";
import SendIcon from "../../icons/chat/send.svg";
import {useMobileScreen} from '@/utils/hook'

const TextArea = Input.TextArea;
function ChatInput({ onPressEnter, scrollToBottom,textRef,onPressFocus }) {
  const isMobileScreen = useMobileScreen();

  const [textarea, setTextarea] = useState("");
  const [textList, setTextList] = useState([
    "我需要保持多少库存？如何避免库存过多导致的资金占用，或者库存过少导致的销售损失？",
    "我如何创建吸引人的产品描述和图片？我如何使用关键词来提高产品的搜索排名？",
    "我应该投放哪些产品的广告？我应该在哪些关键词上投放广告？我应该投放多少预算？",
    // "我如何处理客户问题和退货请求？我如何提高客户满意度和好评率？",
    // "我如何研究竞争对手的产品和策略，以便优化自己的产品和策略？",
    // "我如何计算产品成本、运营成本、广告成本和亚马逊的费用，以便了解我是否有利润，以及利润有多少？",
  ]);
  const [selectIndex,setSelectIndex] = useState(1)
  /**
   * @param {enter 输入事件} e
   */
  function enter(e) {
    if (!e.shiftKey) {
      e.preventDefault();
      e.stopPropagation();
      if (textarea) {
        onPressEnter(textarea);
        setTextarea("");
      }
    }
  }
  function onChange(text){
    setTextarea(text);
  }
  return (
    <>
      <div className={`${styles["amz-chat-quck"]} ${isMobileScreen?'text-xs':'text-sm'}`}>
        <div className={styles["amz-chat-quckTitle"]}>
          选择以下话题，快速与我对话：
        </div>
        <div className={`${styles["amz-chat-quckList"]} ${isMobileScreen?"mt-2 max-h-24":'mt-3 max-h-48'}`}>
          {textList.map((item, index) => {
            return (
              <div
                className={`${styles["amz-chat-quckItem"]} ${isMobileScreen?'mb-1 p-2':'mb-2 p-3'}`}
                key={index}
                onClick={() => {
                  setTextarea(item);
                  textRef.current.focus();
                }}
              >
                {item}
              </div>
            );
          })}
        </div>
      </div>

      <div className={styles["amz-chat-textarea"]}>
        <TextArea
          ref={textRef}
          placeholder="请输入想要查询的内容,Shift+Enter换行,Enter发送"
          maxLength={{ length: 2000}}
          // showWordLimit
          autoSize={{ minRows:1, maxRows:8 }}
          style={{
            // height: 54,
            resize: "none",
            background: "#fff",
            borderRadius: 5,
            paddingRight: 40,
            border:'none',
            fontSize:isMobileScreen?12:14
          }}
          value={textarea}
          onFocus={()=> {
            scrollToBottom();
            onPressFocus();
          }}
          onChange={onChange}
          onPressEnter={enter}
        />
        <img
          className={styles["amz-chat-sendbtn"]}
          src={SendIcon}
          onClick={() => {
            onPressEnter(textarea);
            setTextarea("");
          }}
        />
      </div>
    </>
  );
}

export default ChatInput;
