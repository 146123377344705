import styles from "./index.module.scss";


function Agreement() {
  return (
    <div className={styles["amz-agreement"]}>
      <div className={styles["amz-agreement-title"]}>AMZMOSS用户服务协议</div>
      <div className={styles["amz-agreement-time"]}>
        更新日期：2023年6月 7日
      </div>
      <div className={styles["amz-agreement-btitle"]}>1、导言</div>
      <div className={styles["amz-agreement-indent"]}>
        「AMZMOSS」由杭州徕海科技有限公司（以下简称“徕海公司”或“我们”）开发和运营。我们深知个人信息对您的重要性，您的信息对我们非常重要，我们将按照法律法规的规定并参照行业最佳实践保护您的个人信息及隐私安全。我们制定本政策旨在帮助您了解以下内容：
      </div>
      <div className={styles["amz-agreement-indent"]}>
        《AMZMOSS用户服务协议》（以下简称“本协议”）是您与徕海公司就注册、登记、使用（以下统称“使用”）AMZMOSS软件及相关服务所订立的协议。
      </div>
      <div className={styles["amz-agreement-indent"]}>
        为更好地为您提供服务，请您在开始使用AMZMOSS软件及相关服务之前仔细阅读并充分理解本协议，
        <span className={styles["amz-agreement-underline"]}>
          特别是涉及免除或者限制责任的条款、权利许可和信息使用的条款、同意开通和使用特殊单项服务的条款、法律适用和争议解决条款等。其中，免除或者限制责任条款等重要内容将以加粗形式提示您注意，您应重点阅读。如您未满18周岁，请在法定监护人陪同下仔细阅读并充分理解本协议，并征得法定监护人的同意后使用AMZMOSS软件及相关服务。
        </span>
      </div>
      <div className={styles["amz-agreement-indent"]}>
        如您不同意本协议，这将导致我们无法为您提供完整的产品和服务，您也可以选择停止使用。
        <span className={styles["amz-agreement-underline"]}>
          特如您自主选择同意或使用AMZMOSS软件及相关服务，则视为您已充分理解本协议，并同意作为本协议的一方当事人接受本协议以及其他与AMZMOSS软件及相关服务相关的协议和规则（包括但不限于《AMZMOSS隐私政策》《AMZMOSS用户须知》）的约束。
        </span>
      </div>
      <div className={styles["amz-agreement-indent"]}>
        如您对本协议内容有任何疑问、意见或建议，您可通过添加官方网站（www.amzmoss.com）首页右下角的企业微信与我们联系。
      </div>
      <div className={styles["amz-agreement-btitle"]}>
        2、AMZMOSS软件及相关服务
      </div>
      <div className={styles["amz-agreement-content"]}>
        2.1
        您可以根据自身的需求选择使用本协议项下一项或多项 AMZMOSS服务并遵守其服务规则。相应 AMZMOSS 的具体服务内容、服务等级、技术规范、操作文档、计费标准等内容的服务规则，以 AMZMOSS 官网展示的内容为准；如 AMZMOSS 官网与 AMZMOSS 相关页面展示的服务规则相关内容不一致的，则应当以二者中最新发布的文件内容为准。
      </div>
      <div className={styles["amz-agreement-content"]}>
        2.2
        徕海公司作为中立的技术服务提供者，依据本协议以及其他适用的服务规则，许可您对相应 AMZMOSS 服务进行不可转让的、非排他性的使用。本协议未明示授权的其他一切权利仍由徕海公司保留，您在行使这些权利时须另外取得徕海公司的书面许可。徕海公司如果未行使前述任何权利，并不构成对该权利的放弃。
      </div>
      <div className={styles["amz-agreement-content"]}>
        2.3
        AMZMOSS软件及相关服务的具体内容由徕海公司根据实际情况提供，徕海公司有权随时根据AMZMOSS的运营情况对该等服务进行任何形式的变动、调整，并且徕海公司有权针对 AMZMOSS 软件所涉及的某种特定的服务的服务内容、服务方式通过任何形式(包括但不限于系统/网页公告、系统通知等)作出声明、通知，该等声明、通知将被视为是本协议的一部分。在该等声明、通知发布后，您如继续使用AMZMOSS软件或相关服务，则视为您已知悉并同意该等声明、通知的内容。
      </div>
      <div className={styles["amz-agreement-content"]}>
        2.4
        徕海公司保留/持有将全部或部分服务设定为收费服务的权利，如AMZMOSS软件开通任何收费服务，且您选择使用该收费服务时，您需要自行支付一定的费用。对于收费服务，徕海公司会在您使用之前给予您明确的提示，只有当您根据提示确认愿意支付相关费用时，您才能使用该等收费服务。如您拒绝支付相关费用，您可能无法使用该等收费服务。
      </div>
      <div className={styles["amz-agreement-content"]}>
        2.5
        在您同意并接受本协议全部条款的前提下，徕海公司同意授予您一项可撤销的、可变更的、非专有的、不可转让和不可再授权的、仅限于个人使用用途的使用AMZMOSS软件及相关服务的权利。本协议未明示授权的其他一切权利仍由徕海公司保留，您在行使该等权利前须另行获得徕海公司的书面许可，同时徕海公司如未行使前述任何权利，并不构成对该等权利的放弃。
      </div>
      <div className={styles["amz-agreement-content"]}>
        2.6
        未经徕海公司事先书面授权，您不得将AMZMOSS软件或相关服务用于任何商业用途，不得利用或通过AMZMOSS或相关服务进行任何的牟利行为。
      </div>
      <div className={styles["amz-agreement-btitle"]}>3、关于账号</div>
      <div className={styles["amz-agreement-content"]}>
        3.1  AMZMOSS软件及相关服务将使用手机号注册，您有权为自己的账号自行设置符合安全要求的密码。您设置的帐号、密码是您用以登录并以注册用户身份使用AMZMOSS软件及相关服务的凭证。
      </div>
      <div className={styles["amz-agreement-content"]}>
        3.2  在您需要终止使用AMZMOSS帐号服务时，您理解，您仅能申请注销您本人的帐号，并依照AMZMOSS的流程进行注销，且您仍应对您在注销帐号前使用AMZMOSS服务期间的行为承担相应责任。注销成功后，帐号记录、功能等将无法恢复或提供。
      </div>
      <div className={styles["amz-agreement-underline"]}>
        3.3  您理解并承诺，您所设置的帐号不违反国家法律法规及AMZMOSS的相关规则，您的帐号名称、头像和简介等注册信息及其他个人信息中不得出现违法和不良信息，未经他人许可不得用他人名义（包括但不限于冒用他人姓名、名称、字号、头像或其他足以让人引起混淆的方式）开设帐号，不得恶意注册AMZMOSS帐号（包括但不限于频繁注册、批量注册帐号等行为）。徕海公司有权对您提交的信息进行审核。您在帐号注册及使用过程中需遵守相关法律法规，不得实施任何侵害国家利益、损害其他公民合法权益，有害社会道德风尚的行为。
      </div>
      <div className={styles["amz-agreement-underline"]}>
        3.4  您在AMZMOSS注册的帐号仅限于您本人使用，未经徕海公司书面同意，禁止以任何形式赠与、借用、出租、转让、售卖或以其他方式许可他人使用该帐号。如果徕海公司发现或者有合理理由认为使用者并非帐号初始注册人，为保障帐号安全，徕海公司有权立即暂停或终止向该注册帐号提供服务，并有权永久禁用该帐号。
      </div>
      <div className={styles["amz-agreement-content"]}>
        3.5  您有责任维护个人帐号、密码的安全性与保密性，并对您以注册帐号名义所从事的活动承担全部法律责任。您应高度重视对帐号与密码的保密，在任何情况下不向他人透露帐号及密码。若发现他人未经许可使用您的帐号或发生其他任何安全漏洞问题时，您应当立即通知徕海公司。
      </div>
      <div className={styles["amz-agreement-underline"]}>
        3.6  依照国家相关法律法规的规定，为使用AMZMOSS软件及相关服务的部分功能，您需要填写真实的身份信息，请您按照相关法律规定完成实名认证，并注意及时更新上述相关信息。若您提交的材料或提供的信息不准确、不真实、不规范或徕海公司有理由怀疑为错误、不实、不合法的资料，则徕海公司有权拒绝为您提供相关功能，您将无法使用AMZMOSS软件及相关服务或在使用过程中部分功能受到限制。
      </div>
      <div className={styles["amz-agreement-btitle"]}>4、服务费用</div>
      <div className={styles["amz-agreement-underline"]}>
        4.1 您可自行在线选购所需相关付费服务，购买前您需仔细阅读所购服务对应的服务内容、服务规则，并根据您自身需求决定是否购买或使用。
      </div>
      <div className={styles["amz-agreement-underline"]}>
        4.2  您需在服务的购买订单提交后及时完成支付。部分服务可能有费用支付时限、库存限制或者活动数量等限制，购买订单提交后，若发生您未及时付款、或付款期间出现数量不足或已达到限量等情况的，您将可能无法使用相关服务。
      </div>
      <div className={styles["amz-agreement-underline"]}>
        4.3  徕海公司可能因营销活动、服务推广等各种原因，在特定时期内推出AMZMOSS的“限时免费”、“限时优惠”、“限量优惠”、“充值优惠”以及赠送服务等优惠措施（“优惠措施”），您理解并同意，这些优惠措施可能是暂时性、阶段性或有数量限制的，也可能仅适用于满足特定条件的客户，您需要按照相应的规则购买、使用相应服务。除非另有书面明确说明，优惠措施不可同时叠加适用。
      </div>
      <div className={styles["amz-agreement-underline"]}>
        4.4 AMZMOSS的结算方式可能分为预付费和徕海公司公布的其他方式，为保证服务及时开通或持续提供，您应当遵守本协议及所适用的服务规则并及时缴纳费用。许可特定期限内AMZMOSS使用权的服务开通后，即使您未新增服务项目或资源，亦未进行新的操作，但由于该部分服务会持续占用资源，因此将持续产生扣费。
      </div>
      <div className={styles["amz-agreement-underline"]}>
        4.5  您可以通过账号在线付款或充值续费，也可以通过徕海公司允许的其他方式支付费用。为保证服务的持续性，若您选择非账号在线支付费用的方式，应预留合理时间以便AMZMOSS核实到账情况并完成您账号充值。
      </div>
      <div className={styles["amz-agreement-underline"]}>
        4.6  您所选购AMZMOSS的服务费用标准及结算方式，以AMZMOSS官网或相应AMZMOSS的页面展示为准。徕海公司有权提前7天单方调整AMZMOSS的服务费用标准及结算方式，并通过网站公告、邮件通知、短信通知、系统消息、站内信等方式中的一种或多种方式通知您，而无需获得您的事先同意；如您不同意前述调整应当立即停止AMZMOSS的使用，否则继续使用将视为您已同意前述调整，并按照调整后的标准缴纳费用。
      </div>
      <div className={styles["amz-agreement-underline"]}>
        4.7  您应自行承担因使用AMZMOSS服务所产生的费用，包括但不限于上网流量费、通信服务费等。
      </div>
      <div className={styles["amz-agreement-btitle"]}>5、用户行为规范</div>
      <div className={styles["amz-agreement-content"]}>
        5.1  您同意并承诺，在使用AMZMOSS软件及相关服务时，不利用AMZMOSS软件及相关服务制作、复制、发布、传播下列信息：
      </div>
      <div className={styles["amz-agreement-pad"]}>
        (1) 反对《中华人民共和国宪法》确定的基本原则的；
        <br />
        (1) 反对《中华人民共和国宪法》确定的基本原则的；
        <br />
        (2) 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
        <br />
        (3) 损害国家荣誉和利益的；
        <br />
        (4) 损害国家形象的；
        <br />
        (5) 宣扬恐怖主义、极端主义的；
        <br />
        (6) 煽动民族仇恨、民族歧视，破坏民族团结的；
        <br />
        (7) 煽动地域歧视、地域仇恨的；
        <br />
        (8) 煽动非法集会、结社、游行、示威、聚众扰乱社会秩序的;
        <br />
        (9) 以非法民间组织名义活动的;
        <br />
        (10) 破坏国家宗教政策，宣扬邪教和封建迷信的；
        <br />
        (11) 编造、散布谣言、虚假信息，扰乱经济秩序和社会秩序，破坏社会稳定的；
        <br />
        (12) 散布、传播淫秽、色情、赌博、暴力、凶杀、恐怖、高危险性或者教唆犯罪的；
        <br />
        (13) 侵害未成年人合法权益或者损害未成年人身心健康的；
        <br />
        (14) 侮辱或者诽谤他人，侵害他人合法权益的；
        <br />
        (15) 对他人进行暴力恐吓、威胁、实话人肉搜索的；
        <br />
        (16) 涉及他人隐私、个人信息或资料的；
        <br />
        (17) 虚假信息；
        <br />
        (18) 含有侵犯他人知识产权、肖像权、名誉权、隐私权等合法权益的信息的;
        <br />
        (19) 含有其他违反法律法规、政策及公序良俗、干扰AMZMOSS正常运营或侵犯其他用户或第三方合法权益内容的其他信息。
      </div>
      <div className={styles["amz-agreement-content"]}>
        5.2 您使用AMZMOSS软件及相关服务不得具有下列行为
      </div>
      <div className={styles["amz-agreement-pad"]}>
        (1) 修改、翻译、注释、改编、整理、演绎AMZMOSS软件；
        <br />
        (2)
        复制、翻拷、传播和陈列AMZMOSS软件的程序、使用手册和(或)其他图文音像资料的全部或部分内容;
        <br />
        (3)
        通过反向工程、反向编译、反汇编或其他任何手段对AMZMOSS软件进行分析、修改、攻击、衍生;
        <br />
        (4)
        通过任何方式探测、拦截、获取、使用AMZMOSS软件源代码及/或存储于AMZMOSS软件中的任何信息;
        <br />
        (5)
        删除、掩盖或更改徕海公司或任何第三方的版权声明、商标或其它知识产权声明;
        <br />
        (6) 删除、掩盖或更改AMZMOSS软件所展现的任何信息;
        <br />
        (7)
        利用AMZMOSS软件可能存在的技术缺陷或漏洞而以任何形式为自己或他人谋利或者从事其他不正当行为;
        <br />
        (8)
        使用任何方式或方法、试图攻击提供AMZMOSS软件及相关各项服务的相关服务器、路由器、交换机以及其他设备，以达到非法获得或修改未经授权的数据资料、影响正常的AMZMOSS软件服务秩序，以及其他危害性目的的行为;
        <br />
        (9)
        使用任何方式破坏、干扰AMZMOSS软件及相关服务，恶意破坏AMZMOSS软件，扰乱正常的服务秩序或故意传播恶意程序或病毒以及其他破坏干扰正常网络信息服务的行为；
        <br />
        (10) 制作、发布、传播、使用任何形式的妨碍AMZMOSS软件及相关服务的辅助工具或程序;
        <br />
        (11) 违法删除、篡改、隐匿AMZMOSS在生成内容上提示深度合成情况的标识；
        <br />
        (12) 恶意注册（包括但不限于频繁、批量注册）、盗取或使用他人账号或密码登录/使用AMZMOSS软件;
        <br />
        (13) 其他影响徕海公司品牌形象或公司利益的行为。
      </div>
      <div className={styles["amz-agreement-indent"]}>
        在您使用AMZMOSS软件及相关服务时，徕海公司有权根据相关法律法规等规定，对您输入的信息、通过AMZMOSS服务生成的结果等使用行为进行审核，如果徕海公司有理由认为您的行为违反或可能违反上述约定的，徕海公司可独立进行判断并处理，且有权在不事先通知的情况下终止向您提供服务，并追究相关法律责任。
      </div>
      <div className={styles["amz-agreement-content"]}>
        5.3  您在使用AMZMOSS软件及相关服务时，不得损害、禁用、影响徕海公司的其他产品或服务或使徕海公司的其他产品或服务负担过重，也不得影响任何其它方享用徕海公司的其他产品或服务。如您对AMZMOSS软件或相关服务有任何意见或改进的建议，欢迎您通过正规渠道向徕海公司提出。
      </div>
      <div className={styles["amz-agreement-btitle"]}>6、知识产权</div>
      <div className={styles["amz-agreement-content"]}>
        6.1  徕海公司依法享有AMZMOSS软件及相关服务中提供的内容（包括但不限于软件、技术、程序、网页、文字表述及其组合、图片、图像、图表、图标、图饰、图标、音频、视频、色彩组合、界面设计、版面框架、有关数据、印刷材料、电子文档等）的一切合法权属(包括但不限于知识产权)。徕海公司提供AMZMOSS软件及其相关服务所依托的全部著作权及一切其他知识产权均归属徕海公司所有。未经徕海公司事先书面许可，任何人不得对其进行任何未经授权的使用，包括但不限于不得进行复制、发行、展览、改编、汇编、出版、传播、出售等。
      </div>
      <div className={styles["amz-agreement-content"]}>
        6.2  AMZMOSS软件及其相关服务所体现的“徕海公司”、AMZMOSS徽标(图形)和/或徕海公司的产品和服务标识均属于徕海公司及其关联公司在中国及/或其他国家/地区的商标或者注册商标。未经徕海公司书面合法授权，您不得使用该等产品和服务标识。
      </div>
      <div className={styles["amz-agreement-content"]}>
        6.3  徕海公司及其关联公司在涉及AMZMOSS软件及相关服务中拥有专利权、商标权、著作权及其他知识产权。徕海公司及其关联公司向您提供AMZMOSS软件及相关服务，并不视为向您授予任何与徕海公司及其关联公司、AMZMOSS软件或其他与徕海公司相关的任何知识产权。
      </div>
      <div className={styles["amz-agreement-content"]}>
        6.4  您理解并同意，对于您通过AMZMOSS软件及相关服务上传、发布、传播的任何信息、内容(是否提供/开放该种可发布信息、内容的功能及何时提供该种功能由徕海公司自行决定)，您授予徕海公司一项在全世界范围内的免费的、永久性的、非独家的、不可撤销的授权及许可，徕海公司有权自行或许可第三方完全或部分使用、复制、改编、汇编、出版、翻译、据此创作衍生作品、传播、表演和展示该部分信息及内容，和/或将该部分内容的全部或部分编入徕海公司及/或关联公司的任何作品、服务或技术中。
      </div>
      <div className={styles["amz-agreement-content"]}>
        6.5  本协议未明确授予您的权利均由徕海公司及其关联公司保留。
      </div>
      <div className={styles["amz-agreement-btitle"]}>
        7、服务的变更、中止和终止
      </div>
      <div className={styles["amz-agreement-content"]}>
        7.1 您理解并同意，徕海公司提供的AMZMOSS软件及相关服务是按照现有技术和条件所能达到的现状提供的。徕海公司会尽最大努力向您提供服务，确保服务的连贯性和安全性。您理解，徕海公司不能随时预见和防范技术以及其他风险，包括但不限于不可抗力、网络原因、第三方服务瑕疵、第三方网站等原因可能导致的服务中断、不能正常使用AMZMOSS软件及服务以及其他的损失和风险。
      </div>
      <div className={styles["amz-agreement-content"]}>
        7.2 您理解并同意，为保障徕海公司业务发展，徕海公司保留随时变更(包括但不限于升级、更新、增加、减少、演绎，等)及中断或终止提供AMZMOSS软件及相关服务的权利。如有必要，徕海公司会通过系统公告、通知或徕海公司认为恰当之方式进行公布，请您留意及时查阅了解相关情况。在前述情况下，您可选择单方要求终止本协议及AMZMOSS服务，但是，徕海公司无需向您退还已使用AMZMOSS服务的费用，且您如存在欠费的，徕海公司仍有权向您进行追偿。如您继续使用AMZMOSS服务，将视为您已同意前述调整、终止，并同意接受调整后的服务内容，前述调整将在徕海公司指定的日期生效，而无需再另行获得您同意
      </div>
      <div className={styles["amz-agreement-content"]}>
        7.3 基于网络服务的及时性、复杂性、高效性等特性及监管要求、政策调整等原因，您同意AMZMOSS可以随时对AMZMOSS的服务规则进行调整，但是，AMZMOSS应在合理期限内提前通过
        <span className={styles["amz-agreement-underline"]}>
          【网站公告、邮件通知、短信通知、系统消息、站内信等方式中的一种或多种方式】
        </span>
        通知您。在前述情况下，您可选择单方要求终止本协议及AMZMOSS服务，但是，徕海公司无需向您退还已使用AMZMOSS服务的费用，且您如存在欠费的，徕海公司仍有权向您进行追偿。 如您继续使用AMZMOSS服务，将视为您已同意前述调整，并同意接受调整后的服务规则，前述调整将在徕海公司指定的日期生效，而无需再另行获得您同意。
      </div>
      <div className={styles["amz-agreement-content"]}>
        7.4  如您违反了本协议或其他服务规则的任一条款，且未在徕海公司通知的期限内纠正的，则徕海公司有权终止向您提供AMZMOSS服务，而无需承担任何责任。
      </div>
      <div className={styles["amz-agreement-btitle"]}>8、广告</div>
      <div className={styles["amz-agreement-content"]}>
        8.1  您理解并同意，在使用AMZMOSS软件及相关服务过程中徕海公司可能会向您推送具有相关性的信息、广告发布或品牌推广服务，且徕海公司将在AMZMOSS软件及相关服务中展示AMZMOSS软件及相关服务和/或第三方供应商、合作伙伴的商业广告、推广或信息（包括商业或非商业信息）。
      </div>
      <div className={styles["amz-agreement-content"]}>
        8.2  徕海公司依照法律规定履行广告及推广相关义务，您应当自行判断该广告或推广信息的真实性和可靠性并为自己的判断行为负责。除法律法规明确规定外，您因该广告或推广信息进行的购买、交易或因前述内容遭受的损害或损失，您应自行承担，徕海公司不予承担责任。
      </div>
      <div className={styles["amz-agreement-btitle"]}>9、免责声明</div>
      <div className={styles["amz-agreement-content"]}>
        9.1  您理解并同意，AMZMOSS软件及相关服务可能会受多种因素的影响或干扰，在适用法律允许的最大范围内，
        <span className={styles["amz-agreement-underline"]}>
          除在本协议项下已明示提供的相关保证外，徕海公司明确表示不提供任何其他类型的保证，不论是明示的或默示的，
        </span>
        包括但不限于：
      </div>
      <div className={styles["amz-agreement-pad"]}>
        (1) AMZMOSS软件及相关服务一定能满足您的要求；
        <br />
        (2) AMZMOSS软件及相关服务不受干扰、不被修改、中断或终止；
        <br />
        (3)
        AMZMOSS软件及相关服务的适销性、适用性、及时性、安全性、可靠性、稳定性、准确性、完整性、无病毒或不出现任何错误以及信息传送的准确、及时、顺利；
        <br />
        (4) AMZMOSS软件及相关服务中任何错误都将能得到更正。
      </div>
      <div className={styles["amz-agreement-underline"]}>
        9.2  在使用AMZMOSS软件及相关服务过程中，如有涉嫌借款、投融资、理财或其他涉财产的网络信息、帐户密码、广告或推广等信息，请您谨慎对待并自行进行判断，对您因此遭受的利润、商业信誉、资料损失或其他有形或无形损失，徕海公司不承担任何直接、间接、附带、特别、衍生性或惩罚性的赔偿责任。
      </div>
      <div className={styles["amz-agreement-underline"]}>
        9.3  您理解并同意，在使用AMZMOSS软件及相关服务过程中，可能遇到不可抗力等因素（不可抗力是指不能预见、不能克服并不能避免的客观事件），包括但不限于自然灾害(如洪水、地震、台风等)、政府行为、战争、罢工、骚乱等。出现不可抗力情况时，徕海公司将努力在第一时间及时修复，但因不可抗力造成的暂停、中止、终止服务或造成的任何损失，徕海公司在法律法规允许范围内免于承担责任。
      </div>
      <div className={styles["amz-agreement-underline"]}>
        9.4  徕海公司依据本协议约定获得处理违法违规内容的权利，该权利不构成徕海公司的义务或承诺，徕海公司不能保证及时发现违法行为或进行相应处理。
      </div>
      <div className={styles["amz-agreement-underline"]}>
        9.5  您理解并同意，本协议旨在保障遵守国家法律法规、维护公序良俗，保护用户和他人合法权益，徕海公司在能力范围内尽最大的努力按照相关法律法规进行判断，但并不保证徕海公司的判断完全与司法机关、行政机关的判断一致，如因此产生的后果您已经理解并同意自行承担。
      </div>
      <div className={styles["amz-agreement-underline"]}>
        9.6  在任何情况下，徕海公司均不对任何间接性、后果性、惩罚性、偶然性、特殊性或刑罚性的损害，包括因您使用AMZMOSS软件及相关服务而遭受的利润损失，承担责任。除法律法规另有明确规定外，徕海公司对您承担的全部责任，无论因何原因或何种行为方式，始终不超过您在使用AMZMOSS软件及相关服务期间支付给徕海公司的费用(如有)。
      </div>

      <div className={styles["amz-agreement-btitle"]}>10、违约处理</div>
      <div className={styles["amz-agreement-content"]}>
        10.1  徕海公司有权对您使用AMZMOSS软件及相关服务的情况进行审查和监督。如您违反法律、法规、相关政策、本协议或其他服务条款规定，或经徕海公司判断用户的账号数据异常，徕海公司有权要求用户改正或直接视情况采取必要的措施(包括但不限于预先警示、拒绝发布、立即停止传输信息、删除内容或评论、短期禁止发布内容或评论、限制用户账号的部分或全部功能、限制/暂停/终止用户在AMZMOSS软件及相关服务中的活动/权限、终止AMZMOSS软件及相关服务的授权、强制离线、封停账号、更改或删除用户相关信息内容等)，对于因此而造成您无法正常使用帐号及相关服务、无法正常获取您帐号内资产或其他权益等后果，徕海公司不承担任何责任。徕海公司有权公告处理结果，且有权根据实际情况决定是否恢复相关帐号的使用。对涉嫌违反法律法规、涉嫌违法犯罪的行为，徕海公司将保存有关记录，并有权依法向有关主管部门报告、配合有关主管部门调查、向公安机关报案等。对已删除内容徕海公司有权不予恢复。
      </div>
      <div className={styles["amz-agreement-content"]}>
        10.2  因您违反法律、法规、相关政策、本协议或其他服务条款规定，引起第三方投诉或诉讼索赔的，徕海公司有权在收到权利方或者相关方通知的情况下采取一切合法、合理的措施。针对第三方提出的全部权利主张，您应当自行处理并承担全部可能由此引起的法律责任。因您的违法、侵权或违约等行为导致徕海公司及其关联方、控制公司、继承公司向任何第三方赔偿或遭受国家机关处罚的，您还应足额赔偿徕海公司及其关联方、控制公司、继承公司因此遭受的全部损失（包括但不限于经济损失、商誉损失、鉴定费、评估费、公证费、处罚费、律师费、诉讼费、仲裁费、损害补偿费及其他侵权赔偿费用）。
      </div>
      <div className={styles["amz-agreement-btitle"]}>11、法律及管辖</div>
      <div className={styles["amz-agreement-content"]}>
        11.1  本协议的成立、生效、履行、解释及争议的解决均应适用中华人民共和国法律。若本协议之任何规定因与中华人民共和国的法律抵触而无效或不可执行，则这些条款应在不违反法律的前提下尽可能按照接近本协议原条文目的之原则进行解释和使用，且本协议其它规定仍应具有完整的效力及效果。
      </div>
      <div className={styles["amz-agreement-content"]}>
        11.2  本协议签订地为中华人民共和国福建省福州市鼓楼区。若您与徕海公司发生任何争议，双方应尽量友好协商解决，如协商不成，您同意与徕海公司双方将争议提交至
        <span className={styles["amz-agreement-underline"]}>
          杭州仲裁委员会
        </span>
        ，并按照届时有效的仲裁规则进行仲裁。
      </div>
      <div className={styles["amz-agreement-btitle"]}>12、其他</div>
      <div className={styles["amz-agreement-underline"]}>
        12.1  为给您提供更好的服务或国家法律法规、政策调整、技术条件、产品功能等变化需要，徕海公司会适时对本协议进行修订，修订内容构成本协议的组成部分。本协议条款一旦发生变动，徕海公司会在“AMZMOSS”发出更新版本，并通过系统公告、通知或其他徕海公司认为恰当之方式进行提醒您。您在使用AMZMOSS软件及相关服务时，应当及时查阅了解修改的内容。如您继续登录或使用AMZMOSS软件及相关服务，则视为您认可并接受修改后的协议条款;如您对修改后的协议条款有异议的，请立即停止登录或停止使用AMZMOSS软件及相关服务。
      </div>
      <div className={styles["amz-agreement-content"]}>
        12.2  徕海公司有权依AMZMOSS软件及相关服务或运营的需要单方决定，安排或指定其关联方、控制公司、继承公司或徕海公司认可的第三方公司继续运营AMZMOSS软件。并且，就本协议项下涉及的某些服务，可能会由徕海公司的关联方、控制公司、继承公司或徕海公司认可的第三方公司向您提供。您知晓并同意接受相关服务内容，即视为接受相关权利义务关系亦受本协议约束。
      </div>
      <div className={styles["amz-agreement-content"]}>
        12.3  本协议中的标题仅为方便阅读而设，不应影响本协议任何条款的含义或解释。
      </div>
      <div className={styles["amz-agreement-content"]}>
        12.4  如本协议中任何条款因任何原因被判定为完全或部分无效或不具有执行力的，该无效或不具有执行力的条款将被最接近原条款义务的一项有效并可执行的条款所替代，并且本协议的其余条款仍应有效并具有执行力。
      </div>
      <div className={styles["amz-agreement-content"]}>
        12.5  徕海公司不行使、未能及时行使或者未充分行使本条款或者按照法律规定所享有的权利，不应被视为放弃该权利，也不影响徕海公司在将来行使该权利。
      </div>
      <div className={styles["amz-agreement-content"]}>
        12.6  您和徕海公司均是独立的主体，在任何情况下本协议不构成徕海公司及其关联公司对您的任何形式的明示或暗示担保或条件，双方之间亦不构成代理、合伙、合营或雇佣关系。
      </div>
    </div>
  );
}
export default Agreement;
