import { createSlice } from '@reduxjs/toolkit';
export const appSlice = createSlice({
  name: 'app',
  initialState: {
    username: 'lanmo',
    password:'930152999',
    aiToken:localStorage.aiToken||''
  },
  reducers: {
    setUsername: (state,action) => {
      state.username  = action.payload.username;
    },
    setPassword: (state,action) => {
      state.password = action.payload;
    },
    setToken:(state,action) => {
      state.aiToken = action.payload.token;
      localStorage.aiToken = action.payload.token
    },
    clearToken:(state) => {
      // state.aiToken = '';
      localStorage.aiToken = ''
      localStorage.localChatList=''
      localStorage.localUuid = ''
      window.location.reload()
    }
    
  },
});

// 为每个 case reducer 函数生成 Action creators
export const { setUsername, setPassword, setToken,clearToken } = appSlice.actions;

export default appSlice.reducer;