import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Select, Tooltip } from "@arco-design/web-react";
import styles from "./index.module.scss";
import { useSelector } from "react-redux";
import Logo from "../../icons/logo.svg";
import { ReactComponent as IconLogo } from "@/icons/logo.svg";
import LoginPwd from "../../icons/login/pwd_login.svg";
import LoginCode from "../../icons/login/code_login.svg";
import locales, { changeLang, getLang } from "../../locales";

import Wechat from "./wechat";
import Pwd from "./password";
import Code from "./code";
import BindWei from "./bindWechat";
// import { isMobileScreen } from "../../utils";
import { useMobileScreen } from "@/utils/hook";
const language = [
  { label: "简体中文", value: "cn" },
  { label: "English", value: "en" },
];
function Login({ name }) {
  window.location.href = 'https://ai.amztab.com/auth'
  return <></>
  //方法
  const navigate = useNavigate();
  //变量
  const isMobileScreen = useMobileScreen();
  const [isRegist, setIsResist] = useState(sessionStorage.isRegist || false);
  const [loginWay, setLoginWay] = useState(sessionStorage.loginWay || (isMobileScreen ? 2 : 1)); //1wechat 2code 3pwd

  //token信息
  const token = useSelector((state) => state.app.aiToken);
  //有token跳转到主页
  useEffect(() => {
    // if (token) navigate("/chat");
  });

  /**
   *
   * @param {登录方式} type 1 微信扫码登录 2 验证码登录 3 账号密码登录
   */
  function changeLoginWay(type) {
    setLoginWay(type);
    if (type > 3) return;
    sessionStorage.loginWay = type;
  }

  return (
    <div className={`${styles["amz-login"]} h-screen ${isMobileScreen ? '' : ''}`}

    >
      <div
        className={
          styles["amz-login-banner"] +
          ` ${isMobileScreen ? styles["amz-login-bannerhide"] : ""}`
        }
      >
        <IconLogo className={styles["amz-login-logo"]} />
        {/* <div className={styles["amz-login-title"]}>
          {locales.LOGIN.BannerTitle1}
        </div> */}
        <div className={styles["amz-login-title"]}>
          {locales.LOGIN.BannerTitle2}
        </div>
        <div className={styles["amz-login-descript"]}>
          您的跨境电商AI运营助手
        </div>
      </div>
      <div className={styles["amz-login-box"]}>
        <div className={styles["amz-login-box-top"]}>
          {/* <Select
            defaultValue={getLang()}
            bordered={false}
            style={{
              width: 120,
            }}
            onChange={changeLang}
            options={language}
          /> */}
          {/* <span>{locales.LOGIN.Help}</span> */}
        </div>

        <div
          style={{ height: isMobileScreen ? 460 : 520 }}
          className={`${styles["amz-login-content"]} w-11/12 ${isMobileScreen ? '' : 'max-w-lg'}`} >
          {isRegist ? (
            <></>
          ) : (
            <>
              <div
                className={`${styles["amz-login-way"]} ${isMobileScreen ? 'w-16' : 'w-24'}`}
                onClick={() => {
                  changeLoginWay(loginWay == 1 ? 2 : 1);
                }}
              >
                <Tooltip
                  key="#3C6EF6"
                  color="#3C6EF6"
                  position="top"
                  trigger="hover"
                  content={loginWay == 1 ? "账号登录" : "扫码登录"}
                >
                  <img src={loginWay == 1 ? LoginPwd : LoginCode} />
                </Tooltip>
              </div>
              {loginWay == 1 ? (
                <Wechat changeLoginWay={changeLoginWay} />
              ) : null}
              {loginWay == 2 ? <Code changeLoginWay={changeLoginWay} /> : null}
              {loginWay == 3 ? <Pwd changeLoginWay={changeLoginWay} /> : null}
              {loginWay == 4 ? (
                <BindWei changeLoginWay={changeLoginWay} />
              ) : null}

              <div className={`${styles["amz-login-box-bottom"]} ${isMobileScreen ? 'text-xs' : 'text-sm'}`}>
                注册登录即代表同意 <a href="/#/userAgreement" target="_blank" style={{ textDecoration: 'none' }} rel="noopener noreferrer">《AMZMOSS用户协议》</a>
                <a href="/#/yinsi" target="_blank" style={{ textDecoration: 'none' }} rel="noopener noreferrer">《用户隐私政策》</a>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
export default Login;
