import { useState, useCallback } from "react";
import styles from "./index.module.scss";
import { Image } from "@arco-design/web-react";
import ReactDOM from "react-dom";
import React from "react";
import Waterfall from "react-silky-waterfall";
import { useMobileScreen } from "@/utils/hook";
import { getStyleCssText, getElementSize } from "@/utils";
const extraStyle = {
  margin: "10px 0",
  padding: "0 16px",
  fontSize: 14,
  wordBreak: "break-all",
};

const extraSizeGetter = (data, columnWidth) => {
  const { name } = data;
  if (!name) {
    return 0;
  }
  const box = document.createElement("div");
  box.style.width = `${columnWidth}px`;
  const extra = document.createElement("p");
  extra.style.cssText = getStyleCssText(extraStyle);
  extra.textContent = name;
  box.append(extra);
  document.body.append(box);
  const size = getElementSize(extra);
  box.remove();
  return size + 36;
};
const ImgList = () => {
  const isMobileScreen = useMobileScreen();
  const [dataSource, setDataSource] = useState([]);
  const [prevImg, setPrevImg] = useState("");
  const [visible, setVisible] = useState(false);
  const getDatasource = useCallback(() => {
    const responseData = [
      {
        id: "485453",
        userId: "34950",
        nickName: "眞漫",
        avatarUrl:
          "http://tc.cos.cdn.jchdnet.cn/tables/20@3x_1682756039892.png",
        vipType: "1",
        gender: null,
        keyWord: null,
        type: "sd",
        taskId: "1691135288966365373",
        createTime: "2023-08-04 07:48:08",
        collectTime: null,
        channel: "sd",
        releaseId: "2666",
        collect: false,
        name: "漂亮",
        price: 2,
        src: "https://cdn-sh1.tcbot.cc/sd/test/1691135288966365373_0.png",
        sdCreateRes: {
          id: null,
          prompt: null,
          negativePrompt: null,
          steps: null,
          width: 1024,
          height: 575,
          cfgScale: null,
          batchSize: 2,
          seed: null,
          samplerIndex: null,
          restoreFaces: null,
          overrideSettings: null,
          loras: [],
          userId: "34950",
          initImagesUrl: "",
          taskId: "1691135288966365373",
          cosUrls: null,
          cosUrl: null,
          status: "create",
          proportion: "16:9",
          process: 0,
          errorMsg: null,
          createDate: "2023-08-04 07:48:08",
          collects: [],
        },
        isBuy: false,
        favorites: 0,
      },
      {
        id: "485599",
        userId: "94",
        nickName: "吾五",
        avatarUrl:
          "https://tc.cos.cdn.jchdnet.cn/tables/cfb886a7-2f84-4418-a2da-7e8d394c1cc7_1689260536579.png",
        vipType: "12",
        gender: null,
        keyWord: null,
        type: "sd",
        taskId: "1691136482393404405",
        createTime: "2023-08-04 08:08:02",
        collectTime: null,
        channel: "sd",
        releaseId: "2667",
        collect: false,
        name: "胶片少女",
        price: 5,
        src: "https://cdn-sh1.tcbot.cc/sd/test/1691136482393404405_0.png",
        sdCreateRes: {
          id: null,
          prompt: null,
          negativePrompt: null,
          steps: null,
          width: 768,
          height: 768,
          cfgScale: null,
          batchSize: 2,
          seed: null,
          samplerIndex: null,
          restoreFaces: null,
          overrideSettings: null,
          loras: [],
          userId: "94",
          initImagesUrl: "",
          taskId: "1691136482393404405",
          cosUrls: null,
          cosUrl: null,
          status: "create",
          proportion: "1:1",
          process: 0,
          errorMsg: null,
          createDate: "2023-08-04 08:08:02",
          collects: [],
        },
        isBuy: false,
        favorites: 0,
      },
      {
        id: "485655",
        userId: "94",
        nickName: "吾五",
        avatarUrl:
          "https://tc.cos.cdn.jchdnet.cn/tables/cfb886a7-2f84-4418-a2da-7e8d394c1cc7_1689260536579.png",
        vipType: "12",
        gender: null,
        keyWord: null,
        type: "sd",
        taskId: "1691137064880474417",
        createTime: "2023-08-04 08:17:44",
        collectTime: null,
        channel: "sd",
        releaseId: "2671",
        collect: false,
        name: "宋朝汉服",
        price: 10,
        src: "https://cdn-sh1.tcbot.cc/sd/test/1691137064880474417_1.png",
        sdCreateRes: {
          id: null,
          prompt: null,
          negativePrompt: null,
          steps: null,
          width: 768,
          height: 768,
          cfgScale: null,
          batchSize: 2,
          seed: null,
          samplerIndex: null,
          restoreFaces: null,
          overrideSettings: null,
          loras: [],
          userId: "94",
          initImagesUrl: "",
          taskId: "1691137064880474417",
          cosUrls: null,
          cosUrl: null,
          status: "create",
          proportion: "1:1",
          process: 0,
          errorMsg: null,
          createDate: "2023-08-04 08:17:44",
          collects: [],
        },
        isBuy: false,
        favorites: 0,
      },
      {
        id: "485663",
        userId: "94",
        nickName: "吾五",
        avatarUrl:
          "https://tc.cos.cdn.jchdnet.cn/tables/cfb886a7-2f84-4418-a2da-7e8d394c1cc7_1689260536579.png",
        vipType: "12",
        gender: null,
        keyWord: null,
        type: "sd",
        taskId: "1691137204643623423",
        createTime: "2023-08-04 08:20:04",
        collectTime: null,
        channel: "sd",
        releaseId: "2673",
        collect: false,
        name: "宋朝汉服",
        price: 10,
        src: "https://cdn-sh1.tcbot.cc/sd/test/1691137204643623423_1.png",
        sdCreateRes: {
          id: null,
          prompt: null,
          negativePrompt: null,
          steps: null,
          width: 768,
          height: 768,
          cfgScale: null,
          batchSize: 2,
          seed: null,
          samplerIndex: null,
          restoreFaces: null,
          overrideSettings: null,
          loras: [],
          userId: "94",
          initImagesUrl: "",
          taskId: "1691137204643623423",
          cosUrls: null,
          cosUrl: null,
          status: "create",
          proportion: "1:1",
          process: 0,
          errorMsg: null,
          createDate: "2023-08-04 08:20:04",
          collects: [],
        },
        isBuy: false,
        favorites: 1,
      },
      {
        id: "480179",
        userId: "34461",
        nickName: "UB6X",
        avatarUrl:
          "http://tc.cos.cdn.jchdnet.cn/tables/23@3x_1682756151799.png",
        vipType: "1",
        gender: null,
        keyWord:
          "a stunning 8k wallpaper in official art style, highly detailed and aesthetically pleasing, a masterpiece of the highest quality. Featuring a zentangleinspired design with mandalas and intricate knots, incorporating elements of fractal art. The focal point is a dynamic angle of a woman in a cowboy shot, showcasing the beautiful chaos and elegance of the wild beast design. Vibrant colors, influenced by the romanticism of James Jean, Robbie Trevino, Ross Tran, Francis Bacon, Mihail Mraz, Adrian Gueni, Petra Kotrait, Gerhard Richter, and Yamamoto Takahito. Atmospheric and exuberant, with visible flowing musical notes.",
        type: "sd",
        taskId: "1691052636424845887",
        createTime: "2023-08-03 08:50:36",
        collectTime: null,
        channel: "sd",
        releaseId: "2676",
        collect: false,
        name: "1",
        price: 0,
        src: "https://cdn-sh1.tcbot.cc/sd/test/1691052636424845887_0.png",
        sdCreateRes: {
          id: null,
          prompt:
            "a stunning 8k wallpaper in official art style, highly detailed and aesthetically pleasing, a masterpiece of the highest quality. Featuring a zentangleinspired design with mandalas and intricate knots, incorporating elements of fractal art. The focal point is a dynamic angle of a woman in a cowboy shot, showcasing the beautiful chaos and elegance of the wild beast design. Vibrant colors, influenced by the romanticism of James Jean, Robbie Trevino, Ross Tran, Francis Bacon, Mihail Mraz, Adrian Gueni, Petra Kotrait, Gerhard Richter, and Yamamoto Takahito. Atmospheric and exuberant, with visible flowing musical notes.",
          negativePrompt:
            "(((simple background))),monochrome ,lowres, bad anatomy, bad hands, text, error, missing fingers, extra digit, fewer digits, cropped, worst quality, low quality, normal quality, jpeg artifacts, signature, watermark, username, blurry, lowres, bad anatomy, bad hands, text, error, extra digit, fewer digits, cropped, worst quality, low quality, normal quality, jpeg artifacts, signature, watermark, username, blurry, ugly,pregnant,vore,duplicate,morbid,mut ilated,tran nsexual, hermaphrodite,long neck,mutated hands,poorly drawn hands,poorly drawn face,mutation,deformed,blurry,bad anatomy,bad proportions,malformed limbs,extra limbs,cloned face,disfigured,gross proportions, (((missing arms))),(((missing legs))), (((extra arms))),(((extra legs))),pubic hair, plump,bad legs,error legs,username,blurry,bad feet",
          steps: 29,
          width: 512,
          height: 1024,
          cfgScale: 7,
          batchSize: 2,
          seed: "-1",
          samplerIndex: "DPM++ 2M SDE Karras",
          restoreFaces: true,
          overrideSettings: {
            id: "253",
            title: "洛菲女孩摄影.safetensors",
            modelName: "洛菲女孩摄影",
            hash: "",
            sha256: "",
            filename:
              "/root/sd-web-ui/models/Stable-diffusion/洛菲女孩摄影.safetensors",
            imgUrl: "https://cdn-sh1.tcbot.cc/sd_model/洛菲女孩摄影.png",
            sd_vae: "animevae.pt",
            CLIP_stop_at_last_layers: 2,
          },
          loras: [],
          userId: "34461",
          initImagesUrl: "",
          taskId: "1691052636424845887",
          cosUrls: null,
          cosUrl: null,
          status: "create",
          proportion: "1:2",
          process: 0,
          errorMsg: null,
          createDate: "2023-08-03 08:50:36",
          collects: [],
        },
        isBuy: false,
        favorites: 0,
      },
      {
        id: "485988",
        userId: "35595",
        nickName: "GS9E",
        avatarUrl: "http://tc.cos.cdn.jchdnet.cn/tables/3@3x_1682755522946.png",
        vipType: "0",
        gender: null,
        keyWord:
          "Portrait of a beautiful woman , drenched body, emerging from the water, dark fantasy, regal, fractal crystal, fractal gemstones, by stanley artgerm lau, thomas kindkade, loish, norman rockwell<lora::1.0>",
        type: "sd",
        taskId: "1691140225946240495",
        createTime: "2023-08-04 09:10:25",
        collectTime: null,
        channel: "sd",
        releaseId: "2678",
        collect: false,
        name: "水中美女",
        price: 0,
        src: "https://cdn-sh1.tcbot.cc/sd/test/1691140225946240495_0.png",
        sdCreateRes: {
          id: null,
          prompt:
            "Portrait of a beautiful woman , drenched body, emerging from the water, dark fantasy, regal, fractal crystal, fractal gemstones, by stanley artgerm lau, thomas kindkade, loish, norman rockwell",
          negativePrompt: "",
          steps: 42,
          width: 512,
          height: 1024,
          cfgScale: 7,
          batchSize: 2,
          seed: "-1",
          samplerIndex: "DPM++ 2M SDE",
          restoreFaces: true,
          overrideSettings: {
            id: "257",
            title: "真人摄像.safetensors",
            modelName: "真人摄像",
            hash: "",
            sha256: "",
            filename:
              "/root/sd-web-ui/models/Stable-diffusion/真人摄像.safetensors",
            imgUrl: "https://cdn-sh1.tcbot.cc/sd_model/真人摄像.png",
            sd_vae: "Automatic",
            CLIP_stop_at_last_layers: 2,
          },
          loras: [
            {
              id: "67",
              path: "/root/sd-web-ui/models/Lora/发光少女.safetensors",
              name: "发光少女",
              alias: "bok_20230607234103",
              createDate: "2023-07-12 13:00:34",
              weight: 1,
              imgUrl: "https://cdn-sh1.tcbot.cc/sd_lora/发光少女.png",
              loraName: "bok_20230607234103",
            },
          ],
          userId: "35595",
          initImagesUrl: "",
          taskId: "1691140225946240495",
          cosUrls: null,
          cosUrl: null,
          status: "create",
          proportion: "1:2",
          process: 0,
          errorMsg: null,
          createDate: "2023-08-04 09:10:25",
          collects: [],
        },
        isBuy: false,
        favorites: 2,
      },
      {
        id: "486316",
        userId: "34869",
        nickName: "9OAU",
        avatarUrl: "http://tc.cos.cdn.jchdnet.cn/tables/6@3x_1682755604621.png",
        vipType: "1",
        gender: null,
        keyWord:
          "一个女孩站在沙滩上，最高质量，4K，正面视角，全身照，中国女孩，瘦，大乳房，双马尾，紫发，温柔的微笑，耳朵，眼睛，红嘴唇，弹弓泳衣，大腿，短裙，渔网袜，海洋背景，云朵<lora:胶片质地少女:1.0>",
        type: "sd",
        taskId: "1691142311743395569",
        createTime: "2023-08-04 09:45:11",
        collectTime: null,
        channel: "sd",
        releaseId: "2681",
        collect: false,
        name: "1",
        price: 0,
        src: "https://cdn-sh1.tcbot.cc/sd/test/1691142311743395569_0.png",
        sdCreateRes: {
          id: null,
          prompt:
            "一个女孩站在沙滩上，最高质量，4K，正面视角，全身照，中国女孩，瘦，大乳房，双马尾，紫发，温柔的微笑，耳朵，眼睛，红嘴唇，弹弓泳衣，大腿，短裙，渔网袜，海洋背景，云朵",
          negativePrompt: "室内，车内，坐着，跪着，半身照，粗大腿",
          steps: 80,
          width: 575,
          height: 1024,
          cfgScale: 12,
          batchSize: 1,
          seed: "-1",
          samplerIndex: "Euler a",
          restoreFaces: true,
          overrideSettings: {
            id: "253",
            title: "洛菲女孩摄影.safetensors",
            modelName: "洛菲女孩摄影",
            hash: "",
            sha256: "",
            filename:
              "/root/sd-web-ui/models/Stable-diffusion/洛菲女孩摄影.safetensors",
            imgUrl: "https://cdn-sh1.tcbot.cc/sd_model/洛菲女孩摄影.png",
            sd_vae: "Automatic",
            CLIP_stop_at_last_layers: 1,
          },
          loras: [
            {
              id: "124",
              path: "/root/sd-web-ui/models/Lora/胶片质地少女.safetensors",
              name: "胶片质地少女",
              alias: "胶片质地少女",
              createDate: "2023-07-12 13:00:34",
              weight: 1,
              imgUrl: "https://cdn-sh1.tcbot.cc/sd_lora/胶片质地少女.png",
              loraName: "胶片质地少女",
            },
          ],
          userId: "34869",
          initImagesUrl: "",
          taskId: "1691142311743395569",
          cosUrls: null,
          cosUrl: null,
          status: "create",
          proportion: "9:16",
          process: 0,
          errorMsg: null,
          createDate: "2023-08-04 09:45:11",
          collects: [],
        },
        isBuy: false,
        favorites: 0,
      },
      {
        id: "483617",
        userId: "94",
        nickName: "吾五",
        avatarUrl:
          "https://tc.cos.cdn.jchdnet.cn/tables/cfb886a7-2f84-4418-a2da-7e8d394c1cc7_1689260536579.png",
        vipType: "12",
        gender: null,
        keyWord: null,
        type: "sd",
        taskId: "1691118094885355161",
        createTime: "2023-08-04 03:01:34",
        collectTime: null,
        channel: "sd",
        releaseId: "2656",
        collect: false,
        name: "赛博街道",
        price: 5,
        src: "https://cdn-sh1.tcbot.cc/sd/test/1691118094885355161_0.png",
        sdCreateRes: {
          id: null,
          prompt: null,
          negativePrompt: null,
          steps: null,
          width: 768,
          height: 768,
          cfgScale: null,
          batchSize: 2,
          seed: null,
          samplerIndex: null,
          restoreFaces: null,
          overrideSettings: null,
          loras: [],
          userId: "94",
          initImagesUrl: "",
          taskId: "1691118094885355161",
          cosUrls: null,
          cosUrl: null,
          status: "create",
          proportion: "1:1",
          process: 0,
          errorMsg: null,
          createDate: "2023-08-04 03:01:34",
          collects: [],
        },
        isBuy: false,
        favorites: 0,
      },
      {
        id: "480550",
        userId: "35404",
        nickName: "BAHW",
        avatarUrl: "http://tc.cos.cdn.jchdnet.cn/tables/8@3x_1682755663093.png",
        vipType: "0",
        gender: null,
        keyWord:
          "A girl sitting in a classroom, wearing black stockings and barefoot,\n,official art,8k, masterpiece, best quality, highly detailed, exquisite facial features, prefect face, shiny skin,\n1girl, black stockings:1.2, sweet smile:1.2, classroom, books on desk, chalkboard in the background, sunlight streaming through the window:1.2\nand very important,her is naked",
        type: "sd",
        taskId: "1691055970149990913",
        createTime: "2023-08-03 09:46:10",
        collectTime: null,
        channel: "sd",
        releaseId: "2643",
        collect: false,
        name: "naked girl ",
        price: 0,
        src: "https://cdn-sh1.tcbot.cc/sd/test/1691055970149990913_0.png",
        sdCreateRes: {
          id: null,
          prompt:
            "A girl sitting in a classroom, wearing black stockings and barefoot,\n,official art,8k, masterpiece, best quality, highly detailed, exquisite facial features, prefect face, shiny skin,\n1girl, black stockings:1.2, sweet smile:1.2, classroom, books on desk, chalkboard in the background, sunlight streaming through the window:1.2\nand very important,her is naked",
          negativePrompt: "",
          steps: 20,
          width: 512,
          height: 1024,
          cfgScale: 7,
          batchSize: 2,
          seed: "-1",
          samplerIndex: "Euler a",
          restoreFaces: true,
          overrideSettings: {
            id: "231",
            title: "通用真人.safetensors [e4a30e4607]",
            modelName: "通用真人",
            hash: "e4a30e4607",
            sha256:
              "e4a30e4607faeb06b5d590b2ed8e092690c631da0b2becb6224d4bb5327104b7",
            filename:
              "/root/sd-web-ui/models/Stable-diffusion/通用真人.safetensors",
            imgUrl: "https://cdn-sh1.tcbot.cc/sd_model/通用真人.png",
            sd_vae: "Automatic",
            CLIP_stop_at_last_layers: 2,
          },
          loras: [],
          userId: "35404",
          initImagesUrl: "",
          taskId: "1691055970149990913",
          cosUrls: null,
          cosUrl: null,
          status: "create",
          proportion: "1:2",
          process: 0,
          errorMsg: null,
          createDate: "2023-08-03 09:46:10",
          collects: [],
        },
        isBuy: false,
        favorites: 0,
      },
      {
        id: "479405",
        userId: "33246",
        nickName: "FU77",
        avatarUrl:
          "http://tc.cos.cdn.jchdnet.cn/tables/21@3x_1682756130018.png",
        vipType: "0",
        gender: null,
        keyWord: "美女",
        type: "sd",
        taskId: "1691043580673839783",
        createTime: "2023-08-03 06:19:40",
        collectTime: null,
        channel: "sd",
        releaseId: "2633",
        collect: false,
        name: "哈哈",
        price: 0,
        src: "https://cdn-sh1.tcbot.cc/sd/test/1691043580673839783_0.png",
        sdCreateRes: {
          id: null,
          prompt: "美女",
          negativePrompt: "",
          steps: 20,
          width: 1024,
          height: 1024,
          cfgScale: 7,
          batchSize: 2,
          seed: "-1",
          samplerIndex: "Euler a",
          restoreFaces: false,
          overrideSettings: {
            id: "210",
            title: "彩漫.safetensors [54d8a62d61]",
            modelName: "彩漫",
            hash: "54d8a62d61",
            sha256:
              "54d8a62d61210b6a967dfbe2c8ff416e6e51066cfa5b3613db4e6a4f176fde98",
            filename:
              "/root/sd-web-ui/models/Stable-diffusion/彩漫.safetensors",
            imgUrl: "https://cdn-sh1.tcbot.cc/sd_model/彩漫.png",
            sd_vae: "Automatic",
            CLIP_stop_at_last_layers: 2,
          },
          loras: [],
          userId: "33246",
          initImagesUrl: "",
          taskId: "1691043580673839783",
          cosUrls: null,
          cosUrl: null,
          status: "create",
          proportion: "1:1",
          process: 0,
          errorMsg: null,
          createDate: "2023-08-03 06:19:40",
          collects: [],
        },
        isBuy: false,
        favorites: 0,
      },
      {
        id: "479695",
        userId: "35225",
        nickName: "CQFG",
        avatarUrl:
          "http://tc.cos.cdn.jchdnet.cn/tables/16@3x_1682755885107.png",
        vipType: "0",
        gender: null,
        keyWord:
          "A girl sitting in a classroom, wearing black stockings and barefoot,\n,official art,8k, masterpiece, best quality, highly detailed, exquisite facial features, prefect face, shiny skin,\n1girl, black stockings:1.2, sweet smile:1.2, classroom, books on desk, chalkboard in the background, sunlight streaming through the window:1.2",
        type: "sd",
        taskId: "1691047555156834839",
        createTime: "2023-08-03 07:25:55",
        collectTime: null,
        channel: "sd",
        releaseId: "2638",
        collect: false,
        name: "教室",
        price: 0,
        src: "https://cdn-sh1.tcbot.cc/sd/test/1691047555156834839_1.png",
        sdCreateRes: {
          id: null,
          prompt:
            "A girl sitting in a classroom, wearing black stockings and barefoot,\n,official art,8k, masterpiece, best quality, highly detailed, exquisite facial features, prefect face, shiny skin,\n1girl, black stockings:1.2, sweet smile:1.2, classroom, books on desk, chalkboard in the background, sunlight streaming through the window:1.2",
          negativePrompt: "",
          steps: 20,
          width: 512,
          height: 1024,
          cfgScale: 7,
          batchSize: 2,
          seed: "-1",
          samplerIndex: "Euler a",
          restoreFaces: true,
          overrideSettings: {
            id: "231",
            title: "通用真人.safetensors [e4a30e4607]",
            modelName: "通用真人",
            hash: "e4a30e4607",
            sha256:
              "e4a30e4607faeb06b5d590b2ed8e092690c631da0b2becb6224d4bb5327104b7",
            filename:
              "/root/sd-web-ui/models/Stable-diffusion/通用真人.safetensors",
            imgUrl: "https://cdn-sh1.tcbot.cc/sd_model/通用真人.png",
            sd_vae: "Automatic",
            CLIP_stop_at_last_layers: 2,
          },
          loras: [],
          userId: "35225",
          initImagesUrl: "",
          taskId: "1691047555156834839",
          cosUrls: null,
          cosUrl: null,
          status: "create",
          proportion: "1:2",
          process: 0,
          errorMsg: null,
          createDate: "2023-08-03 07:25:55",
          collects: [],
        },
        isBuy: false,
        favorites: 0,
      },
      {
        id: "477671",
        userId: "35282",
        nickName: "XJNW",
        avatarUrl:
          "http://tc.cos.cdn.jchdnet.cn/tables/17@3x_1682755918128.png",
        vipType: "0",
        gender: null,
        keyWord:
          "A futuristic world painting with an aircraft carrier flying in the sky, showcasing a cyberpunk setting. The main characters include a black cat detective wearing a superhero suit and a Gundam riding a motorcycle. The background features Chinese-style architecture, depicting a futuristic and technological vibe. The scene is animated with raindrops falling and explosions happening, all while interacting with extraterrestrial beings. \nmasterpiece, official art, 8k, best quality, highly detailed, exquisite facial features, perfect face, shiny skin, aircraft carrier flying in the sky, cyberpunk setting, black cat detective wearing a superhero suit, Gundam riding a motorcycle, Chinese-style architecture in the background, futuristic and technological vibe, raindrops falling, explosions happening, extraterrestrial beings",
        type: "sd",
        taskId: "1691024268064658629",
        createTime: "2023-08-03 00:57:48",
        collectTime: null,
        channel: "sd",
        releaseId: "2625",
        collect: false,
        name: "末日追击",
        price: 0,
        src: "https://cdn-sh1.tcbot.cc/sd/test/1691024268064658629_0.png",
        sdCreateRes: {
          id: null,
          prompt:
            "A futuristic world painting with an aircraft carrier flying in the sky, showcasing a cyberpunk setting. The main characters include a black cat detective wearing a superhero suit and a Gundam riding a motorcycle. The background features Chinese-style architecture, depicting a futuristic and technological vibe. The scene is animated with raindrops falling and explosions happening, all while interacting with extraterrestrial beings. \nmasterpiece, official art, 8k, best quality, highly detailed, exquisite facial features, perfect face, shiny skin, aircraft carrier flying in the sky, cyberpunk setting, black cat detective wearing a superhero suit, Gundam riding a motorcycle, Chinese-style architecture in the background, futuristic and technological vibe, raindrops falling, explosions happening, extraterrestrial beings",
          negativePrompt: "",
          steps: 20,
          width: 1024,
          height: 1024,
          cfgScale: 7,
          batchSize: 2,
          seed: "-1",
          samplerIndex: "Euler a",
          restoreFaces: false,
          overrideSettings: {
            id: "210",
            title: "彩漫.safetensors [54d8a62d61]",
            modelName: "彩漫",
            hash: "54d8a62d61",
            sha256:
              "54d8a62d61210b6a967dfbe2c8ff416e6e51066cfa5b3613db4e6a4f176fde98",
            filename:
              "/root/sd-web-ui/models/Stable-diffusion/彩漫.safetensors",
            imgUrl: "https://cdn-sh1.tcbot.cc/sd_model/彩漫.png",
            sd_vae: "Automatic",
            CLIP_stop_at_last_layers: 2,
          },
          loras: [],
          userId: "35282",
          initImagesUrl: "",
          taskId: "1691024268064658629",
          cosUrls: null,
          cosUrl: null,
          status: "create",
          proportion: "1:1",
          process: 0,
          errorMsg: null,
          createDate: "2023-08-03 00:57:48",
          collects: [],
        },
        isBuy: false,
        favorites: 0,
      },
      {
        id: "477836",
        userId: "32988",
        nickName: "啥都要起名呀",
        avatarUrl: "http://tc.cos.cdn.jchdnet.cn/tables/3@3x_1682755522946.png",
        vipType: "1",
        gender: null,
        keyWord: null,
        type: "sd",
        taskId: "1691026273295379653",
        createTime: "2023-08-03 01:31:13",
        collectTime: null,
        channel: "sd",
        releaseId: "2629",
        collect: false,
        name: "沙滩美人",
        price: 2,
        src: "https://cdn-sh1.tcbot.cc/sd/test/1691026273295379653_0.png",
        sdCreateRes: {
          id: null,
          prompt: null,
          negativePrompt: null,
          steps: null,
          width: 1024,
          height: 1024,
          cfgScale: null,
          batchSize: 4,
          seed: null,
          samplerIndex: null,
          restoreFaces: null,
          overrideSettings: null,
          loras: [],
          userId: "32988",
          initImagesUrl: "",
          taskId: "1691026273295379653",
          cosUrls: [
            "https://cdn-sh1.tcbot.cc/sd/test/1691026273295379653_0.png",
          ],
          cosUrl: "https://cdn-sh1.tcbot.cc/sd/test/1691026273295379653_0.png",
          status: "create",
          proportion: "1:1",
          process: 0,
          errorMsg: null,
          createDate: "2023-08-03 01:31:13",
          collects: [],
        },
        isBuy: false,
        favorites: 0,
      },
      {
        id: "478588",
        userId: "34950",
        nickName: "眞漫",
        avatarUrl:
          "http://tc.cos.cdn.jchdnet.cn/tables/20@3x_1682756039892.png",
        vipType: "1",
        gender: null,
        keyWord: null,
        type: "sd",
        taskId: "1691034355724232697",
        createTime: "2023-08-03 03:45:55",
        collectTime: null,
        channel: "sd",
        releaseId: "2631",
        collect: false,
        name: "心情舒畅",
        price: 1,
        src: "https://cdn-sh1.tcbot.cc/sd/test/1691034355724232697_1.png",
        sdCreateRes: {
          id: null,
          prompt: null,
          negativePrompt: null,
          steps: null,
          width: 1024,
          height: 575,
          cfgScale: null,
          batchSize: 2,
          seed: null,
          samplerIndex: null,
          restoreFaces: null,
          overrideSettings: null,
          loras: [],
          userId: "34950",
          initImagesUrl: "",
          taskId: "1691034355724232697",
          cosUrls: null,
          cosUrl: null,
          status: "create",
          proportion: "16:9",
          process: 0,
          errorMsg: null,
          createDate: "2023-08-03 03:45:55",
          collects: [],
        },
        isBuy: false,
        favorites: 0,
      },
      {
        id: "476530",
        userId: "751",
        nickName: "你说什么",
        avatarUrl:
          "https://tc.cos.cdn.jchdnet.cn/tables/a74edbf2-f2e9-49b1-809a-a016ba378f1e_1689027718615.gif",
        vipType: "12",
        gender: null,
        keyWord: null,
        type: "sd",
        taskId: "1690981964281362455",
        createTime: "2023-08-02 13:12:44",
        collectTime: null,
        channel: "sd",
        releaseId: "2610",
        collect: false,
        name: "场景3：来人竟是孙悟饭",
        price: 1,
        src: "https://cdn-sh1.tcbot.cc/sd/test/1690981964281362455_3.png",
        sdCreateRes: {
          id: null,
          prompt: null,
          negativePrompt: null,
          steps: null,
          width: 771,
          height: 1024,
          cfgScale: null,
          batchSize: 4,
          seed: null,
          samplerIndex: null,
          restoreFaces: null,
          overrideSettings: null,
          loras: [],
          userId: "751",
          initImagesUrl: "",
          taskId: "1690981964281362455",
          cosUrls: null,
          cosUrl: null,
          status: "create",
          proportion: "3:4",
          process: 0,
          errorMsg: null,
          createDate: "2023-08-02 13:12:44",
          collects: [],
        },
        isBuy: false,
        favorites: 0,
      },
      {
        id: "476374",
        userId: "751",
        nickName: "你说什么",
        avatarUrl:
          "https://tc.cos.cdn.jchdnet.cn/tables/a74edbf2-f2e9-49b1-809a-a016ba378f1e_1689027718615.gif",
        vipType: "12",
        gender: null,
        keyWord: null,
        type: "sd",
        taskId: "1690980225772691397",
        createTime: "2023-08-02 12:43:45",
        collectTime: null,
        channel: "sd",
        releaseId: "2608",
        collect: false,
        name: "场景2：悟空看着眼前的敌人，很是吃惊",
        price: 1,
        src: "https://cdn-sh1.tcbot.cc/sd/test/1690980225772691397_0.png",
        sdCreateRes: {
          id: null,
          prompt: null,
          negativePrompt: null,
          steps: null,
          width: 771,
          height: 1024,
          cfgScale: null,
          batchSize: 4,
          seed: null,
          samplerIndex: null,
          restoreFaces: null,
          overrideSettings: null,
          loras: [],
          userId: "751",
          initImagesUrl: "",
          taskId: "1690980225772691397",
          cosUrls: null,
          cosUrl: null,
          status: "create",
          proportion: "3:4",
          process: 0,
          errorMsg: null,
          createDate: "2023-08-02 12:43:45",
          collects: [],
        },
        isBuy: false,
        favorites: 0,
      },
      {
        id: "476398",
        userId: "35269",
        nickName: "82VF",
        avatarUrl:
          "http://tc.cos.cdn.jchdnet.cn/tables/24@3x_1682756184038.png",
        vipType: "0",
        gender: null,
        keyWord:
          "best quality, master piece\n girl,  upper_body, cowboy_shot, \nblack_serafuku, bokeh, bow, bowtie, branch, cosplay_photo, depth_of_field, lips, long_hair, looking_at_viewer, motion_blur, outdoors, photo_\\(medium\\), photo_background, realistic, red_bow, red_bowtie, sailor_collar, school_uniform, serafuku, smile, snow, solo, teeth, tree, upper_body",
        type: "sd",
        taskId: "1690980472913401405",
        createTime: "2023-08-02 12:47:52",
        collectTime: null,
        channel: "sd",
        releaseId: "2609",
        collect: false,
        name: "啊实打实打算",
        price: 0,
        src: "https://cdn-sh1.tcbot.cc/sd/test/1690980472913401405_0.png",
        sdCreateRes: {
          id: null,
          prompt:
            "best quality, master piece\n girl,  upper_body, cowboy_shot, \nblack_serafuku, bokeh, bow, bowtie, branch, cosplay_photo, depth_of_field, lips, long_hair, looking_at_viewer, motion_blur, outdoors, photo_\\(medium\\), photo_background, realistic, red_bow, red_bowtie, sailor_collar, school_uniform, serafuku, smile, snow, solo, teeth, tree, upper_body",
          negativePrompt:
            "(worst quality, low quality:1.4), moles, bad anatomy, bad hands, cropped, missing fingers, missing toes, too many toes, too many fingers, missing arms, long neck, Humpbacked, deformed, disfigured, poorly drawn face, distorted face, mutation, mutated, extra limb, ugly, poorly drawn hands, missing limb, floating limbs, disconnected limbs, malformed hands, out of focus, long body, monochrome, symbol, text, logo, door frame, window frame, mirror frame",
          steps: 36,
          width: 771,
          height: 1024,
          cfgScale: 7,
          batchSize: 1,
          seed: "-1",
          samplerIndex: "DPM++ 2M Karras",
          restoreFaces: true,
          overrideSettings: {
            id: "253",
            title: "洛菲女孩摄影.safetensors",
            modelName: "洛菲女孩摄影",
            hash: "",
            sha256: "",
            filename:
              "/root/sd-web-ui/models/Stable-diffusion/洛菲女孩摄影.safetensors",
            imgUrl: "https://cdn-sh1.tcbot.cc/sd_model/洛菲女孩摄影.png",
            sd_vae: "ClearVAE.safetensors",
            CLIP_stop_at_last_layers: 2,
          },
          loras: [],
          userId: "35269",
          initImagesUrl: "",
          taskId: "1690980472913401405",
          cosUrls: null,
          cosUrl: null,
          status: "create",
          proportion: "3:4",
          process: 0,
          errorMsg: null,
          createDate: "2023-08-02 12:47:52",
          collects: [],
        },
        isBuy: false,
        favorites: 0,
      },
      {
        id: "476213",
        userId: "751",
        nickName: "你说什么",
        avatarUrl:
          "https://tc.cos.cdn.jchdnet.cn/tables/a74edbf2-f2e9-49b1-809a-a016ba378f1e_1689027718615.gif",
        vipType: "12",
        gender: null,
        keyWord: null,
        type: "sd",
        taskId: "1690978923951079339",
        createTime: "2023-08-02 12:22:03",
        collectTime: null,
        channel: "sd",
        releaseId: "2607",
        collect: false,
        name: "场景1：孙悟空看着远方，准备干一件大事",
        price: 2,
        src: "https://cdn-sh1.tcbot.cc/sd/test/1690978923951079339_1.png",
        sdCreateRes: {
          id: null,
          prompt: null,
          negativePrompt: null,
          steps: null,
          width: 771,
          height: 1024,
          cfgScale: null,
          batchSize: 2,
          seed: null,
          samplerIndex: null,
          restoreFaces: null,
          overrideSettings: null,
          loras: [],
          userId: "751",
          initImagesUrl: "",
          taskId: "1690978923951079339",
          cosUrls: null,
          cosUrl: null,
          status: "create",
          proportion: "3:4",
          process: 0,
          errorMsg: null,
          createDate: "2023-08-02 12:22:03",
          collects: [],
        },
        isBuy: false,
        favorites: 0,
      },
      {
        id: "475546",
        userId: "751",
        nickName: "你说什么",
        avatarUrl:
          "https://tc.cos.cdn.jchdnet.cn/tables/a74edbf2-f2e9-49b1-809a-a016ba378f1e_1689027718615.gif",
        vipType: "12",
        gender: null,
        keyWord: null,
        type: "sd",
        taskId: "1690971301384610169",
        createTime: "2023-08-02 10:15:01",
        collectTime: null,
        channel: "sd",
        releaseId: "2601",
        collect: false,
        name: "场景5：上课了",
        price: 1,
        src: "https://cdn-sh1.tcbot.cc/sd/test/1690971301384610169_0.png",
        sdCreateRes: {
          id: null,
          prompt: null,
          negativePrompt: null,
          steps: null,
          width: 1024,
          height: 575,
          cfgScale: null,
          batchSize: 2,
          seed: null,
          samplerIndex: null,
          restoreFaces: null,
          overrideSettings: null,
          loras: [],
          userId: "751",
          initImagesUrl: "",
          taskId: "1690971301384610169",
          cosUrls: null,
          cosUrl: null,
          status: "create",
          proportion: "16:9",
          process: 0,
          errorMsg: null,
          createDate: "2023-08-02 10:15:01",
          collects: [],
        },
        isBuy: false,
        favorites: 0,
      },
      {
        id: "475527",
        userId: "751",
        nickName: "你说什么",
        avatarUrl:
          "https://tc.cos.cdn.jchdnet.cn/tables/a74edbf2-f2e9-49b1-809a-a016ba378f1e_1689027718615.gif",
        vipType: "12",
        gender: null,
        keyWord:
          "((best quality)), ((masterpiece)), (detailed), (realistic),\nA girl with a backpack is walking along the street, surrounded by pedestrians and cars: (8k resolution) \n(Girl, Walking: high-resolution) \n(Urban street scene: detailed) \n(Interacting with the environment: realistic) \n(Masterpieces: absurdres)",
        type: "sd",
        taskId: "1690971121250651167",
        createTime: "2023-08-02 10:12:01",
        collectTime: null,
        channel: "sd",
        releaseId: "2600",
        collect: false,
        name: " 场景4：出发，上学",
        price: 0,
        src: "https://cdn-sh1.tcbot.cc/sd/test/1690971121250651167_0.png",
        sdCreateRes: {
          id: null,
          prompt:
            "((best quality)), ((masterpiece)), (detailed), (realistic),\nA girl with a backpack is walking along the street, surrounded by pedestrians and cars: (8k resolution) \n(Girl, Walking: high-resolution) \n(Urban street scene: detailed) \n(Interacting with the environment: realistic) \n(Masterpieces: absurdres)",
          negativePrompt:
            "(ugly face:0.8),cross-eyed,sketches, (worst quality:2), (low quality:2), (normal quality:2), lowres, normal quality, ((monochrome)), ((grayscale)), skin spots, acnes, skin blemishes, bad anatomy, DeepNegative, facing away, tilted head, {Multiple people}, lowres, bad anatomy, bad hands, text, error, missing fingers, extra digit, fewer digits, cropped, worstquality, low quality, normal quality, jpegartifacts, signature, watermark, username, blurry, bad feet, cropped, poorly drawn hands, poorly drawn face, mutation, deformed, worst quality, low quality, normal quality, jpeg artifacts, signature, watermark, extra fingers, fewer digits, extra limbs, extra arms,extra legs, malformed limbs, fused fingers, too many fingers, long neck, cross-eyed,mutated hands, polar lowres, bad body, bad proportions, gross proportions, text, error, missing fingers, missing arms, missing legs, extra digit, extra arms, extra leg, extra foot, ((repeating hair)) ,（2girl:2）",
          steps: 20,
          width: 1024,
          height: 575,
          cfgScale: 7,
          batchSize: 2,
          seed: "346590",
          samplerIndex: "Euler a",
          restoreFaces: true,
          overrideSettings: {
            id: "232",
            title: "2.25D二次元.safetensors [cca17b08da]",
            modelName: "2.25D二次元",
            hash: "cca17b08da",
            sha256:
              "cca17b08da3254aa2f0242a84ab8641c89d1bc71cc1046fc79f8e906581a97ba",
            filename:
              "/root/sd-web-ui/models/Stable-diffusion/2.25D二次元.safetensors",
            imgUrl: "https://cdn-sh1.tcbot.cc/sd_model/2.25D二次元.png",
            sd_vae: "Automatic",
            CLIP_stop_at_last_layers: 2,
          },
          loras: [],
          userId: "751",
          initImagesUrl: "",
          taskId: "1690971121250651167",
          cosUrls: null,
          cosUrl: null,
          status: "create",
          proportion: "16:9",
          process: 0,
          errorMsg: null,
          createDate: "2023-08-02 10:12:01",
          collects: [],
        },
        isBuy: false,
        favorites: 0,
      },
    ].map((item) => {
      return {
        ...item,
        height: item.sdCreateRes.height,
        width: item.sdCreateRes.width,
        id: item.id + Math.random(),
      };
    });
    const responseDataSource = Array.isArray(responseData) ? responseData : [];
    setDataSource((prevDataSource) => [
      ...prevDataSource,
      ...responseDataSource,
    ]);
  }, []);

  const onLoadMore = useCallback(() => {
    getDatasource();
  }, [getDatasource]);

  return (
    <div
      style={{
        height: isMobileScreen ? "calc(100vh - 100px)" : "calc(100vh - 66px)",
      }}
    >
      <Waterfall
        className="mx-auto"
        dataSource={dataSource}
        extraSizeGetter={extraSizeGetter}
        onLoadMore={onLoadMore}
        columns={isMobileScreen ? 2 : 5}
        onImgClick={({ src }) => {
          console.log(src);
          setPrevImg(src);
          setVisible(true);
        }}
      >
        {({ data }) => {
          const { name, avatarUrl, nickName } = data;
          if (!name) {
            return null;
          }
          return (
            <>
              <p style={extraStyle} className="text-black">
                {name}
              </p>
              <p className="px-2 pb-2 text-gray-500">
                <img
                  className="h-7 mr-2 rounded-[28px]"
                  src={avatarUrl}
                  alt=""
                />
                {nickName}
              </p>
              <div className="star absolute top-0 left-0 hidden">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 512 512"
                  fill="#f75050"
                >
                  <path d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z" />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 512 512"
                  fill="#000000"
                >
                  <path d="M225.8 468.2l-2.5-2.3L48.1 303.2C17.4 274.7 0 234.7 0 192.8v-3.3c0-70.4 50-130.8 119.2-144C158.6 37.9 198.9 47 231 69.6c9 6.4 17.4 13.8 25 22.3c4.2-4.8 8.7-9.2 13.5-13.3c3.7-3.2 7.5-6.2 11.5-9c0 0 0 0 0 0C313.1 47 353.4 37.9 392.8 45.4C462 58.6 512 119.1 512 189.5v3.3c0 41.9-17.4 81.9-48.1 110.4L288.7 465.9l-2.5 2.3c-8.2 7.6-19 11.9-30.2 11.9s-22-4.2-30.2-11.9zM239.1 145c-.4-.3-.7-.7-1-1.1l-17.8-20c0 0-.1-.1-.1-.1c0 0 0 0 0 0c-23.1-25.9-58-37.7-92-31.2C81.6 101.5 48 142.1 48 189.5v3.3c0 28.5 11.9 55.8 32.8 75.2L256 430.7 431.2 268c20.9-19.4 32.8-46.7 32.8-75.2v-3.3c0-47.3-33.6-88-80.1-96.9c-34-6.5-69 5.4-92 31.2c0 0 0 0-.1 .1s0 0-.1 .1l-17.8 20c-.3 .4-.7 .7-1 1.1c-4.5 4.5-10.6 7-16.9 7s-12.4-2.5-16.9-7z" />
                </svg>
              </div>
            </>
          );
        }}
      </Waterfall>
      <Image.Preview
        src={prevImg}
        visible={visible}
        onVisibleChange={setVisible}
      />
    </div>
  );
};
export default ImgList;
// ReactDOM.render(<ImgList />, document.querySelector("#root"));
