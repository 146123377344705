import React,{ useState,useRef } from "react";
import styles from "./index.module.scss";
import { Route, Routes, useNavigate, Navigate } from "react-router-dom";

import { Layout, Button, Drawer } from "@arco-design/web-react";
import { IconSettings, } from "@arco-design/web-react/icon";
import { useSelector, useDispatch } from "react-redux";
import { clearToken } from "@/store/appSlice";
import {useMobileScreen} from '@/utils/hook'
import EditIcon from '../../icons/chat/edit.svg'
import DelIcon from '../../icons/chat/del.svg'
import ChatitemIcon from '../../icons/chat/chatItem.svg'
// 列表项组件
function ChatListItem(props) {
  const isMobileScreen = useMobileScreen();

  return (
    <div className={styles["amz-chat-listbox"]}>
      {props.list.map((item, key) => {
        return (
          <div
            key={key}
            onClick = {()=>{props.selectChat(item)}}
            className={`${styles["amz-chat-listitem"]} ${isMobileScreen?styles["isMoble"]:''}  ${item.uuid==props.uuid?styles["amz-chat-listactive"]:''}`}
          >
            <img src = {ChatitemIcon} style={{marginRight:'9px'}} />
            <div className={styles["amz-chat-listitemtitle"]}>
              {item.title}
            </div>
            <img className={styles['hovericon']} onClick={(e)=>{
              e.stopPropagation();
              e.preventDefault()
              props.editChat(item);
              }} src = {EditIcon} />
            <img className={styles['hovericon']} src = {DelIcon} onClick={(e)=>{
              e.stopPropagation();
              props.delChat(item);
              }} />
          </div>
        );
      })}
    </div>
  );
}
// 列表组件
function ChatList({ list, uuid ,addNewChat,editChat,selectChat,delChat}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  /**
   * 退出登录
   */
  function loginOut() {
    dispatch(clearToken());
    navigate("/login");
  }
  
  return (
    <>
      <div className={styles["amz-chat-list"]}>
      <Button type='primary' style = {{borderRadius:'8px'}} onClick={addNewChat}>新建会话</Button>

        <ChatListItem name='talk' selectChat = {selectChat} list={list} delChat = {delChat} editChat = {editChat} uuid = {uuid} />
        {/* <div className={styles["amz-chat-listbottom"]}>
        <Button shape='circle' type='primary' onClick={() => {
              setVisible(true);
            }} icon={<IconSettings
            style={{cursor:'pointer'}}
            
          />} />
          
        </div> */}
      </div>
      <Drawer
        width={332}
        height={332}
        title={<span>设置</span>}
        visible={visible}
        placement="left"
        onOk={() => {
          setVisible(false);
        }}
        onCancel={() => {
          setVisible(false);
        }}
      >
        <div>Here is an example text. </div>
        <div>Here is an example text.</div>
      </Drawer>
    </>
  );
}

export default ChatList;
