import {
  Route,
  Link,
  Routes,
  useNavigate,
} from "react-router-dom";
import styles from "./index.module.scss";
import Response from "../../icons/common/response.svg";
import VipIcon from "@/icons/common/viptab.svg";
import VipIcona from "@/icons/common/viptab_a.svg";
import LogoIcon from "@/icons/logo_w.svg";
import { ReactComponent as IconLogo } from "@/icons/logo_w.svg";
import { useLocation } from "react-router";
import { Dropdown, Menu, Avatar, Button, Popover } from "@arco-design/web-react";
import { IconMenu, IconQrcode, IconDown } from "@arco-design/web-react/icon";
import { clearToken } from "@/store/appSlice";
import { useSelector, useDispatch } from "react-redux";
import { useMobileScreen } from "@/utils/hook";
// import IconHead from "@/icons/common/head.svg";
import IconUser from "@/icons/common/user.jpg"
import UserCode from '../../assets/image/userCode.png'
import GroupCode from '../../assets/image/groupCode.png'
import {
  setChatListStatus
} from "../../store/chatSlice";
function HeadInfo() {
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.app.aiToken);
  const isMobileScreen = useMobileScreen();
  const chatListShow = useSelector((state) => state.chat.chatListShow)
  function toLink(path) {
    window.location.href = 'https://ai.amztab.com' + path
    // navigate(path);
  }
  function toNewPage(path) {
    // window.open(location.)
  }
  function toggleSlider() {
    dispatch(setChatListStatus({ status: !chatListShow }))
  }
  /**
   * 退出登录
   */
  function loginOut() {
    dispatch(clearToken());
    // navigate("/login");
    window.location.href = 'https://ai.amztab.com/auth'

  }
  return (
    <div className={styles["amz-head"]}>
      <IconLogo onClick={() => {
        toLink("/");
      }}
        className={`${styles['amz-head-logo']} `} />

      {isMobileScreen ? (
        <IconMenu style={{ fontSize: 30 }} onClick={toggleSlider} />
      ) : (
        <>
          <div className={styles["amz-head-tab"]}>
            <span
              onClick={() => {
                toLink("/chat");
              }}
              className={`${styles["amz-head-appcenter"]} ${pathname == '/chat' ? 'bg-gradient-to-r from-sky-400 to-blue-500 text-white' : ''} px-3 py-1 rounded`}
            >
              会话中心
            </span>
            {/* <span
              onClick={() => {
                toLink("/aiimg");
              }}
              className={styles["amz-head-appcenter"]}
            >
              AI绘画
            </span> */}
            <span
              onClick={() => {
                toLink("/app");
              }}
              className={`${styles["amz-head-appcenter"]} ${pathname == '/app' ? 'bg-gradient-to-r from-sky-400 to-blue-500 text-white' : ''} px-3 py-1 rounded`}

            >
              应用中心
            </span>
            {/* <a href="#/vip" target="_blank">
              <img
                className={styles["amz-head-vipicon"]}
                src={location.pathname == "/vip" ? VipIcona : VipIcon}
              />
            </a> */}
          </div>
          <div className={styles["amz-head-user"]}>
            <div className={styles["amz-head-help"]}>
              <Popover
                style={{ width: 420, display: 'inline-block', maxWidth: 600 }}
                position='br'
                content={
                  <div className="flex " >
                    <div className="flex-1">
                      <div className="text-xl text-center font-bold pt-3">微信扫码入群</div>
                      <div className="text-sm text-center mt-1 text-gray-400">AIGC跨境卖家讨论群</div>
                      <div class='text-center w-11/12 mx-auto rounded-sm p-1 mt-2 border border-gray-300'>
                        <img className="" src={GroupCode} />
                      </div>
                      <div className="text-sm w-11/12 mx-auto text-xs mt-2 mb-4 text-gray-400">亚马逊，独立站，外贸B2B卖家AI工具及运营技巧交流</div>
                    </div>
                    <div className="flex-1">
                      <div className="text-xl text-center font-bold pt-3">产品经理企微</div>
                      <div className="text-sm text-center mt-1 text-gray-400">@产品经理</div>
                      <div className="text-center w-11/12 mx-auto rounded-sm p-1 mt-2 border border-gray-300">
                        <img src={UserCode} />

                      </div>
                      <div className="text-sm w-11/12 mx-auto text-xs mt-2 mb-4 text-gray-400">
                        产品功能吐槽、改进建议等
                        <br />
                        与AMZMOSS一起轻松做好跨境
                      </div>
                    </div>

                  </div>
                }
              >
                <span className="flex items-center text-sm">
                  <IconQrcode style={{ fontSize: 16, marginRight: 10 }} />
                  跨境卖家AI工具群
                  <IconDown style={{ marginLeft: 5 }} />
                </span>
              </Popover>
            </div>


            {!token ? (
              <Button
                type="primary"
                onClick={() => {
                  toLink("/auth");
                }}
                style={{ borderRadius: 5 }}
              >
                登录/注册
              </Button>
            ) : (
              <Dropdown
                position="br"
                droplist={
                  <Menu>
                    <Menu.Item key='1' onClick={() => { window.open('https://support.qq.com/products/600687') }}>问题反馈</Menu.Item>
                    <Menu.Item key="3" onClick={loginOut}>
                      退出登录
                    </Menu.Item>
                  </Menu>
                }
              >
                <img alt="avatar" className="rounded" style={{ width: 28 }} src={IconUser} />
              </Dropdown>
            )}
          </div>
        </>
      )}
    </div>
  );
}
export default HeadInfo;
