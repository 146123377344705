import { configureStore } from '@reduxjs/toolkit';
import appReducer from './appSlice';
import chatReducer from './chatSlice';
import settingReducer from './settingSlice';

export default configureStore({
  reducer: {
    app: appReducer,
    chat:chatReducer,
    settting:settingReducer
  },
});