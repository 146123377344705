import styles from "./app.module.scss";

import React, { createRef, useRef, useState } from "react";

// import {v4 as uuid} from "uuid"
// import {Button,Input} from "antd";
import { Routes, Route, Link ,useNavigate,Navigate} from "react-router-dom";
import Layout from "./components/Layout";
import Login from "./pages/login";
import HomePage from "./pages/home";
import Agreement from './pages/agreement'
import Yinsi from './pages/yinsi'
import { useSelector } from "react-redux";
import { isMobileScreen } from "./utils";
let config = {
    tightBorder:false
}

function App() {
  let [username, setUsername] = useState(0);
  const navigate = useNavigate();
  const containerRef = useRef('')
  function onScroll(e){
    setUsername(containerRef.current.scrollTop)
  }
  // const loginState = ''


  return (
    <>
      <div ref = {containerRef} 
      onScroll={onScroll}
      className={`${
         styles.container
      }`}>
        <Routes>
          <Route path="/" element={<HomePage containerRef = {containerRef} name={username} />} />
          <Route path="/login" element={<Login name={username} />} />
          <Route path="/userAgreement" element={<Agreement name={username} />} />
          <Route path="/yinsi" element={<Yinsi name={username} />} />
          <Route path="/*" element={<Layout />} />
        </Routes>
      </div>
    </>
  );
}

export default App;
