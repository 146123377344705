import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuid } from "uuid";
import { chatStartItem } from "@/utils/config";
let uid = localStorage.localUuid || uuid();

function saveTolocal(activeChat) {
  localStorage.activeChat = JSON.stringify(activeChat);
}
let activeChat = localStorage["activeChat"]?JSON.parse(localStorage["activeChat"]):{}
export const appSlice = createSlice({
  name: "app",
  initialState: {
    chatList: [],
    uid: localStorage.localUuid,
    activeChat: activeChat,
    chatDetail:[],
    chatListShow: false,
  },
  reducers: {
    setChatListStatus: (state, action) => {
      state.chatListShow = action.payload.status;
    },
    //切换会话
    setUuid: (state, action) => {
      state.activeChat = action.payload
      saveTolocal(action.payload);
    },
    //新增会话
    addChatList: (state, action) => {
      state.activeChat = {title:action.payload.title,uuid:action.payload.uuid};
      state.chatDetail =action.payload.list;
      state.chatList = [{title:action.payload.title,uuid:action.payload.uuid}, ...state.chatList];
      saveTolocal({title:action.payload.title,uuid:action.payload.uuid});
    },
    setChatList: (state, action) => {
      state.chatList = action.payload;
    },
    //删除会话
    delChatList: (state, action) => {
      if (action.payload.deleteAll || state.chatList.length == 1) {
        //清除所有
        state.chatList = [];
        state.activeChat = {};
      } else {
        //清楚单个
        state.chatList = state.chatList.filter((item) => item.uuid != action.payload.uuid);
        if (state.activeChat.uuid == action.payload.uuid) {
          state.activeChat = state.chatList[0];
        }
      }
      saveTolocal(state.activeChat);
    },
    // 编辑会话标题
    editChatTitle: (state, action) => {
      state.chatList.forEach((item) => {
        if (item.uuid == action.payload.uuid) {
          item.title = action.payload.title;
        }
      });
      if (state.uid == action.payload.uuid)
        state.activeChat.title = action.payload.title;
      // saveTolocal(state.uid, state.chatList);
    },
    //跟新对话内容
    updataChatItem: (state, action) => {
      if (action.payload.loading) {
        state.chatDetail.push(action.payload);
      } else if (action.payload.adding) {
        state.chatDetail[state.chatDetail.length - 1].content =
          action.payload.content;
      } else {
        if (action.payload.save) {
          state.chatDetail[state.chatDetail.length - 1].content =
            action.payload.content;
        } else {
          state.chatDetail.push(action.payload);
        }
        // saveTolocal(state.uid, state.chatList);
      }
    },
    resetChatItem: (state, action) => {
      state.activeChat = action.payload;
    },
    initChatDetail:(state,action) => {
      state.chatDetail = action.payload
    }
  },
});

// 为每个 case reducer 函数生成 Action creators
export const {
  setUuid,
  addChatList,
  delChatList,
  updataChatItem,
  editChatTitle,
  setChatListStatus,
  setChatList,
  resetChatItem,
  initChatDetail
} = appSlice.actions;

export default appSlice.reducer;
