import styles from "./index.module.scss";
import RightIcon from "../../icons/vip/right.svg";
import AliIcon from "../../icons/vip/ali.svg";
import WeiIcon from "../../icons/vip/wei.svg";
import { useEffect, useState, useRef } from "react";
import { Message, Modal } from "@arco-design/web-react";
import QRCode from "qrcode.react";
import LoadingIcon from "@/icons/common/three-dots.svg";
import { getViplist, getPayQrcode, getPayStatus } from "../../api/auth";
import { useSelector } from "react-redux";
import { tranTime } from "@/utils";
import { useNavigate } from "react-router-dom";
function VipPage() {
  const navigate = useNavigate();
  const token = useSelector((state) => state.app.aiToken);
  const [qrTime, setQrTime] = useState(-1);
  const timeRef = useRef(); //设置延时器
  //请求数据
  useEffect(() => {
    getViplist().then((res) => {
      setListInfo(res.data);
    });
  }, []);

  useEffect(() => {
    //如果设置倒计时且倒计时不为0
    if (qrTime >= 0) {
      timeRef.current = setTimeout(() => {
        setQrTime((qrTime) => qrTime - 1);
        setPayTimerText((text) => tranTime(qrTime));
        checkPayStatus();
        if (qrTime == 0) {
          setIsInvalid(true);
        }
      }, 1000);
    } else {
    }
    //清楚延时器
    return () => {
      clearTimeout(timeRef.current);
    };
  }, [qrTime]);

  const [payInfo, setPayInfo] = useState({});
  const [payDialog, setPaydialog] = useState(false);
  const [listInfo, setListInfo] = useState([]);
  const [payQrcode, setPayQrcode] = useState("");
  const [payId, setPayId] = useState("");
  const [isInvalid, setIsInvalid] = useState(false);
  const [payType, setPayType] = useState(2);
  const [payTimerText, setPayTimerText] = useState("");
  /**
   * 根据订单信息获取支付二维码
   * @param {Object} info 订单信息
   * @returns
   */
  function toPay(info, type) {
    if (!token) {
      navigate("/login");
      return;
    }
    setPayInfo(info);
    setPaydialog(true);
    setIsInvalid(false);
    getPayQrcode({
      pay_type: type || payType,
      version_id: info.id,
    }).then((res) => {
      setPayQrcode(res.data.codeUrl);
      setPayId(res.data.tradeNo);
      setQrTime(600);
    });
  }
  /**
   * 检查订单状态
   */
  function checkPayStatus() {
    getPayStatus({
      trade_no: payId,
    }).then((res) => {
      if (res.data.payStatus == 1) {
        Message.success("支付成功");
        clearTimeout(timeRef.current);
        setPaydialog(false);
        //后续请求用户数据或刷新页面
      }
    });
  }
  function changePayType(type) {
    if (type == payType) return;
    setPayType(type);
    toPay(payInfo, type);
  }
  function toChat() {
    navigate("/chat");
  }

  return (
    <>
      {!listInfo.length ? (
        <img
          src={LoadingIcon}
          style={{
            marginTop: 100,
            position: "relative",
            left: "calc(50% - 7px)",
          }}
        />
      ) : (
        <div className={styles["amz-vip"]}>
          <div className={styles["amz-vip-title"]}>
            <div className={styles["amz-vip-titleBig"]}>
              购买超级会员 · 享专属特权
            </div>
            <div className={styles["amz-vip-titleSub"]}>
              可以根据您个人需求以及团队规模，选择相对应版本
            </div>
          </div>
          <div className={styles["amz-vip-content"]}>
            <div className={`${styles["amz-vip-item"]}`}>
              <div className={styles["amz-vip-itemTop"]}></div>

              <div className={styles["amz-vip-itemTitle"]}>
                {listInfo[0]?.title}
              </div>
              <div className={styles["amz-vip-itemSub"]}>
                好不好用，先试试看
              </div>
              <div className={`${styles["amz-vip-price"]} ${styles["one"]}`}>
                免费
              </div>
              <div className={styles["amz-vip-start"]} onClick={toChat}>
                开始使用
              </div>
              <div className={styles["amz-vip-desc"]}>
                <div className={styles["amz-vip-descTitle"]}>AI 内容生产</div>
                <div className={styles["amz-vip-descDefault"]}>
                  每月{listInfo[0]?.textTimes}次自由问答/文字
                </div>
                <div className={styles["amz-vip-descDefault"]}>
                  每月可生成图片{listInfo[0]?.imageTimes}张
                </div>
                <div className={styles["amz-vip-descDefault"]}>
                  {listInfo[0]?.applicationNumbers}个自定义应用
                </div>
              </div>
              <div className={styles["amz-vip-desc"]}>
                <div className={styles["amz-vip-descTitle"]}>团队专属协同</div>
                <div className={styles["amz-vip-descDefault"]}>账号数 1 个</div>
                <div className={styles["amz-vip-descDefault"]}>
                  创作中心记录保存 7 天
                </div>
                <div className={styles["amz-vip-descDefault"]}>
                  创作中心记录保存 7 天
                </div>
                <div className={styles["amz-vip-descDefault"]}>
                  20MB 存储空间
                </div>
              </div>
              <div className={styles["amz-vip-desc"]}>
                <div className={styles["amz-vip-descTitle"]}>
                  账号绑定 1 个（同步内容）
                </div>
                <div className={styles["amz-vip-descDefault"]}>
                  亚马逊/Shopify/Tiktok 等
                </div>
                <div className={styles["amz-vip-descDefault"]}>
                  创作中心记录保存 7 天
                </div>
                <div className={styles["amz-vip-descDefault"]}>
                  创作中心记录保存 7 天
                </div>
                <div className={styles["amz-vip-descDefault"]}>
                  20MB 存储空间
                </div>
              </div>
            </div>
            <div className={`${styles["amz-vip-item"]}`}>
              <div
                className={styles["amz-vip-itemTop"]}
                style={{ backgroundColor: "#3C6EF6" }}
              ></div>

              <div className={styles["amz-vip-itemTitle"]}>个人版</div>
              <div className={styles["amz-vip-itemSub"]}>
                提升跨境出海内容品质
              </div>
              <div className={`${styles["amz-vip-price"]} ${styles["two"]}`}>
                <span className={styles["priceicon"]}>¥</span>
                <span className={styles["pricenum"]}>
                  {listInfo[1]?.money / 100}
                </span>
                /{listInfo[1]?.timeType == 1 ? "月" : "年"}
              </div>
              <div
                className={`${styles["amz-vip-start"]} ${styles["two"]}`}
                onClick={() => {
                  toPay(listInfo[1]);
                }}
              >
                立即升级
              </div>
              <div className={styles["amz-vip-desc"]}>
                <div className={styles["amz-vip-descTitle"]}>AI 内容生产</div>
                <div className={styles["amz-vip-descTitle"]}>
                  每月{listInfo[1]?.textTimes}次自由问答/文字
                </div>
                <div className={styles["amz-vip-descDefault"]}>
                  每月可生成图片{listInfo[1]?.imageTimes}张
                </div>
                <div className={styles["amz-vip-descDefault"]}>
                  {listInfo[1]?.applicationNumbers}个自定义应用
                </div>
                {listInfo[1]?.supportInternetQuery ? (
                  <div className={styles["amz-vip-descAdd"]}>
                    <img src={RightIcon} />
                    Google联网查询
                  </div>
                ) : null}
              </div>
              <div className={styles["amz-vip-desc"]}>
                <div className={styles["amz-vip-descTitle"]}>团队专属协同</div>
                <div className={styles["amz-vip-descDefault"]}>账号数 1 个</div>
                <div className={styles["amz-vip-descDefault"]}>
                  创作中心记录保存 7 天
                </div>
                <div className={styles["amz-vip-descDefault"]}>
                  20MB 存储空间
                </div>
                <div className={styles["amz-vip-descAdd"]}>
                  <img src={RightIcon} />
                  个性化域名
                </div>
                <div className={styles["amz-vip-descAdd"]}>
                  <img src={RightIcon} />
                  子帐号增加
                </div>
              </div>
              <div className={styles["amz-vip-desc"]}>
                <div className={styles["amz-vip-descTitle"]}>
                  账号绑定 1 个（同步内容）
                </div>
                <div className={styles["amz-vip-descDefault"]}>
                  亚马逊/Shopify/Tiktok 等
                </div>
                <div className={styles["amz-vip-descDefault"]}>
                  创作中心记录保存 7 天
                </div>
                <div className={styles["amz-vip-descDefault"]}>
                  创作中心记录保存 7 天
                </div>
                <div className={styles["amz-vip-descDefault"]}>
                  20MB 存储空间
                </div>
              </div>
            </div>

            <div
              className={`${styles["amz-vip-item"]}`}
              style={{ marginTop: 0 }}
            >
              <div
                className={styles["amz-vip-itemTop"]}
                style={{ backgroundColor: "#E6AD4A" }}
              >
                更多人的选择
              </div>

              <div className={styles["amz-vip-itemTitle"]}>专业版</div>
              <div className={styles["amz-vip-itemSub"]}>
                海量应用无限创作可能
              </div>
              <div className={`${styles["amz-vip-price"]} ${styles["two"]}`}>
                <span className={styles["priceicon"]}>¥</span>
                <span className={styles["pricenum"]}>
                  {listInfo[2]?.money / 100}
                </span>
                /{listInfo[2]?.timeType == 1 ? "月" : "年"}
              </div>
              <div
                className={`${styles["amz-vip-start"]} ${styles["two"]}`}
                onClick={() => {
                  toPay(listInfo[2]);
                }}
              >
                立即升级
              </div>
              <div className={styles["amz-vip-desc"]}>
                <div className={styles["amz-vip-descTitle"]}>AI 内容生产</div>
                <div className={styles["amz-vip-descTitle"]}>
                  每月{listInfo[2]?.textTimes}次自由问答/文字
                </div>
                <div className={styles["amz-vip-descDefault"]}>
                  每月可生成图片{listInfo[2]?.imageTimes}张
                </div>
                <div className={styles["amz-vip-descDefault"]}>
                  {listInfo[2]?.applicationNumbers}个自定义应用
                </div>
                {listInfo[2]?.supportInternetQuery ? (
                  <div className={styles["amz-vip-descAdd"]}>
                    <img src={RightIcon} />
                    Google联网查询
                  </div>
                ) : null}
              </div>
              <div className={styles["amz-vip-desc"]}>
                <div className={styles["amz-vip-descTitle"]}>团队专属协同</div>
                <div className={styles["amz-vip-descDefault"]}>账号数 1 个</div>
                <div className={styles["amz-vip-descDefault"]}>
                  创作中心记录保存 7 天
                </div>
                <div className={styles["amz-vip-descDefault"]}>
                  20MB 存储空间
                </div>
                <div className={styles["amz-vip-descAdd"]}>
                  <img src={RightIcon} />
                  个性化域名
                </div>
                <div className={styles["amz-vip-descAdd"]}>
                  <img src={RightIcon} />
                  子帐号增加
                </div>
              </div>
              <div className={styles["amz-vip-desc"]}>
                <div className={styles["amz-vip-descTitle"]}>
                  账号绑定 1 个（同步内容）
                </div>
                <div className={styles["amz-vip-descDefault"]}>
                  亚马逊/Shopify/Tiktok 等
                </div>
                <div className={styles["amz-vip-descDefault"]}>
                  创作中心记录保存 7 天
                </div>
                <div className={styles["amz-vip-descDefault"]}>
                  创作中心记录保存 7 天
                </div>
                <div className={styles["amz-vip-descDefault"]}>
                  20MB 存储空间
                </div>
              </div>
            </div>

            <div className={`${styles["amz-vip-item"]}`}>
              <div
                className={styles["amz-vip-itemTop"]}
                style={{ backgroundColor: "#FF6600" }}
              ></div>
              <div className={styles["amz-vip-itemTitle"]}>团队版</div>
              <div className={styles["amz-vip-itemSub"]}>
                让团队协作效率更高
              </div>
              <div className={`${styles["amz-vip-price"]} ${styles["three"]}`}>
                <span className={styles["priceicon"]}>¥</span>
                <span className={styles["pricenum"]}>
                  {listInfo[3]?.money / 100}
                </span>
                /{listInfo[3]?.timeType == 1 ? "月" : "年"}
              </div>
              <div
                className={`${styles["amz-vip-start"]} ${styles["three"]}`}
                onClick={() => {
                  toPay(listInfo[3]);
                }}
              >
                立即升级
              </div>
              <div className={styles["amz-vip-desc"]}>
                <div className={styles["amz-vip-descTitle"]}>AI 内容生产</div>
                <div className={styles["amz-vip-descTitle"]}>
                  每月{listInfo[3]?.textTimes}次自由问答/文字
                </div>
                <div className={styles["amz-vip-descDefault"]}>
                  每月可生成图片{listInfo[3]?.imageTimes}张
                </div>
                <div className={styles["amz-vip-descDefault"]}>
                  {listInfo[3]?.applicationNumbers}个自定义应用
                </div>
                {listInfo[3]?.supportInternetQuery ? (
                  <div className={styles["amz-vip-descAdd"]}>
                    <img src={RightIcon} />
                    Google联网查询
                  </div>
                ) : null}
              </div>
              <div className={styles["amz-vip-desc"]}>
                <div className={styles["amz-vip-descTitle"]}>团队专属协同</div>
                <div className={styles["amz-vip-descDefault"]}>账号数 1 个</div>
                <div className={styles["amz-vip-descDefault"]}>
                  创作中心记录保存 7 天
                </div>
                <div className={styles["amz-vip-descDefault"]}>
                  20MB 存储空间
                </div>
                <div className={styles["amz-vip-descAdd"]}>
                  <img src={RightIcon} />
                  个性化域名
                </div>
                <div className={styles["amz-vip-descAdd"]}>
                  <img src={RightIcon} />
                  子帐号增加
                </div>
              </div>
              <div className={styles["amz-vip-desc"]}>
                <div className={styles["amz-vip-descTitle"]}>
                  账号绑定 1 个（同步内容）
                </div>
                <div className={styles["amz-vip-descDefault"]}>
                  亚马逊/Shopify/Tiktok 等
                </div>
                <div className={styles["amz-vip-descDefault"]}>
                  创作中心记录保存 7 天
                </div>
                <div className={styles["amz-vip-descDefault"]}>
                  创作中心记录保存 7 天
                </div>
                <div className={styles["amz-vip-descDefault"]}>
                  20MB 存储空间
                </div>
              </div>
            </div>
          </div>
          <Modal
            style={{ width: "800px" }}
            wrapClassName="vip_dialog"
            title={
              <div className="amz-vip-dialogTitle">
                <div className="amz-vip-upTitle">升级版本</div>
                <div className="amz-vip-version">当前版本:基础版</div>
              </div>
            }
            visible={payDialog}
            onCancel={() => {
              setPaydialog(false);
            }}
            onOk={() => {
              setPaydialog(false);
            }}
            footer={null}
          >
            <div className="amz-vip-dialogItem">
              <div className="amz-vip-dialogItem-title">订单详情</div>
              <div className="amz-vip-dialogItem-content">
                <div className="item">
                  订单类型：<span>升级版本</span>
                </div>
                <div className="item">
                  订购时长：<span>1{payInfo.timeType == 1 ? "月" : "年"}</span>
                </div>
                <div className="item">
                  订购版本：<span>{payInfo.title}</span>
                </div>
                <div className="item">
                  订购金额：<span>¥{payInfo.money / 100}元</span>
                </div>
              </div>
            </div>
            <div className="amz-vip-dialogItem">
              <div className="amz-vip-dialogItem-title">支付方式</div>
              <div className="payBox">
                <div className="payBoxLeft">
                  {!payQrcode ? null : (
                    <QRCode
                      id="qrCode"
                      value={payQrcode}
                      size={220} // 二维码的大小
                      fgColor="#000000" // 二维码的颜色
                      style={{ margin: "auto" }}
                      // imageSettings={{ // 二维码中间的logo图片
                      //     src: 'logoUrl',
                      //     height: 100,
                      //     width: 100,
                      //     excavate: true, // 中间图片所在的位置是否镂空
                      // }}
                    />
                  )}
                  {!isInvalid ? null : (
                    <div
                      className="codeInvalid"
                      onClick={() => {
                        toPay(payInfo);
                      }}
                    >
                      重新获取
                    </div>
                  )}
                </div>
                <div className="payBoxRight">
                  <div className="payBoxIcons">
                    <img
                      className={`payBoxIcon ${payType == 2 ? "active" : ""}`}
                      onClick={() => {
                        changePayType(2);
                      }}
                      src={AliIcon}
                    />
                    <img
                      className={`payBoxIcon ${payType == 1 ? "active" : ""}`}
                      onClick={() => {
                        changePayType(1);
                      }}
                      src={WeiIcon}
                    />
                  </div>
                  <div className="payBoxText">
                    支持支付宝/微信扫码付款，数字产品无法退款，支付即同意
                    <a className="payBoxLink">《AMZMOSS服务会员条款》</a>
                  </div>
                  <div className="payBoxNumber">
                    应付金额
                    <span className="payBoxPrice">
                      {" "}
                      ¥{payInfo.money / 100}元
                    </span>
                  </div>
                  <div className="payBoxDes">
                    剩余支付时间 <span>{payTimerText}</span>，否则订单将自动失效
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      )}
    </>
  );
}
export default VipPage;
