import CN from "./cn";
import EN from "./en";
// import TW from "./tw";
// import ES from "./es";
// import IT from "./it";

// export type { LocaleType } from "./cn";

export const AllLangs = ["en", "cn", "tw", "es", "it"] ;
// type Lang = (typeof AllLangs)[number];

const LANG_KEY = "lang";

function getItem(key) {
  try {
    return localStorage.getItem(key);
  } catch {
    return null;
  }
}

function setItem(key, value) {
  try {
    localStorage.setItem(key, value);
  } catch {}
}

function getLanguage() {
  try {
    return navigator.language.toLowerCase();
  } catch {
    return "cn";
  }
}

export function getLang(){
  const savedLang = getItem(LANG_KEY);

  if (AllLangs.includes((savedLang ?? ""))) {
    return savedLang;
  }

  const lang = getLanguage();

  if (lang.includes("zh") || lang.includes("cn")) {
    return "cn";
  } else if (lang.includes("tw")) {
    return "tw";
  } else if (lang.includes("es")) {
    return "es";
  } else if (lang.includes("it")) {
    return "it";
  } else {
    return "en";
  }
}

export function changeLang(lang) {
  setItem(LANG_KEY, lang);
  window.location.reload();
}

export default { en: EN, cn: CN,}[getLang()];
