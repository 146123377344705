import styles from "./index.module.scss";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {  getTemptypeList } from "@/utils";
import {
  addTemp,
  delTemp,
  getTempList,
  updateTemp,
  getTempById,
} from "../../api/app";
import AmzAct from "../../icons/app/amz_a.png";
import FaceBook from "../../icons/app/facebook.png";
import TikTok from "../../icons/app/tiktok.png";
import YouTuBe from "../../icons/app/youtube.png";
import { ReactComponent as IconLoading } from "@/icons/common/three-dots.svg";
import { useMobileScreen } from "@/utils/hook";
import {
  useNavigate,
} from "react-router-dom";
import React from "react";
import { useWindowSize } from "@/utils/hook";
import {
  Menu,
  Button,
  Message,
  Tabs,
  Input,
  Empty,
  Dropdown,
  Modal,
  Form,
  Select,
  Switch,
} from "@arco-design/web-react";
import {
  IconHome,
  IconMessage,
  IconFire,
  IconMore,
  IconDelete,
  IconEdit,
  IconPlus,
  IconApps,
} from "@arco-design/web-react/icon";
import { ReactComponent as IconTemp } from "@/icons/app/temp.svg";
import { ReactComponent as IconSeo } from "@/icons/app/seo.svg";
import { ReactComponent as IconLogo } from "@/icons/common/logo.svg";
const TabPane = Tabs.TabPane;
const FormItem = Form.Item;
const Option = Select.Option;
const InputSearch = Input.Search;

function AppCenter() {
  const isMobileScreen = useMobileScreen();
  const token = useSelector((state) => state.app.aiToken);
  const options = [
    {
      title: "亚马逊",
      icon: (
        <img
          src={AmzAct}
          className={styles["self-icon"]}
          style={{ marginRight: 6, width: 14 }}
        />
      ),
      icona: <img src={AmzAct} style={{ marginRight: 6, width: 14 }} />,
      category: "1",
    },
    {
      title: "独立站",
      icon: <IconHome style={{ marginRight: 6 }} />,
      category: "2",
    },
    {
      title: "社交媒体",
      icon: <IconMessage style={{ marginRight: 6 }} />,
      category: "4",
      about: [
        {
          icon: (
            <img
              key="1"
              src={TikTok}
              className={styles["self-icon"]}
              style={{ marginLeft: 6, width: 14 }}
            />
          ),
          icona: (
            <img key="1" src={TikTok} style={{ marginLeft: 6, width: 14 }} />
          ),
        },
        {
          icon: (
            <img
              key="2"
              src={YouTuBe}
              className={styles["self-icon"]}
              style={{ marginLeft: 6, width: 14 }}
            />
          ),
          icona: (
            <img key="2" src={YouTuBe} style={{ marginLeft: 6, width: 14 }} />
          ),
        },
        {
          icon: (
            <img
              key="3"
              src={FaceBook}
              className={styles["self-icon"]}
              style={{ marginLeft: 6, width: 14 }}
            />
          ),
          icona: (
            <img key="3" src={FaceBook} style={{ marginLeft: 6, width: 14 }} />
          ),
        },
      ],
    },
    {
      title: "SEO & SEM",
      icon: <IconSeo style={{ marginRight: 6 ,width:14,height:12,fill: "var(--color-text-2)" }} />,
      icona: <IconSeo style={{ marginRight: 6 ,width:14,height:12,fill: "rgb(var(--primary-6))" }} />,
      category: "8",
    },
    {
      title: "其他",
      icon: <IconApps style={{ marginRight: 6 }} />,
      category: "16",
    },
  ];
  const tabList = [
    {
      title: "热门",
      icon: <IconFire style={{ marginRight: 6 }} />,
      category: "-1",
    },
    ...options,
    {
      title: "自定义应用",
      icon: (
        <IconTemp
          className="arco-icon"
          style={{ marginRight: 6, fill: "var(--color-text-2)" }}
        />
      ),
      icona: (
        <IconTemp
          className="arco-icon"
          style={{ marginRight: 6, fill: "rgb(var(--primary-6))" }}
        />
      ),
      category: "-2",
    },
  ];
  const iconStyle = {
    marginRight: "5px",
  };

  const formItemLayout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 20,
    },
  };
  const { width } = useWindowSize();
  useEffect(() => {
    setColNum(width>600?Math.floor(width / 300):2);
  }, [width]);
  
  const [colNum, setColNum] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [category, setCategory] = useState("-1");
  const navigate = useNavigate();
  const [tempList, setTempList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const params = Form.useWatch("paramsList", form);
  const [editId, setEditId] = useState("");
  const [paramsName, setParamsName] = useState(['params1','params2']);
  const [initialValues, setInitialValues] = useState({
    title: "",
    category: ["1"],
    content: "",
    description: "",
    params: [],
  });
  useEffect(() => {
      getList();
  }, [category, searchText]);
  function getList() {
    let hot = Number(category === "-1");
    let self = Number(category === "-2");
    getTempList({
      category: Number(category) < 0 ? 0 : Number(category),
      keywords: searchText,
      is_hot: hot,
      is_self: self,
    })
      .then((res) => {
        if (res.status == 0) {
          setTempList(res.data || []);
        }else{
          Message.error(res.msg)
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }
  // tab栏修改
  function tabChange(e) {
    if(!token){
      navigate("/login");
      return
    }
    setLoading(true);
    setTempList([]);
    setCategory(e);
    console.log("🚀 ~ file: index.jsx:68 ~ tabChange ~ e:", e);
  }
  /**
   * 打开创建模板弹框
   */
  function openCreateTemp() {
    form.resetFields();
    setInitialValues((e) => {
      return {
        title: "",
        content: "我如何计算{{params1}}、{{params2}}、广告成本和亚马逊的费用，以便了解我是否有利润，以及利润有多少",
        category: [],
        description: "",
        paramsList: [
          {
            name:'params1',
            param_desc: "",
            required: true,
          },
          {
            name:'params2',
            param_desc: "",
            required: true,
          },
        ],
      };
    });
    setVisible(true);
  }
  /**
   * 提交模板信息
   */
  function onOk() {
    form.validate().then((res) => {
      setConfirmLoading(true);
      let paramData = {
        ...res,
        category: res.category.map((item) => Number(item)),
        paramsList: !params
          ? []
          : params.map((item, index) => {
                return {
                  ...item,
                  param_name: "参数" + (index + 1),
                  required: item.required ? 1 : 0,
                  param_type: "string",
                  param_value: "",
                  default_value: "",
                  OptionalValue: [],
                  Remark: "",
                  Max: 100,
                  Min: 0
                };
              }),
      };
      if (editId) {
        paramData.id = editId;
        updateTemp(paramData).then((res) => {
          if (res.status == 0) {
            Message.success("修改成功 !");
            setVisible(false);
            setConfirmLoading(false);
            getList();
          } else {
            Message.error(res.msg);
          }
        });
      } else {
        addTemp(paramData).then((res) => {
          if (res.status == 0) {
            Message.success("新增成功 !");
            setVisible(false);
            setConfirmLoading(false);
            getList();
          } else {
            Message.error(res.msg);
            setConfirmLoading(false);
          }
        });
      }
    });
  }
  // 删除模板
  function del(id) {
    Modal.confirm({
      title: "确认删除当前模板？",
      style: {
        width: "95%",
        maxWidth: 400,
      },
      okButtonProps: {
        status: "danger",
      },
      onOk: () => {
        delTemp({
          id,
        }).then((res) => {
          getList();
          if (res.status == 0) {
            Message.success("删除成功");
          }
        });
      },
    });
  }
  //编辑模板
  function edit(data) {
    setEditId(data.ID);
    getTempById({ id: data.ID }).then((res) => {
      let info = res.data;
      setParamsName(info.paramsList.map((item) => item.name));

      setInitialValues((e) => {
        return {
          title: info.title,
          content: info.content,
          category: getTemptypeList(data.category),
          description: info.description,
          paramsList: info.paramsList.map((item) => {
            return {
              ...item,
              required: !!item.required,
            };
          }),
        };
      });
      setVisible(true);
    });
  }

  //模板内容修改
  function tempChange(e, add, remove) {
    let list = Array.from(new Set(e.match(/(\{\{([A-Za-z0-9_]+)\}\})/g) || []));
    setParamsName(list.map((item) => item.replace(/[\{|\}]/g, "")));
    if (list) {
      list.forEach((item, index) => {
        if (params && params[index]) {
          form.setFieldValue(
            `paramList[${index}].name`,
            item.replace(/[\{|\}]/g, "")
          );
        } else {
          add({
            name: item.replace(/[\{|\}]/g, ""),
            param_desc: "",
            required: true,
          });
        }
      });
      params?.forEach((item, index) => {
        if (!list[index]) {
          remove(index);
        }
      });
    } else if (params) {
      params.forEach((item, index) => {
        remove(index);
      });
    }
  }
  //跳转内容生成页面
  function toDetail(id) {
    navigate(`/prompt?id=${id}`);
  }
  return (
    <div className={styles["amz-app"]} style={{height:isMobileScreen?"calc(100vh - 100px)":"calc(100vh - 65px)"}}>
      <InputSearch
        allowClear
        size="large"
        value={searchText}
        searchButton="搜索"
        defaultValue="Search content"
        placeholder="输入关键词搜索应用"
        className={styles["amz-app-search"]}
        style={{backgroundColor:'#fff'}}
        onChange={(e) => {
          setSearchText(e);
        }}
      />
      {!!searchText ? null : (
         <div className="w-11/12 max-w-3xl mx-auto overflow-x-auto scrollbarHide py-2" >
         <div className="flex " style = {{width:'768px'}}>
         {tabList.map((item) => {
             return (
               <div
               onClick={()=>{tabChange(item.category)}}
                 className={`${category == item.category?'text-white bg-gradient-to-r from-sky-400 to-blue-500':''} px-2 py-1 rounded mx-1 bg-gray-200 hover:bg-gray-300 w-28 text-center cursor-pointer `}
                 key={item.category}
               >{item.title}</div>
             );
           })}
         </div>
       </div>
      )}
     

      <div style={{ flex: 1, overflowY: "auto" }}>
        <div className={styles["amz-app-tabs"]}>
          {loading ? (
            <IconLoading style = {{margin:'100px auto 0',display:'block'}} />
          ) : !tempList.length && category != -2 ? (
            <Empty style={{ marginTop: "100px" }} />
          ) : (
            <div className={styles["amz-app-tabItems"]}>
              {category != -2 ? null : (
               <div  style={{width:`calc(100% / ${colNum})`,marginBottom:10}}>
                   <div
                  className={styles["amz-app-tabItem"]}
                  style={{ textAlign: "center" }}
                >
                  <Button
                    type="primary"
                    style={{
                      margin: "24px auto",
                      borderRadius: 5,
                      width: "80%",
                    }}
                    onClick={() => {
                      openCreateTemp(false);
                    }}
                  >
                    {<IconPlus />}创建应用
                  </Button>
                  <div className={styles["tabitem-des"]}>
                    创建属于你自己的ai应用
                  </div>
                </div>
               </div>
              )}
              {tempList.map((val) => {
                return (
                  <div style={{width:`calc(100% / ${colNum})`,marginBottom:10}}>
                    <div
                      className={styles["amz-app-tabItem"]}
                      onClick={() => {
                        toDetail(val.ID);
                      }}
                      key={val.ID}
                    >
                      <div className={styles["tabitem-title"]}>{val.title}</div>
                      <div className={styles["tabitem-content"]}>
                        {val.description}
                      </div>
                      <div className={styles["tabitem-from"]}><IconLogo style={{width:12,height:12,marginRight:5}}/> AMZMOSS</div>

                      {val.is_self != 1 ? null : (
                        <Dropdown
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          trigger={["click", "hover"]}
                          droplist={
                            <Menu
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              <Menu.Item
                                key="1"
                                onClick={() => {
                                  edit(val);
                                }}
                              >
                                <IconEdit style={iconStyle} />
                                编辑
                              </Menu.Item>
                              <Menu.Item key="2" onClick={() => del(val.ID)}>
                                <IconDelete style={iconStyle} />
                                删除
                              </Menu.Item>
                            </Menu>
                          }
                        >
                          <div className={styles["tabitem-tool"]}>
                            <IconMore />
                          </div>
                        </Dropdown>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>

      <Modal
        title={
          <div style={{ textAlign: "left" }}>
            {!editId ? "创建" : "编辑"}应用{" "}
            <span style={{ fontSize: 12, color: "#bbb" }}>
              {!editId ? "创建属于" : "编辑"}自己的应用
            </span>
          </div>
        }
        visible={visible}
        style={{ width: "95%", maxWidth: 900 }}
        onOk={onOk}
        confirmLoading={confirmLoading}
        onCancel={() => setVisible(false)}
      >
        <Form
          {...formItemLayout}
          form={form}
          validateTrigger="onBlur"
          initialValues={initialValues}
          labelCol={{
            style: { flexBasis: 130 },
          }}
          layout={isMobileScreen?'vertical':'horizontal'}
          wrapperCol={{
            style: { flexBasis: "calc(100% - 130px)" },
          }}
          validateMessages={{
            required: (_, { label }) => `${label}是必填项`,
          }}
        >
          <FormItem label="场景" field="category" rules={[{ required: true }]}>
            <Select mode="multiple" placeholder="请选择使用场景" allowClear>
              {options.map((option) => (
                <Option key={option.category} value={option.category}>
                  {option.title}
                </Option>
              ))}
            </Select>
          </FormItem>
          <FormItem label="标题" field="title" rules={[{ required: true }]}>
            <Input maxLength={100} showWordLimit placeholder="请输入应用标题" />
          </FormItem>
          <FormItem
            label="应用简介"
            field="description"
          >
            <Input.TextArea
              placeholder="请输入应用简介"
              maxLength={{ length: 500 }}
              showWordLimit
              autoSize={{ minRows: 3, maxRows: 3 }}
              style={{
                // height: 54,
                resize: "none",
                borderRadius: 5,
                // paddingRight: 40,
                // border:'none'
              }}
            />
          </FormItem>

          <Form.List field="paramsList" initialValues={initialValues.paramsList}>
            {(fields, { add, remove, move }) => {
              return (
                <div>
                  <FormItem
                    label="Prompt内容"
                    tooltip={
                      <div>{`模板支持自定义多个变量，变量格式为{{变量名}},变量支持英文字母、数字 `}</div>
                    }
                    required
                    field="content"
                    rules={[{ required: true }]}
                  >
                    <Input.TextArea
                      placeholder="请输入模板内容"
                      maxLength={{ length: 3000 }}
                      showWordLimit
                      autoSize={{ minRows: 3, maxRows: 3 }}
                      onChange={(e) => tempChange(e, add, remove)}
                      style={{
                        // height: 54,
                        resize: "none",
                        borderRadius: 5,
                        // paddingRight: 40,
                        // border:'none'
                      }}
                    />
                  </FormItem>
                  {fields.map((item, index) => {
                    return (
                      <div key={item.name}>
                        <Form.Item
                          label={"参数 " + (index + 1)}
                          layout="inline"
                        >
                          <div style={{ display: "flex" }}>
                            <FormItem
                              style={{ width: "calc(100% - 135px)" }}
                              label={paramsName[index]}
                              field={item.field + ".param_desc"}
                              rules={[{ required: true }]}
                            >
                              <Input  placeholder="请输入该参数的简介"/>
                            </FormItem>
                            <FormItem
                              hidden
                              field={item.field + ".name"}
                            >
                              <Input  />
                            </FormItem>
                            <FormItem
                              style={{ width: "120px", marginLeft: 15 }}
                              layout="inline"
                              triggerPropName="checked"
                              field={item.field + ".required"}
                              initialValue={true}
                              label="是否必填"
                            >
                              <Switch />
                            </FormItem>
                          </div>
                        </Form.Item>
                      </div>
                    );
                  })}
                </div>
              );
            }}
          </Form.List>
        </Form>
      </Modal>
    </div>
  );
}
export default AppCenter;
