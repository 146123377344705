import styles from "./index.module.scss";
import {
  Input,
  Tooltip,
  Layout,
  Button,
  Typography,
} from "@arco-design/web-react";
import { useMobileScreen } from "@/utils/hook";
function ChatDefault({ info={name:'Amz'}, index, }) {
  const isMobileScreen = useMobileScreen();

  const arr = [
    {
      title:'Listing 写作与优化',
      list:['100%关键词埋词率','流畅地道的语言表达','JS评分可达85分以上','更高的点击率与转化率']
    },
    {
      title:'AI作图',
      list:['素材裂变','POD快捷键站','版权无忧',]
    },
    {
      title:'Listing 写作与优化',
      list:['100%关键词埋词率','流畅地道的语言表达','JS评分可达85分以上','更高的点击率与转化率']
    }
  ]
  return (
    <div
    className={`${styles["amz-chat-chatitem"]} ${
      styles[
       "amz-chat-chatitem-ai"
      ]
    } ${isMobileScreen ? "p-2" : "p-4"}`}
    >
      <div className={styles["amz-chat-chatuser"]}>{info.name}</div>
      <div   className={styles["amz-chat-chattext"]}
                    style={{
                      maxWidth: isMobileScreen
                        ? "calc(100% - 110px)"
                        : "calc(100% - 200px)",
                    }}>
        <div  className={`${styles["amz-chat-chattextcon"]} px-4 py-3 ${
                        isMobileScreen ? "" : ""
                      }`}>
            <div className={styles["azm-chat-defaultTitle"]} style={{fontSize:isMobileScreen ? 12 : 14}}>
            你好，我是AMZMOSS，有什么可以帮助你吗？
            </div>
            <div className={styles["azm-chat-defaultDesc"]}>
            </div>
            <div className={styles["azm-chat-defaultList"]}>
              {
                arr.map((item,key) => {
                  <div className={styles["azm-chat-defaultItem"]} >
                  </div>

                })
              }
              

            </div>
        </div>
       
      </div>
      
    </div>
  );
}

export default ChatDefault;
