import { useEffect, useState, useRef } from "react";
import {
  useNavigate,
} from "react-router-dom";
import CustomInput from "./numberbegin";
import {
  Button,
  Form,
  Input,
} from "@arco-design/web-react";
import styles from "./index.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { setPassword, setUsername, setToken } from "../../store/appSlice";
import LogoPwd from "../../icons/login/logo_pwd.svg";
import IconCode from "../../icons/login/icon_code.svg";
import { post } from "@/api/request";
import { useMobileScreen } from "@/utils/hook";
const FormItem = Form.Item;


function Pwd({ changeLoginWay }) {
  const isMobileScreen = useMobileScreen();

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const formRef = useRef();
  const dispatch = useDispatch();
  let [phoneBegin, setPhoneBegin] = useState("+86");
  let [loginPendding, setLoginPendding] = useState(false);
  /**
   * 提交登录信息
   */
  function toLogin(info) {
    setLoginPendding(true);
    post("/api/user/login", {
      username: "liushuzhu",
      password: "930152",
    }).then((res) => {
      setLoginPendding(false);
      dispatch(setToken({ token: res.data.token }));
      navigate("/chat");
    });
  }

  return (
    <div className={styles["amz-login-content-pwd"]}>
      <div className={styles["amz-login-content-ctitle"]}>密码登录</div>
      <Form
        className={styles["amz-login-content-cform"]}
        ref={formRef}
        wrapperCol={{ span: 24 }}
        initialValues={{
          password: "",
          customInput: {
            select: "+86",
            input: "",
          },
        }}
        onSubmit={toLogin}
      >
        <FormItem
        validateTrigger='onBlur'
          rules={[
            { required: true },
            {
              validator: (val, cb) => {
                if(!val.input){
                  return cb("手机号码不能为空！");
                }else if (!val.input.match(/^1[3-9][0-9]{9}$/)) {
                  return cb("手机号码格式有误！");
                }
                cb();
              },
            },
          ]}
          field="customInput"
        >
          <CustomInput />
        </FormItem>
        <FormItem
        validateTrigger='onBlur'
          field="password"
          rules={[
            {
              required: true,
            },
            {
              validator: (val, cb) => {
                if (val.length < 6) {
                  return cb("密码过短！");
                }
                cb();
              },
            },
          ]}
        >
          <Input.Password
            allowClear
            prefix={<img src={LogoPwd} style={{ marginRight: "15px" }} />}
            placeholder="请输入您的密码"
            className={styles.codeinput}
            size="large"
          />
        </FormItem>
        <FormItem>
          <div className={styles["amz-login-content-csign"]}>
            <span>没有账号？</span>
            <a>立即注册</a>
          </div>
        </FormItem>
        <FormItem>
          <Button
            size="large"
            className={styles["amz-login-content-btn"]}
            type="primary"
            htmlType="submit"
            loading={loginPendding}
            onClick={async () => {
              if (formRef.current) {
                try {
                  await formRef.current.validate();
                } catch (_) {
                }
              }
            }}
          >
            Login
          </Button>
        </FormItem>
        <FormItem>
          <div
            onClick={() => {
              changeLoginWay(2);
            }}
            className={styles["amz-login-content-cbottom"]}
          >
            <img src={IconCode} /> 验证码登录
          </div>
        </FormItem>
      </Form>
    </div>
  );
}
export default Pwd;
