import axios from "axios";
import { Message } from "@arco-design/web-react";
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";
// let baseURL = "http://chat.amztab.com:8080/api/v1";
let baseURL = 'https://chatapi.amzmoss.com/api/v1'
const instance = axios.create({
  baseURL: baseURL,
  timeout: 10000,
});

// 添加请求拦截器
instance.interceptors.request.use(
  function (config) {
    //默认请求头设置
    config.headers = {
      ...config.headers,
      Authorization: localStorage.aiToken,
    };
    //判断网络
    if (!window.navigator.onLine) {
      // app.state.netError = true;
      return;
    }
    let cancel;
    // 设置cancelToken对象
    config.cancelToken = new axios.CancelToken(function (c) {
      cancel = c;
    });
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
let codeList = [0, 2004, 3002];
instance.interceptors.response.use(
  function (response) {
    console.log("🚀 ~ file: request.js:42 ~ response:", response);
    // 对响应数据做点什么
    if (response.status === 200) {
      return Promise.resolve(response.data);
    } else {
      Message.error(response.data.msg);
      Promise.reject(response.data);
    }
  },
  function (error) {
    // 对响应错误做点什么
    // console.log('err',error)
    Message.error(error.message);
    return Promise.reject(error);
  }
);

/**
 * get请求
 * @param {*} url 请求地址
 * @param {*} param url参数
 */

export function get(url, params) {
  return instance.get(url, {
    params,
  });
}

/**
 * post请求
 * @param {*} url 请求地址
 * @param {*} data url参数
 */

export function post(url, data) {
  return instance.post(url, data);
}
export function postStream(url, data) {
  let controller = new AbortController();
  return fetch(baseURL + url, {
    method: "post",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      Authorization: localStorage.aiToken,
    },
    body: JSON.stringify(data),
    signal: controller.signal,
  });
}
/**
 * put请求
 * @param {*} url 请求地址
 * @param {*} data url参数
 */

export function put(url, data) {
  return instance.put(url, data);
}

/**
 * del请求
 * @param {*} url 请求地址
 */

export function del(url) {
  return instance.del(url);
}

// const resHandle = (res, resolve) => {
//   //处理特殊情况code
//   resolve(res);
// };

