import { Route, Link, Routes, useLocation, useNavigate } from 'react-router-dom';
import styles from './index.module.scss'
import LogoIcon from '@/icons/logo_w.svg'
import { ReactComponent as IconLogo } from "@/icons/logo_w.svg";
import { clearToken } from "@/store/appSlice";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown, Menu, Avatar, Button, Popover } from "@arco-design/web-react";
import { IconMenu, IconQrcode, IconDown } from "@arco-design/web-react/icon";
import { useMobileScreen } from "@/utils/hook";

import IconHead from "@/icons/common/user.jpg";
import UserCode from '../../assets/image/userCode.png'
import GroupCode from '../../assets/image/groupCode.png'
function HeadHome({ hasScroll }) {
  const token = useSelector((state) => state.app.aiToken);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const isMobileScreen = useMobileScreen();
  function toLink(path) {
    navigate(path)
  }
  function loginOut() {
    dispatch(clearToken());
    // navigate("/login");
  }
  return (
    <div className={`${styles['amz-head']} ${hasScroll ? styles['amz-head-scroll'] : ''}`}>
      <IconLogo onClick={() => {
        toLink("/");
      }}
        className={`${styles['amz-head-logo']} `} />

      <div className={styles['amz-head-user']}>
        {
          isMobileScreen ? null : (<Popover
            style={{ width: 420, display: 'inline-block', maxWidth: 600 }}
            position='br'
            content={
              <div className="flex " >
                <div className="flex-1">
                  <div className="text-xl text-center font-bold pt-3">微信扫码入群</div>
                  <div className="text-sm text-center mt-1 text-gray-400">AIGC跨境卖家讨论群</div>
                  <div class='text-center w-11/12 mx-auto rounded-sm p-1 mt-2 border border-gray-300'>
                    <img className="" src={GroupCode} />
                  </div>
                  <div className="text-sm w-11/12 mx-auto text-xs mt-2 mb-4 text-gray-400">亚马逊，独立站，外贸B2B卖家AI工具及运营技巧交流</div>
                </div>
                <div className="flex-1">
                  <div className="text-xl text-center font-bold pt-3">产品经理企微</div>
                  <div className="text-sm text-center mt-1 text-gray-400">@产品经理</div>
                  <div className="text-center w-11/12 mx-auto rounded-sm p-1 mt-2 border border-gray-300">
                    <img src={UserCode} />

                  </div>
                  <div className="text-sm w-11/12 mx-auto text-xs mt-2 mb-4 text-gray-400">
                    产品功能吐槽、改进建议等
                    <br />
                    与AMZMOSS一起轻松做好跨境
                  </div>
                </div>

              </div>
            }
          >
            <span className="flex items-center text-sm mr-4 cursor-pointer">
              <IconQrcode style={{ fontSize: 16, marginRight: 10 }} />
              跨境卖家AI工具群
              <IconDown style={{ marginLeft: 5 }} />
            </span>
          </Popover>)
        }
        {!token ? <Button type='primary' onClick={() => {
          window.location.href = 'https://ai.amztab.com/auth'
        }} style={{ borderRadius: 5 }}>登录/注册</Button> : (
          <Dropdown
            position='br'
            droplist={
              <Menu>
                <Menu.Item key='1' onClick={() => { window.open('https://support.qq.com/products/600687') }}>问题反馈</Menu.Item>
                <Menu.Item key='3' onClick={loginOut}>退出登录</Menu.Item>
              </Menu>
            }
          >
            <img alt="avatar" className='rounded' style={{ width: 28 }} src={IconHead} />

          </Dropdown>
        )}


      </div>
    </div>
  )
}
export default HeadHome