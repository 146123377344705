import { useState } from "react";
import styles from "./index.module.scss";
import {
  IconSelectAll,
  IconList,
  IconRight,
  IconCheck,
} from "@arco-design/web-react/icon";
import { Button } from "@arco-design/web-react";
function ImgList(props) {
  const [isEdit, setIsEdit] = useState(false);
  const [imgList, setImgList] = useState([1, 2, 3, 4,5,6,7,8,9,10]);
  const [selectList, setSelectList] = useState([]);
  function itemClick(item) {
    if (isEdit) {
      if (selectList.includes(item)) {
        setSelectList(selectList.filter((val) => val != item));
      } else {
        setSelectList([...selectList, item]);
      }
    } else {
        props.changeActive(item)
    }
  }
  function delImg() {
    setImgList(imgList.filter((item) => !selectList.includes(item)));
    setIsEdit(false);
    setSelectList([]);
  }
  return (
    <div className={styles.amz_img_list}>
      <div className={styles.amz_img_listTitle}>
        绘画记录
        {!isEdit ? (
          <IconList
            onClick={() => {
              setSelectList([]);
              setIsEdit(!isEdit);
            }}
            style={{ fontSize: 18 }}
          />
        ) : (
          <IconSelectAll
            onClick={() => {
              setSelectList([]);
              setIsEdit(!isEdit);
            }}
            style={{ fontSize: 18 }}
          />
        )}
      </div>
      <div className={styles.amz_img_listCon}>
        {imgList.map((item, index) => {
          return (
            <div
              key={item}
              className={`${styles.amz_img_listBox} ${props.active==item?styles.active:''}`}
              onClick={() => {
                itemClick(item);
              }}
            >
              <div className={styles.amz_img_listItem}>
                {isEdit ? (
                  <div className={styles.amz_img_listSelect}>
                    <div
                      className={`${styles.unselect} ${
                        selectList.includes(item) ? styles.active : ""
                      }`}
                    >
                      {selectList.includes(item) ? (
                        <IconCheck
                          style={{ color: "#fff", fontWeight: "bold" }}
                        />
                      ) : null}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          );
        })}
      </div>
      <div className={styles.amz_img_listMore}>
        {isEdit ? (
          <Button
            type="primary"
            status="danger"
            size='mini'
            style={{ borderRadius: 5, width: "90%" }}
            onClick={delImg}
          >
            删除
          </Button>
        ) : (
          <div className={styles.amz_img_moreBtn}>
            更多创作记录 <IconRight style={{ fontSize: 16 }} />
          </div>
        )}
      </div>
    </div>
  );
}
export default ImgList;
