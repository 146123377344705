import {get,post,postStream} from './request'

// example 获取数据json模拟
export const getPayQrcode = (data) => post('/pay',data) 

export const getPayStatus = (data) => get('/pay/info',data) 

export const getViplist = () => get('/version/list')

export const getInfos = () => get('/user/info')
export const getPhoneCode = (data) => post('/code',data)
export const smsLogin = (data) => post('/sms/login',data)

//发送对话
export const sendCompletion = (data) => post('/completion',data)
// 流式对话
export const sendCompletionStream = (data) => postStream('/completion/stream',data)
//获取会话列表
export const getChatMenuList = (data) => post('/session/list',data)
//修改会话标题
export const changeChatTitle = (data) => post('/session/rename',data)
// 删除会话列表
export const deleteChatList = (data) => post('/session/del',data)
//获取聊天内容列表
export const getChatDetail = (data) => post('/chatMsg/list',data)