import styles from "./index.module.scss";
function Yinsi() {
  return (
    <div className={styles["amz-agreement"]}>
        <div className={styles['amz-agreement-title']}>
        AMZMOSS隐私政策
        </div>
        <div className={styles['amz-agreement-time']}>
        更新日期：2023年6月 7日
        </div>
        <div className={styles['amz-agreement-content']}>
          序言
        </div>
        <div className={styles['amz-agreement-content']}>
        「AMZMOSS」由杭州徕海科技有限公司（以下简称“徕海公司”或“我们”）开发和运营。我们深知个人信息对您的重要性，您的信息对我们非常重要，我们将按照法律法规的规定并参照行业最佳实践保护您的个人信息及隐私安全。我们制定本政策旨在帮助您了解以下内容：
        </div>
        <div className={styles['amz-agreement-indent']}>
        1、AMZMOSS浏览器收集个人信息明示清单；<br/>
        2、我们如何存储您的个人信息；<br/>
        3、我们如何转移、公开您的个人信息；<br/>
        4、我们如何保护您的个人信息；<br/>
        5、您管理个人信息的权利；<br/>
        6、未成年人个人信息的保护；<br/>
        7、隐私政策的变更和修订；<br/>
        8、如何联系我们。
        </div>
        <div className={styles['amz-agreement-content']}>
        <span className={styles['amz-agreement-underline']}>
        请在使用AMZMOSS软件及相关服务前，务必仔细阅读并了解本隐私政策，在确认充分理解并同意后使用我们的产品或服务。如您自主选择同意或使用AMZMOSS软件及相关服务，即表示您已充分理解并同意本政策，并同意我们按照本隐私政策收集、使用、储存和分享您的相关信息。
        </span> 如对本政策内容有任何疑问、意见或建议，您可通过本隐私政策提供的各种联系方式与我们联系。
        </div>
        <div className={styles['amz-agreement-btitle']}>
        1、AMZMOSS浏览器收集个人信息明示清单
        </div>
        <div className={styles['amz-agreement-content']}>
        我们会通过以下方式收集和使用您在使用AMZMOSS及相关服务时主动提供的，以及通过自动化手段收集您在使用功能或接受服务过程中产生的信息：
        </div>
        <div className={styles['amz-agreement-stitle']}>
        1.1 基本业务功能
        </div>
        <div className={styles['amz-agreement-content']}>
        (1) 当您注册、登录AMZMOSS及相关服务时，AMZMOSS会收集您的手机号码、验证码或其他真实身份信息，收集这些信息是为了帮助您完成AMZMOSS帐号注册并按照相关法律规定完成实名认证，保护您的帐号安全。如果您拒绝提供这些信息，您可能无法成功注册或登录AMZMOSS帐号。
        </div>
        <div className={styles['amz-agreement-content']}>
        (2) 为了保障软件与服务的安全运行、运营的质量及效率，我们会收集您的设备的硬件型号、操作系统版本号、设备标识符、网络设备硬件地址、IP 地址、WLAN接入点、软件版本号、网络接入方式、服务日志。我们可能会将您的设备信息或电话号码与您的AMZMOSS帐号相关联。
        </div>
        <div className={styles['amz-agreement-content']}>
        (3) 在相关法律法规等规定允许的情况下，为了分析应用新增、激活、留存、性能等统计性指标，我们可能会收集您在AMZMOSS上的使用行为，并调用剪切板对相关统计信息进行归因分析。
        </div>
        <div className={styles['amz-agreement-content']}>
        (4) 消息通知
          <div className={styles['amz-agreement-underline']} style={{paddingLeft:30}}>
          您知悉并同意，对于您在使用产品及/或服务的过程中提供的您的联系方式（例如：联系电话），我们在运营中可能会向其中的一种或多种发送多类通知，用于用户消息告知、身份验证、安全验证、用户使用体验调研、争议处理等用途。
          </div>
        </div>
        <div className={styles['amz-agreement-content']}>
        (5) 问题反馈
        </div>
        <div className={styles['amz-agreement-indent']}>
        1.客服及问题：为便于与您联系、尽快帮助您解决问题或记录相关问题的处理方案及结果，我们可能会保存您与我们的<span className={styles['amz-agreement-underline']}>通信/通话记录及相关内容（包括帐号信息、反馈信息、订单信息、您为了证明相关事实提供的其他信息，或您留下的联系方式）</span>，如果您针对具体订单进行咨询、投诉或提供建议，我们会使用您的帐号信息和订单信息。为了方便未登录用户使用客服或反馈功能，我们可能会将上述信息与您的设备信息相关联，并可在登录后查看。<br/>
2.当您投诉他人或被他人投诉时，为了保护您及他人的合法权益, 我们可能会将您的帐号/<span className={styles['amz-agreement-underline']}>身份信息</span>、联系方式、投诉相关内容提供给消费者权益保护部门及监管机关，以便及时解决投诉纠纷，但法律法规明确禁止提供的除外。<br/>
3.问卷调查：为了提供服务及改进服务质量的合理需要，我们还可能使用您的其他信息，包括您与客服联系时您提供的相关信息，您参与问卷调查时向我们发送的问卷答复信息；如您不愿意，您也可以拒绝提供参与问卷调查。
        </div>
        <div className={styles['amz-agreement-stitle']}>
        1.2 Cookie和同类技术的使用
        </div>
        <div className={styles['amz-agreement-content']}>
        在相关法律法规等规定允许的范围内，当您使用AMZMOSS及相关服务时，我们可能会使用相关技术向您的设备发送一个或多个 cookie 或匿名标识符，以收集、标识和存储您访问、使用本产品时的信息。我们承诺，不会将 cookie 用于本隐私政策所述目的之外的任何其他用途。我们使用 cookie 和同类技术主要为了实现以下功能或服务：
        </div>
        <div className={styles['amz-agreement-content']}>
        (1) 保障产品与服务的安全、高效和运转
        </div>
        <div className={styles['amz-agreement-indent']}>
        我们可能会设置认证与保障安全性的 cookie 或匿名标识符，来确认您是否安全登录服务，或者是否遇到盗用、欺诈及其他不法行为。这些技术还会帮助我们改进服务效率，提升登录和响应速度。
        </div>
        <div className={styles['amz-agreement-content']}>
        (2) 帮助您获得更轻松的访问体验
        </div>
        <div className={styles['amz-agreement-indent']}>
        我们可能会利用 COOKIE 和同类技术了解您的使用习惯，以改善产品服务，使用此类技术可以帮助您省去重复填写个人信息、输入搜索内容的步骤和流程（示例：记录搜索、表单填写）。
        </div>
        <div className={styles['amz-agreement-content']}>
        (3)  Cookie的清除
        </div>
        <div className={styles['amz-agreement-indent']}>
        大多数浏览器均为用户提供了清除浏览器缓存数据的功能，您可以在浏览器设置功能中进行相应的数据清除操作。如您进行清除，因为这些修改，您可能无法使用我们提供的且依赖于cookie的服务或相应功能。
        </div>
        <div className={styles['amz-agreement-stitle']}>
        1.3 第三方在AMZMOSS中的使用
        </div>
        <div className={styles['amz-agreement-content']}>
        为保障AMZMOSS的正常运行或实现相关功能，我们可能会通过SDK、API、插件等方式接入第三方提供的服务（详情参见附录一）。接入前，我们会对第三方的服务进行严格的安全测试和隐私评估，并与该第三方约定严格的数据保护措施，令其按照本隐私政策以及其他任何相关的保密和安全措施来处理您的个人信息，以保障您的隐私和用户信息安全。这些第三方服务提供者属于独立的信息控制者，会对其信息收集、处理行为独立承担全部责任。
        </div>
        <div className={styles['amz-agreement-stitle']}>
        1.4 征得授权同意的例外
        </div>
        <div className={styles['amz-agreement-content']}>
        根据相关法律法规规定，以下情形中收集您的个人信息不必事先征得您的授权同意：
        </div>
        <div className={styles['amz-agreement-indent']}>
        (1)与国家安全、国防安全有关的；<br/>
(2)与公共安全、公共卫生、重大公共利益有关的；<br/>
(3)与刑事侦查、起诉、审判和判决执行等有关的；<br/>
(4)出于维护您或他人的生命、财产等重大合法权益但又很难得到您本人授权同意的；<br/>
(5)您自行向社会公众公开的个人信息；<br/>
(6)从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；<br/>
(7)用于维护AMZMOSS软件及相关服务的安全稳定运行所必需的，例如发现、处置软件及相关服务的故障；<br/>
(8)为开展合法的新闻报道所必需的；<br/>
(9)为学术研究机构、基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；<br/>
(10)法律法规规定的其他情形。
        </div>
        <div className={styles['amz-agreement-underline']}>
        特别提示您注意，如信息无法单独或结合其他信息识别到您的个人身份，其不属于法律意义上您的个人信息；当您的信息可以单独或结合其他信息识别到您的个人身份时或我们将无法与任何特定个人信息建立联系的数据与其他您的个人信息结合使用时，这些信息在结合使用期间，将作为您的个人信息依法进行处理与保护。
        </div>
        <div className={styles['amz-agreement-btitle']}>
        2、我们如何存储您的个人信息
        </div>
        <div className={styles['amz-agreement-content']}>
        我们在中华人民共和国境内收集和产生的个人信息将按照法律规定的存储期限存储在中华人民共和国境内。
        </div>
        <div className={styles['amz-agreement-content']}>
        若为处理跨境业务，确需向境外机构传输境内收集的相关个人信息的，我们会按照法律、行政法规和相关监管部门的规定执行。我们会确保您的个人信息得到足够的保护，例如匿名化、加密存储等。我们仅在为提供AMZMOSS相关服务之目的所必需的期间和法律法规要求的时限内存储您的个人信息。
        </div>
        <div className={styles['amz-agreement-btitle']}>
        3、我们如何转移、公开您的个人信息
        </div>
        <div className={styles['amz-agreement-stitle']}>
        3.1转移
        </div>
        <div className={styles['amz-agreement-content']}>
        随着我们业务的持续发展，我们将有可能进行合并、收购、资产转让或其他类似的交易，您的个人信息有可能因此而被转移。在发生前述变更时，我们将按照法律法规及不低于本隐私政策所载明的安全标准要求继受方继续受此隐私政策的约束，若继受方变更原先的处理目的、处理方式的，我们将要求继受方重新征得您的授权同意。
        </div>
        <div className={styles['amz-agreement-stitle']}>
        3.2公开
        </div>
        <div className={styles['amz-agreement-content']}>
        尊重用户个人隐私和保护用户个人信息安全是徕海公司的一项基本政策，我们不会公开您的个人信息。但以下情形除外：
        </div>
        <div className={styles['amz-agreement-content']}>
        (1)已取得您的明确同意；<br/>
        (2)为订立、履行您作为一方当事人的合同所必需，或者按照依法制定的劳动规章制度和依法签订的集体合同实施人力资源管理所必需；<br/>
        (3)为履行法定职责或者法定义务所必需；<br/>
        (4)为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；<br/>
        (5)为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；<br/>
        (6)法律、行政法规规定的其他情形。
        </div>
        <div className={styles['amz-agreement-content']}>
        即使在以上情况下，我们也将采用合法、正当的方式，并以明确、合理的目的处理您的个人信息。
        </div>
        <div className={styles['amz-agreement-btitle']}>
        4、我们如何保护您的个人信息
        </div>
        <div className={styles['amz-agreement-content']}>
        4.1 我们非常重视您个人信息的安全，将努力采取合理的安全措施（包括技术方面和管理方面）来保护您的个人信息，防止您提供的个人信息被不当使用或在未经授权的情况下被访问、公开披露、使用、修改、损坏、丢失或泄露。
        </div>
        <div className={styles['amz-agreement-content']}>
        4.2 我们会使用不低于行业同行的加密技术、匿名化处理及相关合理可行的手段保护您的个人信息，并使用安全保护机制防止您的个人信息遭到恶意攻击。
        </div>
        <div className={styles['amz-agreement-content']}>
        4.3 我们会建立专门的安全部门、安全管理制度、数据安全流程保障您的个人信息安全。我们采取严格的数据使用和访问制度，确保只有授权人员才可访问您的个人信息，并适时对数据和技术进行安全审计。
        </div>
        <div className={styles['amz-agreement-content']}>
        4.4 <span className={styles['amz-agreement-underline']}>
        尽管已经采取了上述合理有效措施，并已经遵守了相关法律规定要求的标准，但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全，我们将尽力确保您提供给我们的个人信息的安全性。
        </span>
        </div>
        <div className={styles['amz-agreement-content']}>
        4.5 <span className={styles['amz-agreement-underline']}>
        您知悉并理解，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。因此，我们强烈建议您采取积极措施保护个人信息的安全，包括但不限于使用复杂密码、定期修改密码、不将自己的帐号密码及相关个人信息透露给他人。
        </span>
        </div>
        <div className={styles['amz-agreement-content']}>
        4.6 我们会制定应急处理预案，并在发生用户信息安全事件时立即启动应急预案，努力阻止这些安全事件的影响和后果扩大。一旦发生用户信息安全事件（泄露、丢失）后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已经采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施。我们将及时将事件相关情况以推送通知、邮件、信函、短信及相关形式告知您，难以逐一告知时，我们会采取合理、有效的方式发布公告。同时，我们还将按照相关监管部门要求，上报用户信息安全事件的处置情况。
        </div>
        <div className={styles['amz-agreement-btitle']}>
        5、您管理个人信息的权利
        </div>
        <div className={styles['amz-agreement-content']}>
        在您使用AMZMOSS软件及相关服务期间，您可以通过以下方式访问并管理您的个人信息：
        </div>
        <div className={styles['amz-agreement-stitle']}>
        5.1 个人信息查阅与管理
        </div>
        <div className={styles['amz-agreement-content']}>
        (1)您可以登录AMZMOSS账号，查询、访问、更正、删除您的个人信息，包括密码、性别、年龄、生日等信息。<span className={styles['amz-agreement-underline']}>特别提示您注意，出于安全性和身份识别（如号码申诉服务）的考虑，您可能无法自主修改注册时提交的某些初始注册信息。如您确有需要修改该类注册信息，请根据本隐私政策载明的联系方式联系我们。</span>
        </div>
        <div className={styles['amz-agreement-content']}>
        (2)以下情况发生时，您有权要求徕海公司删除您的个人信息：<br/>

        </div>
        <div className={styles['amz-agreement-indent']}>
        1.我们停止提供产品或者服务，或者保存期限已届满；<br/>
2.个人撤回同意；<br/>
3.我们处理个人信息的行为违反了法律、行政法规或与您的约定；<br/>
4.法律、行政法规规定的其他情形。<br/>
        </div>
        <div className={styles['amz-agreement-content']}>
        <span className={styles['amz-agreement-underline']}>个人信息删除：</span>指在实现日常业务功能所涉及的系统中去除个人信息的行为，使其保持不可被检索、访问的状态。<span className={styles['amz-agreement-underline']}>当您或我们协助您删除相关信息后，因为适用的法律和安全技术限制，我们可能无法立即从备份系统中删除相应的信息，我们将安全地存储您的个人信息并限制对其的任何进一步的处理，直到备份可以清除或实现匿名化。</span>
        </div>
        <div className={styles['amz-agreement-btitle']}>
        6、未成年人个人信息的保护
        </div>

        <div className={styles['amz-agreement-content']}>
        6.1若您是未满18周岁的未成年人，在使用本产品前，应在您的父母或其他监护人的监护、指导下共同阅读并同意本隐私政策。<span className={styles['amz-agreement-underline']}>若您是未满14周岁的未成年人的监护人，在使用AMZMOSS及相关服务前，应为您的被监护人阅读并同意本隐私政策。</span>
        </div>
        <div className={styles['amz-agreement-content']}>
        6.2若您是未成年人的监护人，当您对您所监护的未成年人的个人信息有其他疑问时，请通过公司本隐私政策公示的联系方式与我们联系。
        </div>
        <div className={styles['amz-agreement-btitle']}>
        7、隐私政策的变更和修订
        </div>
        <div className={styles['amz-agreement-content']}>
        7.1为了给您提供更好的服务，AMZMOSS及相关服务将不时更新与变化，我们会适时对本隐私政策进行修订，这些修订构成本隐私政策的一部分并具有等同于本隐私政策的效力，未经您明确同意，我们不会削减您依据当前生效的本隐私政策所应享受的权利。
        </div>
        <div className={styles['amz-agreement-content']}>
        7.2本隐私政策更新后，<span className={styles['amz-agreement-underline']}>我们会在“AMZMOSS”软件发出更新版本，并通过系统公告、通知或其他徕海公司认为恰当之方式提醒您。您在使用“AMZMOSS”软件及相关服务时，应当及时查阅了解修改的内容，以便您及时了解本隐私政策的最新版本</span>
        </div>
        <div className={styles['amz-agreement-btitle']}>
        8、如何联系我们
        </div>
        <div className={styles['amz-agreement-content']}>
        如果您对我们的隐私政策及对您个人信息的处理有任何疑问、意见、建议或投诉，请将问题发送至（support@amzmoss.com），我们一般会在15个工作日内对您的反馈作出答复。
        </div>
        <div className={styles['amz-agreement-btitle']}>
        附录一
        </div>
        <div className={styles['amz-agreement-content']}>
        徕海公司授权在AMZMOSS中接入的SDK详情
        </div>
        <table style={{border:'1px solid #000'}}>
          <thead>
            <tr>
              <td>sdk名称</td>
              <td>用途</td>
              <td>权限申请</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>微信SDK</td>
              <td>支付服务，微信登录服务</td>
              <td>用户微信支付 官网链接：<a href="https://pay.weixin.qq.com/index.php/core/home/login?return_url=%2F" target="_blank">https://pay.weixin.qq.com/index.php/core/home/login?return_url=%2F</a><br/>
用户微信登录 官网链接：<a href="https://open.weixin.qq.com/" target="_blank" rel="noopener noreferrer">https://open.weixin.qq.com/</a></td>
            </tr>
            <tr>
              <td>支付宝SDK</td>
              <td>支付服务</td>
              <td>用户支付宝支付 官网链接：<a href="https://b.alipay.com/index2.htm" target="_blank">https://b.alipay.com/index2.htm</a></td>
            </tr>
          </tbody>
        </table>
    </div>
  );
}
export default Yinsi;
