export const talkItem = {
  type: "assistant",
  name: "Amz",
  icon: "",
  select: false,
  content: "",
};
export const userItem = {
  type: "user",
  name: "lanmo",
  icon: "",
  select: false,
  content: "",
};
export const chatStartItem = {
  title: "新的会话",
  modelType: "gtp4",
  list: [
    {
      ...talkItem,
      type: "system",
      content: "你好，我是AMZMOSS，有什么可以帮助你吗？",
    },
  ],
};
