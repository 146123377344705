import styles from "./index.module.scss";
import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import HeadHome from "../../components/HeadHome";
import CenterBg from "@/assets/image/center.svg";
import OurOne from "@/assets/image/1.svg";
import OurTwo from "@/assets/image/2.svg";
import OurThree from "@/assets/image/3.svg";
import OurFour from "@/assets/image/4.svg";
import homeBannerPc from '@/assets/image/pc.png'
import homeBannerM from '@/assets/image/mob.jpg'
import { Button } from "@arco-design/web-react";
import { useNavigate } from "react-router-dom";
import { useMobileScreen } from "@/utils/hook";

function HomePage({ containerRef }) {
  const isMobileScreen = useMobileScreen();
  const navigate = useNavigate();
  const token = useSelector((state) => state.app.aiToken);
  const desList = [
    {
      title: "跨境电商平台",
      stitle: "符合平台风格的文案",
      descript:
        "针对亚马逊、eBay等跨境电商平台，我们利用AI技术生成符合平台风格的文案。通过深入了解各平台规范和要求，我们为您量身打造独特的文案，让您的产品在激烈竞争中脱颖而出。",
    },
    {
      title: "社交媒体平台",
      stitle: "满足不同行业的需求",
      descript:
        "我们的AI助力文案创作平台为不同行业提供多样化的社交媒体文案，包括TikTok、YouTube、Facebook等平台。无论您是美妆、时尚、健康还是科技行业，我们能为您生成吸引人眼球的社交媒体文案，扩大品牌影响力，吸引更多关注。",
    },
    {
      title: "SEO优化文案",
      stitle: "符合搜索引擎算法规则",
      descript:
        "我们的AI助力文案创作平台不仅生成吸引人的文案，还确保文案符合搜索引擎算法规则。通过结合自然语言处理和搜索引擎优化知识，我们为您的独立站提供高效、精准的文案，提高网站在搜索结果中的排名，吸引更多潜在客户。",
    },
    {
      title: "独立站文案",
      stitle: "打造独特品牌形象",
      descript:
        "您的独立站是展示品牌形象和产品优势的窗口。我们提供个性化的文案服务，为您的独立站定制独特、引人注目的文案。利用AI技术，我们为您打造与众不同的品牌形象，吸引目标受众的注意",
    },
  ];
  // 跳转页面
  function toLink(path) {
    // if (!token) {
    //   navigate("/login");
    //   return;
    // }
    // navigate(path);
    window.location.href = 'https://ai.amztab.com' + path
  }
  useEffect(() => {
  })
  return (
    <div className={styles["amz-home"]}>
      <HeadHome hasScroll={containerRef.current.scrollTop > 10} />
      <div className={`${styles["amz-home-topbanner"]} ${isMobileScreen ? 'h-96' : ''}`}
        style={{ height: isMobileScreen ? '300px' : '750px' }}
      >
        <div style={{ width: "80%", margin: "0 auto" }}>
          <div className={`${styles["amz-home-toptitle"]} ${isMobileScreen ? 'text-xl pt-24' : 'text-5xl pt-52'}`}>
            AMZMOSS
            <br />
            您的跨境运营AI助理
          </div>
          <div className={`${styles["amz-home-topdes"]} ${isMobileScreen ? 'text-base mt-8' : 'text-3xl mt-24'}`}>
            一键解决跨境运营及营销需求
          </div>
          <Button
            onClick={() => {
              toLink("/chat");
            }}
            className={`${styles["amz-home-topbtn"]} ${isMobileScreen ? 'mt-6 ' : 'mt-14'}`}
            type="primary"

            style={isMobileScreen ? {
              borderRadius: 3, height: 36, padding: '0 16px', fontSize: 16
            } : { borderRadius: 5, height: 64, padding: '0 50px', fontSize: '36px' }}
          >
            免费试用
          </Button>
        </div>
      </div>
      <div className={`${styles["amz-home-descript"]} ${isMobileScreen ? 'pt-6' : 'pt-24'}`}>
        <div className={`${styles["amz-home-descriptTitle"]} ${isMobileScreen ? "text-2xl" : 'text-5xl'}`}>
          引领跨境电商新潮流
        </div>
        <div className={`${styles["amz-home-descriptItems"]}  mx-auto ${isMobileScreen ? ' mt-6 w-5/6' : 'flex  mt-16 w-4/5'}`}>
          {desList.map((item, index) => {
            return (
              <div className={`${styles["amz-home-descriptItem"]} ${isMobileScreen ? 'px-4 py-4 mb-4' : 'px-6 py-8'}`}
                style={isMobileScreen ? { width: '100%' } : { width: '23%' }}
                key={index}>
                <div className={`${styles["amz-home-itemTitle"]} ${isMobileScreen ? 'text-lg mb-1' : 'text-2xl mb-3'}`}>{item.title}</div>
                <div className={`${isMobileScreen ? 'text-sm mb-2' : 'text-lg mb-3'}`}>
                  {item.stitle}
                </div>
                <div className={`${isMobileScreen ? 'text-xs' : 'text-sm '}`}>
                  {item.descript}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles["amz-home-our"]}>
        <img src={isMobileScreen ? homeBannerM : homeBannerPc} alt="" />
      </div>
      <div className={`${styles["amz-home-btminner"]} ${isMobileScreen ? 'h-32' : 'h-64'}`}>
        <div className={`${styles["amz-home-btminnerTitle"]} ${isMobileScreen ? 'text-xl pt-4' : "text-4xl pt-12"}`}>
          一键解决跨境运营及营销需求
        </div>
        <Button
          onClick={() => {
            toLink("/app");
          }}
          className={`${styles["amz-home-topbtn"]} ${isMobileScreen ? 'mt-5 ' : 'mt-14'}`}
          type="primary"

          style={isMobileScreen ? {
            borderRadius: 18, height: 36, padding: '0 16px', fontSize: 16
          } : { borderRadius: 32, height: 64, padding: '0 50px', fontSize: 32 }}
          size="large"
          shape="round"
        >
          免费试用
        </Button>
      </div>
      <div className="flex mx-auto pt-5 pb-6" style={{ maxWidth: isMobileScreen ? '90%' : '80%' }}>
        <div className="flex-1 ">
          <div className="py-3 text-lg font-bold ">关于</div>
          <div className="text-sm  py-1 cursor-pointer"><a className="text-black hover:text-blue-600" target='_blank' href="/#/yinsi">隐私政策</a></div>
          <div className="text-sm  py-1 cursor-pointer"><a className="text-black hover:text-blue-600" target='_blank' href="/#/userAgreement">用户服务协议</a></div>
        </div>
      </div>
      <div className="text-black  text-center py-2">Copyright © 2023 laihai All Rights Reserved</div>
      <div className="text-center pb-4"><a className="text-black hover:text-blue-600 " href="https://beian.miit.gov.cn/" target='_blank'>浙ICP备2023006008号-1</a></div>
    </div>
  );
}
export default HomePage;
