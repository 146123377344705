import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.less';
import './styles/global.scss'
import './styles/highlight.scss'
import './styles/markdown.scss'
import App from './App';
import { BrowserRouter,HashRouter } from 'react-router-dom'
import store from './store/index';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';

import "@arco-design/web-react/dist/css/arco.css";
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider  store = {store}  >
        <HashRouter>
            <App/>
        </HashRouter>
    </Provider>
);

reportWebVitals();



