import styles from "./index.module.scss";
import { useEffect, useState } from "react";
import { getTempById, getTempList, templateSubmit } from "@/api/app";
import { useScrollToBottom } from "@/utils/hook";
import { useSearchParams } from "react-router-dom";
import React from "react";
import AmzAct from "../../icons/app/amz_a.png";
import FaceBook from "../../icons/app/facebook.png";
import TikTok from "../../icons/app/tiktok.png";
import YouTuBe from "../../icons/app/youtube.png";
import { ReactComponent as IconTemp } from "@/icons/app/temp.svg";
import { ReactComponent as IconSeo } from "@/icons/app/seo.svg";
import { ReactComponent as IconLoading } from "@/icons/common/three-dots.svg";
import PromptList from "./list";
import {
  Layout,
  Menu,
  Button,
  Message,
  Input,
  Form,
  Select,
  Drawer
} from "@arco-design/web-react";
import {
  IconHome,
  IconMessage,
  IconFire,
  IconApps,
  IconDelete,
  IconCopy,
} from "@arco-design/web-react/icon";
import { Markdown } from "../chat/markdown";
import { copyToClipboard } from "@/utils";
import { useMobileScreen } from "@/utils/hook";
import { useSelector, useDispatch } from "react-redux";
import {
  setUuid,
  editChatTitle,
  setChatListStatus,
} from "../../store/chatSlice";
const MenuItem = Menu.Item;
const Sider = Layout.Sider;
const Content = Layout.Content;
const FormItem = Form.Item;

function Prompt() {
  let dispatch = useDispatch();

  const isMobileScreen = useMobileScreen();

  const options = [
    {
      title: "亚马逊",
      icon: (
        <img
          src={AmzAct}
          className={styles["self-icon"]}
          style={{ marginRight: 6, width: 14 }}
        />
      ),
      icona: <img src={AmzAct} style={{ marginRight: 6, width: 14 }} />,
      category: "1",
    },
    {
      title: "独立站",
      icon: <IconHome style={{ marginRight: 6 }} />,
      category: "2",
    },
    {
      title: "社交媒体",
      icon: <IconMessage style={{ marginRight: 6 }} />,
      category: "4",
      about: [
        {
          icon: (
            <img
              src={TikTok}
              className={styles["self-icon"]}
              style={{ marginRight: 6, width: 14 }}
            />
          ),
          icona: <img src={TikTok} style={{ marginRight: 6, width: 14 }} />,
        },
        {
          icon: (
            <img
              src={YouTuBe}
              className={styles["self-icon"]}
              style={{ marginRight: 6, width: 14 }}
            />
          ),
          icona: <img src={YouTuBe} style={{ marginRight: 6, width: 14 }} />,
        },
        {
          icon: (
            <img
              src={FaceBook}
              className={styles["self-icon"]}
              style={{ marginRight: 6, width: 14 }}
            />
          ),
          icona: <img src={FaceBook} style={{ marginRight: 6, width: 14 }} />,
        },
      ],
    },
    {
      title: "SEO & SEM",
      icon: (
        <IconSeo
          style={{
            marginRight: 6,
            width: 14,
            height: 12,
            fill: "var(--color-text-2)",
          }}
        />
      ),
      icona: (
        <IconSeo
          style={{
            marginRight: 6,
            width: 14,
            height: 12,
            fill: "rgb(var(--primary-6))",
          }}
        />
      ),
      category: "8",
    },
    {
      title: "其他",
      icon: <IconApps style={{ marginRight: 6 }} />,
      category: "16",
    },
  ];
  const tabList = [
    {
      title: "热门",
      icon: <IconFire style={{ marginRight: 6 }} />,
      category: "-1",
    },
    ...options,
    {
      title: "我的模版",
      icon: (
        <IconTemp
          className="arco-icon"
          style={{ marginRight: 6, fill: "var(--color-text-2)" }}
        />
      ),
      icona: (
        <IconTemp
          className="arco-icon"
          style={{ marginRight: 6, fill: "rgb(var(--primary-6))" }}
        />
      ),
      category: "-2",
    },
  ];
  const chatListShow = useSelector((state) => state.chat.chatListShow);
  const [form] = Form.useForm();
  const [search] = useSearchParams();
  const [id, setId] = useState(search.get("id"));
  const [category, setCategory] = useState("0");
  const [tempList, setTempList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [detailLoading, setDetailLoading] = useState(false);
  const [isPrint, setIsPrint] = useState(false);
  const [detail, setDetail] = useState({});
  const [result, setResult] = useState("");
  const { scrollRef, setAutoScroll, scrollToBottom } = useScrollToBottom();

  useEffect(() => {
    getDetail();
  }, []);
  /**获取platform 详情 */
  function getDetail(info) {
    setDetailLoading(true);
    setCategory("0");
    setResult("");
    if (info) {
      setId(info.ID);
    }
    getTempById({ id: info ? info.ID : Number(id) }).then((res) => {
      setDetailLoading(false);

      res.data.id = info ? info.ID : Number(id);
      setDetail(res.data);
    });
  }
  // 获取详情
  function getList(key) {
    let hot = Number(key === "-1");
    let self = Number(key === "-2");
    setLoading(true);
    getTempList({
      category: Number(key) < 0 ? 0 : Number(key),
      is_hot: hot,
      is_self: self,
    })
      .then((res) => {
        if (res.status == 0) {
          setTempList(res.data || []);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }
  // 复制文案
  function copyText() {
    copyToClipboard(result);
  }
  // 切换tab
  function menuChange(key) {
    dispatch(setChatListStatus({ status: false }));
    setTempList([]);
    setId("");
    setDetail({});
    setCategory(key);
    getList(key);
  }
  // 提交信息获取文案
  function onOk() {
    if (loading || isPrint) {
      Message.warning("文案生成中，请稍后操作");
      return;
    }
    form.validate().then((res) => {
      setLoading(true);
      templateSubmit({
        id: detail.id,
        paramsList: detail.paramsList.map((item) => {
          return {
            ...item,
            param_value: res[item.name],
          };
        }),
      }).then(async (res) => {
        const reader = res.body.getReader();
        let str = "";
        let str_ = "";
        setIsPrint(true);
        let index = 0;
        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            setIsPrint(false);
            break;
          }
          console.log(new TextDecoder().decode(value));
          str += new TextDecoder().decode(value);
          let timer = setInterval(() => {
            if (!isPrint && str == str_) {
              clearInterval(timer);
              setLoading(false);
            } else if (str[index]) {
              str_ += str[index];
              index++;
              setResult(str_);
              scrollToBottom();
            }
          }, 300);
        }
      });
    });
  }

  return (
    <>
      <Layout className={styles["amz-prompt"]} style={{height:isMobileScreen?'calc(100vh - 100px)':'calc(100vh - 66px)'}}>
 {
  isMobileScreen?null:(     <Sider collapsed={false} collapsible trigger={null} breakpoint="xl">
  <Menu
    defaultOpenKeys={[category]}
    defaultSelectedKeys={[category]}
    onClickMenuItem={menuChange}
    style={{ width: "100%" }}
  >
    {tabList.map((item) => {
      return (
        <MenuItem key={item.category}>
          {item.category == category
            ? item.icona || item.icon
            : item.icon}
          {item.title}
        </MenuItem>
      );
    })}
  </Menu>
</Sider>)
 }

      <Content>
        {!id ? (
          <PromptList
            list={tempList}
            loading={loading}
            toDetail={getDetail}
          ></PromptList>
        ) : (
          <div className={`${styles["amz-prompt-content"]} ${isMobileScreen?styles["isMoble"]:''} ${isMobileScreen?'':'flex'}`}>
            <div className={styles["amz-prompt-form"]}>
              {detailLoading ? (
                <IconLoading
                  style={{ display: "block", margin: "100px auto 0" }}
                />
              ) : (
                <div className={styles["amz-prompt-formCon"]}>
                  <div className={[styles["amz-prompt-formTitle"]]}>
                    {detail.title}
                  </div>
                  <div className={[styles["amz-prompt-formDescript"]]}>
                    {detail.description}
                  </div>
                  <Form
                    autoComplete="off"
                    layout="vertical"
                    validateMessages={{
                      required: (_, { label }) => `${label}是必填项`,
                    }}
                    form={form}
                    style={{ marginTop: 15 }}
                  >
                    {detail.paramsList?.map((item) => {
                      return item.param_type == "array" ? (
                        <FormItem
                          label={item.param_name}
                          field={item.name}
                          initialValue={item.default_value}
                          rules={[{ required: item.required }]}
                          key={item.name}
                        >
                          <Select
                            placeholder="请选择"
                            options={item.OptionalValue}
                          />
                        </FormItem>
                      ) : item.Max > 100 ? (
                        <FormItem
                          rules={[{ required: item.required }]}
                          label={item.param_name}
                          field={item.name}
                          key={item.name}
                          initialValue={item.default_value}
                        >
                          <Input.TextArea
                            autoSize={{ minRows: 2, maxRows: 5 }}
                            placeholder={item.param_desc}
                            showWordLimit={item.Max > 1}
                            maxLength={item.Max > 1 ? { length: item.Max } : {}}
                          />
                        </FormItem>
                      ) : (
                        <FormItem
                          rules={[{ required: item.required }]}
                          label={item.param_name}
                          field={item.name}
                          key={item.name}
                          initialValue={item.default_value}
                        >
                          <Input
                            placeholder={item.param_desc}
                            showWordLimit={item.Max > 1}
                            maxLength={item.Max > 1 ? { length: item.Max } : {}}
                          />
                        </FormItem>
                      );
                    })}
                  </Form>
                  <div
                    className={[styles["amz-prompt-formReset"]]}
                    onClick={() => {
                      form.resetFields();
                    }}
                  >
                    <IconDelete />
                    清空录入
                  </div>
                </div>
              )}
              <div className={styles["amz-prompt-send"]}>
                <Button
                  type="primary"
                  onClick={onOk}
                  style={{ width: "100%", borderRadius: 5 }}
                >
                  立即生成
                </Button>
              </div>
            </div>
            <div className={styles["amz-prompt-print"]} ref={scrollRef}>
              <div className={styles["amz-prompt-printTitle"]}>
                生成文案
                {!isPrint && result && !loading ? (
                  <span onClick={copyText}>
                    <IconCopy /> 复制文案
                  </span>
                ) : null}
              </div>
              <div className={styles["amz-prompt-printContent"]}>
                {!result && loading ? (
                  <IconLoading
                    style={{ display: "block", margin: "100px auto 0" }}
                  />
                ) : (
                  <>
                    {!result ? (
                      <div className={styles["amz-prompt-printEmpty"]}>
                        海量文案，生成即完成
                        <br />
                        输入你的文案诉求，即刻生成专属优质文案
                      </div>
                    ) : (
                      <Markdown
                        content={result}
                        onContextMenu={(e) => {}}
                        onDoubleClickCapture={() => {}}
                        fontSize={14}
                        parentRef={scrollRef}
                        defaultShow={true}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </Content>
    </Layout>
          <Drawer
          width={240}
          height={332}
          title={null}
          footer={null}
          closable={false}
          visible={chatListShow}
          bodyStyle={{ padding: 0 }}
          placement="left"
          onOk={() => {
            dispatch(setChatListStatus({ status: false }));
          }}
          onCancel={() => {
            dispatch(setChatListStatus({ status: false }));
          }}
        >
            <Menu
    defaultOpenKeys={[category]}
    defaultSelectedKeys={[category]}
    onClickMenuItem={menuChange}
    style={{ width: "100%" }}
  >
    {tabList.map((item) => {
      return (
        <MenuItem key={item.category}>
          {item.category == category
            ? item.icona || item.icon
            : item.icon}
          {item.title}
        </MenuItem>
      );
    })}
  </Menu>
        </Drawer>
    </>
  );
}
export default Prompt;
