import styles from "./index.module.scss";
import { Input, Layout, Message, Modal, Drawer } from "@arco-design/web-react";
import ChatList from "./chatList";
import ChatInput from "./chatInput";
import ChatDefault from "./chatDefault";
import { Markdown } from "./markdown";
import { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
//通用开始对话对象
import { chatStartItem, talkItem, userItem } from "@/utils/config";
//发起对话
import {
  sendCompletion,
  sendCompletionStream,
  getChatMenuList,
  changeChatTitle,
  deleteChatList,
  getChatDetail
} from "@/api/auth";
import { useSelector, useDispatch } from "react-redux";
import {
  setUuid,
  addChatList,
  delChatList,
  editChatTitle,
  updataChatItem,
  setChatListStatus,
  setChatList,
  initChatDetail
} from "../../store/chatSlice";
import { ReactComponent as IconHead } from "@/icons/common/head.svg";

import CopyIcon from "@/icons/chat/copy.svg";
import { copyToClipboard } from "@/utils";
import { useMobileScreen } from "@/utils/hook";
let iconamz = require('../../icons/common/amz.png')
let iconuser = require('../../icons/common/user.jpg')
const Sider = Layout.Sider;
const Content = Layout.Content;
function useScrollToBottom() {
  // for auto-scroll
  const scrollRef = useRef(null);
  const [autoScroll, setAutoScroll] = useState(true);
  const scrollToBottom = () => {
    const dom = scrollRef.current;
    if (dom) {
      setTimeout(() => (dom.scrollTop = dom.scrollHeight), 1);
    }
  };

  // auto scroll
  useLayoutEffect(() => {
    autoScroll && scrollToBottom();
  });

  return {
    scrollRef,
    autoScroll,
    setAutoScroll,
    scrollToBottom,
  };
}
function Chat() {
  let dispatch = useDispatch();
  let navigate = useNavigate();

  const localChatList = useSelector((state) => state.chat.chatList);
  const activeChat = useSelector((state) => state.chat.activeChat);
  const chatDetail = useSelector((state) => state.chat.chatDetail)
  const token = useSelector((state) => state.app.aiToken);
  const chatListShow = useSelector((state) => state.chat.chatListShow);
  const [visible, setVisible] = useState(false);
  const [isOutput, setIsOutput] = useState(false);
  const [editTitle, setEditTitle] = useState("");
  const [editUuid, setEditUuid] = useState("");
  const textRef = useRef(null);
  const { scrollRef, setAutoScroll, scrollToBottom } = useScrollToBottom();
  const isMobileScreen = useMobileScreen();
 
  //聊天内容列表

  /**
   * @param {用户输入的字符串} e
   */

  function pressEnter(text) {
    //未登录跳转登录
    if (!token) {
      navigate("/login");
      return;
    }
    //内容为空
    if (!text.trim()) return;
    //chatgpt 回答中
    if (isOutput) {
      Message.warning("对话进行中");
      return;
    }
    dispatch(
      updataChatItem({
        ...userItem,
        content: text,
      })
    );
    dispatch(
      updataChatItem({
        ...talkItem,
        loading: true,
        content: "",
      })
    );
    setIsOutput(true);

    sendCompletionStream({
      request: {
        messages: [
          // ...getMemoryChat(),
          {
            role: "user",
            content: text,
          },
        ],
      },
      options: {
        ChatSessionId: activeChat.uuid||'',
        existType:chatDetail.length==1?2:1
      },
    })
      .then(async (response) => {
        const reader = response.body.getReader();
        let str = "";
        let str_ = "";
        let isPrint = true;
        let index = 0;
        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            isPrint = false;
              setIsOutput(false);
            break;
          }
          str += new TextDecoder().decode(value);
          try {
            let res = JSON.parse(str)
            dispatch(
              updataChatItem({
                ...talkItem,
                adding: true,
                content:res.msg,
              })
            );
          } catch (error) {
            dispatch(
              updataChatItem({
                ...talkItem,
                adding: true,
                content: str,
              })
            );
          }
          
          // let timer = setInterval(() => {
          //   if (!isPrint && str == str_) {
          //     clearInterval(timer);
          //     setIsOutput(false);
          //   } else if (str[index]) {
          //     str_ += str[index];
          //     index++;
          //     dispatch(
          //       updataChatItem({
          //         ...talkItem,
          //         adding: true,
          //         text: str_,
          //       })
          //     );
          //   }
          // }, 300);
        }
      })
      .catch((err) => {
        dispatch(
          updataChatItem({
            ...talkItem,
            save: true,
            content: "服务器连接异常，请稍后重试",
          })
        );
        setIsOutput(false);
      });
  }
  function pressFocus() {
    if (!activeChat.uuid) {
      addNewChat();
    }
  }
  function getMemoryChat() {
    let len = 4;
    let arr = [];
    chatDetail.forEach((element, index) => {
      if (chatDetail.length > 5) {
        if (index > chatDetail.length - len) {
          arr.push({
            role: element.type == "user" ? "user" : "assistant",
            content: element.content,
          });
        }
      } else if (index >= 2) {
        arr.push({
          role: element.type == "user" ? "user" : "assistant",
          content: element.content,
        });
      }
    });
    return arr;
  }
  /**
   * 新增聊天
   */
  function addNewChat() {
    if (!token) {
      navigate("/login");
      return;
    }
    if (chatDetail?.length == 1) {
      textRef.current.focus();
      return;
    }
    let uid = uuid();
    let defaultObj = {
      ...chatStartItem,
      uuid: uid,
    };
    dispatch(addChatList(defaultObj));
    dispatch(setChatListStatus({ status: false }));
  }
  /**
   * 打开编辑弹框
   * @param {Object} info 需要编辑的对话
   */
  function editChat(info) {
    setVisible(true);
    setEditTitle(info.title);
    setEditUuid(info.uuid);
  }
  /**
   * 确认修改标题
   */
  function onOk() {
    setVisible(false);
    dispatch(editChatTitle({ title: editTitle, uuid: editUuid }));
    changeChatTitle({
      title: editTitle,
      ChatId: editUuid,
    }).then((res) => {
      Message.success("修改成功");
    });
  }
  function toRemark(index) {
    let text = chatDetail[index - 1].content;
    pressEnter(text);
  }
  function getChatMenuListFun() {
    getChatMenuList({
      pageSize: 50,
      pageNum: 1,
    }).then((res) => {
      if(res.data.list&&res.data.list.length){
        dispatch(
          setChatList(
            res.data.list.map((item) => {
              return {
                uuid: item.chat_id,
                title: item.title,
              };
            })
          )
        );
        if(!activeChat?.uuid){
          dispatch(setUuid( res.data.list.map((item) => {
            return {
              uuid: item.chat_id,
              title: item.title,
            };
          })[0]))
          getChatDetailFun()
        }
      }
      
    });
  }
  function getChatDetailFun(){
    getChatDetail({
      chatId:activeChat.uuid,
      pageNum:1,
      pageSize:100
    }).then(res => {
      res.data.list?.forEach(item => {
        item.type= item.type==1?'user':'assistant'
      })
        dispatch(initChatDetail([...chatStartItem.list,...(res.data.list||[])]))
        dispatch(setChatListStatus({ status: false }));
    })
        
  }
  function delChatListFun(info){
    Modal.confirm({
      title: '确认删除？',
      okButtonProps: {
        status: 'danger',
      },
      style:{ maxWidth: "500px", width: "90%" },
        wrapStyle:{ width: "100%" },
      onOk: () => {
        dispatch(delChatList(info));
        deleteChatList({
          ChatId:info.uuid
        }).then(res => {
          Message.success({
            content: '删除成功！',
          });
        })
      
      },
    });
    
      
    
  }
  useEffect(() => {
    scrollToBottom();
    getChatMenuListFun();
    if(activeChat?.uuid){
      getChatDetailFun(activeChat)
    }
  }, []);
  useEffect(() => {
    if(activeChat?.uuid){
      getChatDetailFun()
    }
  },[activeChat.uuid])
  return (
    <div>
      <Layout
        style={{ height: isMobileScreen?"calc(100vh - 100px)": "calc(100vh - 66px)", width: "100vw" ,flexDirection:'row'}}
        className={styles["amz-chat"]}
      >
        {isMobileScreen ? null : (
          <Sider
            resizeDirections={["right"]}
            style={{
              minWidth: 200,
              maxWidth: 400,
            }}
          >
            <ChatList
              list={localChatList}
              uuid={activeChat.uuid}
              addNewChat={addNewChat}
              selectChat={(info) => {dispatch(setUuid(info))}}
              delChat={delChatListFun}
              editChat={editChat}
            />
          </Sider>
        )}

        <Content className={styles["amz-chat-layoutcontent"]}>
          <div className={styles["amz-chat-content"]} ref={scrollRef}>
            {activeChat.uuid ? (
              chatDetail.map((info, index) => (
                // <ChatItem info={info} index={index} key={index} />
                <div
                  key={index}
                  className={`${styles["amz-chat-chatitem"]} ${
                    styles[
                      info.type == 'user'
                        ? "amz-chat-chatitem-self"
                        : "amz-chat-chatitem-ai"
                    ]
                  } ${isMobileScreen ? "p-2" : "p-4"}`}
                >
                  <div className={styles["amz-chat-chatuser"]}>
                  <img className="rounded" src={info.type == "user" ? iconuser:iconamz} />
                  </div>
                  <div
                    className={styles["amz-chat-chattext"]}
                    style={{
                      maxWidth: isMobileScreen
                        ? "calc(100% - 110px)"
                        : "calc(100% - 200px)",
                    }}
                  >
                    <div
                      className={`${styles["amz-chat-chattextcon"]} px-4 py-3 ${
                        isMobileScreen ? "" : ""
                      }`}
                    >
                      <Markdown
                        content={info.content}
                        loading={!info.content}
                        onContextMenu={(e) => {}}
                        onDoubleClickCapture={() => {}}
                        fontSize={isMobileScreen ? 12 : 14}
                        parentRef={scrollRef}
                        defaultShow={true}
                      />
                    </div>
                    {index == 0 ? null : (
                      <div className={styles["amz-chat-chattextdo"]}>
                        {info.type == "user" ? (
                          <span></span>
                        ) : (
                          <div
                            className={styles.remake}
                            onClick={() => {
                              toRemark(index);
                            }}
                          >
                            重新生成
                          </div>
                        )}

                        <div
                          className={styles.copytext}
                          onClick={() => {
                            copyToClipboard(info.content);
                          }}
                        >
                          <img src={CopyIcon} style={{ marginRight: 8 }} />{" "}
                          复制文本
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <ChatDefault />
            )}
          </div>
          <ChatInput
            textRef={textRef}
            onPressFocus={pressFocus}
            onPressEnter={pressEnter}
            scrollToBottom={scrollToBottom}
          />
        </Content>
      </Layout>

      <Drawer
        width={240}
        height={332}
        title={null}
        footer={null}
        closable={false}
        visible={chatListShow}
        bodyStyle={{ padding: 0 }}
        placement="left"
        onOk={() => {
          dispatch(setChatListStatus({ status: false }));
        }}
        onCancel={() => {
          dispatch(setChatListStatus({ status: false }));
        }}
      >
        <ChatList
          list={localChatList}
          uuid={activeChat.uuid}
          addNewChat={addNewChat}
          selectChat={(info) => {dispatch(setUuid(info))}}
          delChat={delChatListFun}
          editChat={editChat}
        />
      </Drawer>
      {/* 标题修改 */}
      <Modal
        title="修改会话标题"
        visible={visible}
        onOk={onOk}
        style={{ maxWidth: "500px", width: "90%" }}
        wrapStyle={{ width: "100%" }}
        onCancel={() => {
          setVisible(false);
        }}
      >
        <Input
          placeholder=""
          value={editTitle}
          onChange={(e) => {
            setEditTitle(e);
          }}
        />
      </Modal>
    </div>
  );
}

export default Chat;
