
import { useEffect, useState, useRef } from "react";
import {
  matchRoutes,
  useLocation,
  useNavigate,
  Navigate,
} from "react-router-dom";
import { Button, Form, Input,Message } from "@arco-design/web-react";
import styles from "./index.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { setToken } from "../../store/appSlice";

import LogoCode from "../../icons/login/logo_code.svg";
import IconCode from "../../icons/login/icon_code.svg";
import { getInfos,getPhoneCode,smsLogin,bindWechatPhone,getBindCode } from "@/api/login";
import { useMobileScreen } from "@/utils/hook";
const FormItem = Form.Item;

function BindWechat({ changeLoginWay }) {
  const isMobileScreen = useMobileScreen();

  let [codeTime, setCodeTime] = useState(60);
  let [hasSend, setHasSend] = useState(false);
  let [loginPendding, setLoginPendding] = useState(false);
  let dispatch = useDispatch();
  let navigate = useNavigate()
  const [form] = Form.useForm();
  const formRef = useRef();
  const customInput = Form.useWatch('customInput', form);
  /**
   * @returns 获取验证码
   */
  function getCode() {
    if (hasSend) return;
    if(!customInput.match(/^1[0-9]{10}$/)){
      Message.error(!customInput?'手机号码不能为空！':'手机号码格式有误！')
      return 
    }
    setHasSend(true);
    getBindCode({tel:customInput}).then(res => {
      setCodeTime((codeTime) => 60);
      let timer = setInterval(() => {
        setCodeTime((codeTime) => {
          if (codeTime == 0) {
            setHasSend((hasSend) => !hasSend);
            clearInterval(timer);
            return;
          }
          return codeTime - 1;
        });
      }, 1000);
    })
    
  }
  /**
   * 提交登录信息
   */
  function toLogin(info) {
    setLoginPendding(true);
    bindWechatPhone({
      tel: info.customInput,
      code: info.code,
      key:sessionStorage.weCodekey
    }).then((res) => {
      if(res.code!==200){
        setLoginPendding(false);
        dispatch(setToken({ token: res.data.token }));
        getUserInfo()
      }
      // setLoginPendding(false);
      // dispatch(setToken({ token: res.data.token }));
      // getUserInfo()

    });
  }
  /**
   * 获取用户信息
   */
  function getUserInfo(){
    getInfos().then((res) => {
      navigate("/chat");
    });
  }

  return (
    <div className={`${styles["amz-login-content-code"]} ${isMobileScreen?'pb-12':'pb-20'}`}>
      <div className={`${styles["amz-login-content-ctitle"]} ${isMobileScreen?'text-2xl pt-10':'text-3xl pt-12'}`}>微信登录绑定</div>
      <Form
        className={`${styles["amz-login-content-cform"]} ${isMobileScreen?styles["isMobel"]:''}`}
        ref={formRef}
        wrapperCol={{ span: 24 }}
        initialValues={{
          code: "",
          customInput: ''
        }}
        form={form}
        onSubmit={toLogin}
      >
        <FormItem
          rules={[
            { required: true },
            {
              validator: (val, cb) => {
                if(!val){
                  return cb("手机号码不能为空！");
                }else if (!val.match(/^1[0-9]{10}$/)) {
                  return cb("手机号码格式有误！");
                }
                cb();
              },
            },
          ]}
          field="customInput"
        >
          {/* <CustomInput /> */}
          <Input
              allowClear
              size="large"
              placeholder="请输入您的手机号"
              style={{ width: "100%" }}
            />
        </FormItem>
        <FormItem
          field="code"
          rules={[
            {
              required: true,
            },
            {
              validator: (val, cb) => {
                if (val.length < 6) {
                  return cb("密码过短！");
                }
                cb();
              },
            },
          ]}
        >
          <Input
            placeholder="请输入验证码"
            className={styles.codeinput}
            prefix={<img src={LogoCode} style={{ marginRight: "15px" }} />}
            suffix={
              <div
                className={
                  styles.codeinputAfter + ` ${hasSend ? styles.disabled : ""}`
                }
                onClick={() => {
                  getCode();
                }}
              >
                {hasSend ? `重新获取验证码${codeTime}s` : "获取验证码"}
              </div>
            }
            size="large"
          />
        </FormItem>
        {/* <FormItem>
          <div className={styles["amz-login-content-csign"]}>
            <span>没有账号？</span>
            <a>立即注册</a>
          </div>
        </FormItem> */}
        <FormItem style={{marginTop:30}}>
          <Button
            size="large"
            className={styles["amz-login-content-btn"]}
            type="primary"
            htmlType="submit"
            loading={loginPendding}
            onClick={async () => {
              if (formRef.current) {
                try {
                  await formRef.current.validate();
                  // Message.info("校验通过，提交成功！");
                } catch (_) {
                  // Message.error("校验失败，请检查字段！");
                }
              }
            }}
          >
            登录
          </Button>
        </FormItem>
        <FormItem>
          <div
            onClick={() => {
              changeLoginWay(2);
            }}
            className={styles["amz-login-content-cbottom"]}
          >
            <img src={IconCode} /> 验证码登录
          </div>
        </FormItem>
      </Form>
    </div>
  );
}
export default BindWechat;
