import {get,post,postStream} from './request'

export const addTemp = (data) => post('/template/create',data) 
export const updateTemp = (data) => post('/template/update',data) 
export const delTemp = (data) => post('/template/del',data) 
export const getTempList = (data) => post('/template/list',data) 
export const getTempById = (data) => post('/template/getById',data) 
export const templateSubmit = (data) => postStream('/template/submit',data) 





