import { useState } from "react";
import ImgList from "./imgList";
import ImgConfig from "./imgList";
import styles from "./index.module.scss";
import Bg from "../../assets/image/demo.png";
import { Image } from "@arco-design/web-react";
import { addImgTask, getTaskStatus } from "@/api/imgai";
import { useEffect } from "react";
import { get } from "../../api/request";
import PacmanLoader from "react-spinners/PacmanLoader";

const statusText = {
  NOT_START: "未启动",
  SUBMITTED: "已提交处理",
  IN_PROGRESS: "执行中",
  FAILURE: "失败",
  SUCCESS: "成功",
};
function ChatImg() {
  let [color, setColor] = useState("#ffffff");
  const [activeItem, setActiveItem] = useState(1);
  const [previewUrl, setPreviewUrl] = useState("");
  const [showPreview, setShowPreview] = useState(false);
  const [taskInfo, setTaskInfo] = useState({
    id: "",
    status: "",
    progress: "",
  });
  const [loading, setLoading] = useState(false);
  const [imgUrl, setImgUrl] = useState("");
  const [imgText, setImgText] = useState("");
  function changeActive(item) {
    setActiveItem(item);
  }

  function addTask() {
    setLoading(true);
    addImgTask({
      prompt: imgText,
    }).then((res) => {
      getTaskInfo(res.data);
    });
  }
  function getTaskInfo(id) {
    getTaskStatus({
      taskId: id,
    }).then((res) => {
      setTaskInfo(() => res.data);

      if (res.data.status == "SUCCESS") {
        setLoading(false);
        setImgUrl(res.data.imageUrl);
        return;
      }
      setTimeout(() => {
        getTaskInfo(id);
      });
    });
  }
  useEffect(() => {
    // getTaskInfo(1691327021307055);
  }, []);
  return (
    <div className={styles.amz_img}>
      <ImgList active={activeItem} changeActive={changeActive} />
      <div className={styles.amz_img_content}>
        <div className={styles.amz_img_imgbox}>
          <div className={styles.amz_img_imgitem}>
            {loading && taskInfo.status != "SUCCESS" ? (
              <div className="flex justify-between w-40 items-center">
                <PacmanLoader color="#36d7b7" />
                <span>{taskInfo.progress}</span>
              </div>
            ) : (
              <img
                className={styles.img}
                src={imgUrl}
                onClick={() => {
                  setPreviewUrl(imgUrl);
                  setShowPreview(true);
                }}
              />
            )}
          </div>
          <div className={styles.amz_img_imgconfig}></div>
        </div>
        <div className={styles.amz_img_input}>
          <div className="flex rounded-[50px] border border-[#3c6ef6] border-solid p-3 mt-4 w-4/5 mx-auto">
            <input
              type="text"
              value={imgText}
              onChange={(item) => {
                console.log(item);
                setImgText(item.target.value);
              }}
              style={{ border: "none" }}
              className="flex-1 line-clamp-1 border-none ml-4"
              placeholder="请输入您的会话描述"
            />
            <div
              onClick={addTask}
              style={{
                background:
                  "linear-gradient(135deg,#01b2fe 0%,#236eff 47%,#673efc 100%)",
              }}
              className="w-[100px] text-white send-btn1 h-9 px-4 rounded-full mr-2 flex items-center justify-center cursor-pointer"
            >
              发送
            </div>
          </div>
        </div>
      </div>
      <Image.Preview
        src={previewUrl}
        visible={showPreview}
        onVisibleChange={setShowPreview}
      />
    </div>
  );
}
export default ChatImg;
